import { Match } from './match';
import { RankedApplication } from './ranked-application';
import { Room } from './room';

export interface RankedState {
  // currentRoom
  // availableGames
  application: RankedApplication;
  room: Room;
  match: Match;
}

import { Injectable } from '@angular/core';
import { Auth, User as FirebaseUser, user as getFirebaseUser } from '@angular/fire/auth';
import { Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { StoreService, dispatch, observe, select } from '@ngxp/store-service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map, mergeMap } from 'rxjs/operators';
import { PresenceStatus } from '@ranked/model';
import { RoomStoreService } from '@ranked/room';
import { RankedAccountState } from '../model/ranked-account-state';
import { User } from '../model/user';
import {
  AuthenticateWithGoogle,
  CreateRankedAccount,
  JoinRoom,
  LoginWithEmail,
  Logout,
  RegisterWithEmail,
  RequestNewPassword,
  RequestNewPasswordFailure,
  RequestNewPasswordSuccessful,
  ResendVerificationMail,
  ResendVerificationMailFailure,
  ResendVerificationMailSuccessful,
  SavedRoomIdRemoved,
  SelectRoomFromSelectRoomPage,
  UpdateCurrentAccount,
} from '../state/account.actions';
import { AccountAppState } from '../state/account.reducer';
import {
  getCurrentRoomStatus,
  getRankedAccount,
  getRankedAccountId,
  getRankedAccountStatus,
  getRoomMemberships,
} from '../state/account.selectors';

@Injectable()
export class AccountStoreService extends StoreService<AccountAppState> {
  private firebaseUser$: Observable<FirebaseUser>;

  private passwordResetResult$ = observe([RequestNewPasswordSuccessful, RequestNewPasswordFailure], (action) => action);

  private resendVerificationResult$ = observe([ResendVerificationMailSuccessful, ResendVerificationMailFailure], (action) => action);

  private requestNewPasswordInternal = dispatch(RequestNewPassword);

  private resendVerificationMailInternal = dispatch(ResendVerificationMail);

  public loginWithEmail = dispatch(LoginWithEmail);

  public registerWithEmail = dispatch(RegisterWithEmail);

  public authenticateWithGoogle = dispatch(AuthenticateWithGoogle);

  public logout = dispatch(Logout);

  public createAccount = dispatch(CreateRankedAccount);

  public roomSelected = dispatch(SelectRoomFromSelectRoomPage);

  public joinRoom = dispatch(JoinRoom);

  public updateCurrentAccount = dispatch(UpdateCurrentAccount);

  public getRoomMemberships = select(getRoomMemberships);

  public getRankedAccount = select(getRankedAccount);

  public getRankedAccountId = select(getRankedAccountId);

  public getRankedAccountStatus = select(getRankedAccountStatus);

  public getCurrentRoomStatus = select(getCurrentRoomStatus);

  public roomLeft$ = observe([SavedRoomIdRemoved]);

  public constructor(
    store: Store<AccountAppState>,
    actions: Actions<Action>,
    firebaseAuth: Auth,
    private roomStoreService: RoomStoreService,
  ) {
    super(store, actions);

    this.firebaseUser$ = getFirebaseUser(firebaseAuth);
  }

  public getUser(): Observable<User> {
    return this.firebaseUser$.pipe(
      distinctUntilChanged(),
      map((firebaseUser) =>
        firebaseUser
          ? {
              displayName: firebaseUser.displayName,
              photoUrl: firebaseUser.photoURL,
            }
          : null,
      ),
    );
  }

  public isUserLoggedIn(): Observable<boolean> {
    return this.getUser().pipe(map((user) => !!user));
  }

  public isRankedAccountAvailable(): Observable<boolean> {
    return this.getRankedAccountStatus().pipe(map((status) => status === PresenceStatus.PRESENT));
  }

  public isRankedAccountVerified(): Observable<boolean> {
    return this.getRankedAccount().pipe(map((account) => !!account && account.state !== RankedAccountState.VERIFICATION_PENDING));
  }

  public getOwnPlayerNameInRoom(roomId: string): Observable<string | undefined> {
    return this.getRoomMemberships().pipe(map((memberships) => memberships.find((membership) => membership.roomId === roomId)?.playerName));
  }

  public getOwnPlayerNameInCurrentRoom(): Observable<string | undefined> {
    return this.roomStoreService.getCurrentRoomId().pipe(
      mergeMap((currentRoomId) => {
        return currentRoomId === undefined ? undefined : this.getOwnPlayerNameInRoom(currentRoomId);
      }),
    );
  }

  public requestNewPassword(email: string): Promise<boolean> {
    this.requestNewPasswordInternal({ email });

    return this.passwordResetResult$()
      .pipe(
        first(),
        map((result) => result.type === RequestNewPasswordSuccessful.type),
      )
      .toPromise();
  }

  public resendVerificationMail(): Promise<boolean> {
    this.resendVerificationMailInternal();

    return this.resendVerificationResult$()
      .pipe(
        first(),
        map((result) => result.type === ResendVerificationMailSuccessful.type),
      )
      .toPromise();
  }
}

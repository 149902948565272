import { NgModule } from '@angular/core';
import { AuthGuard, AuthGuardModule, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { DebugPageComponent } from './components/debug/debug-page.component';
import { HomePageComponent } from './components/home/home-page.component';
import { JoinRoomComponent } from './components/join-room/join-room.component';
import { SelectRoomPageComponent } from './components/select-room/select-room-page.component';
import { AccountPresentGuard } from './guards/account-present.guard';
import { AccountVerifiedGuard } from './guards/account-verified.guard';
import { RoomSelectedGuard } from './guards/room-selected.guard';

const redirectToLoginPage = () => redirectUnauthorizedTo(['account/login']);

const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard, AccountPresentGuard, AccountVerifiedGuard, RoomSelectedGuard],
    data: { authGuardPipe: redirectToLoginPage },
  },
  {
    path: 'select-room',
    component: SelectRoomPageComponent,
    canActivate: [AccountPresentGuard, AccountVerifiedGuard],
  },
  {
    path: 'join-room',
    component: JoinRoomComponent,
    canActivate: [AccountPresentGuard, AccountVerifiedGuard],
  },
  {
    path: 'debug',
    component: DebugPageComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AuthGuardModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { PresenceStatus } from '@ranked/model';

@Injectable({
  providedIn: 'root',
})
export class AccountVerifiedGuard {
  constructor(private router: Router, private accountStoreService: AccountStoreService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.accountStoreService.getRankedAccountStatus().pipe(
      filter((status) => status === PresenceStatus.PRESENT),
      mergeMap(() =>
        this.accountStoreService.isRankedAccountVerified().pipe(
          map((isVerified) => {
            if (!isVerified) {
              return this.router.createUrlTree(['account/pending']);
            }

            return true;
          }),
        ),
      ),
    );
  }
}

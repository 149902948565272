export enum ManikinPosition {
  OWN_GOAL = 'ownGoal',
  GOAL_KEEPER = 'goalKeeper',
  LEFT_DEFENSE = 'leftDefense',
  RIGHT_DEFENSE = 'rightDefense',
  LEFT_OUTER_CENTER = 'leftOuterCenter',
  LEFT_INNER_CENTER = 'leftInnerCenter',
  CENTER = 'center',
  RIGHT_INNER_CENTER = 'rightInnerCenter',
  RIGHT_OUTER_CENTER = 'rightOuterCenter',
  LEFT_OFFENSE = 'leftOffense',
  CENTER_OFFENSE = 'centerOffense',
  RIGHT_OFFENSE = 'rightOffense',
}

import { AllowedContextTypes, AllowedTimeFrames } from './metadata';

export enum AllowedTimeFramesDTO {
  LAST_DAY = 'LAST_DAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  ALL_TIME = 'ALL_TIME',
}

export function toAllowedTimeFrames(timeFrame: AllowedTimeFramesDTO): AllowedTimeFrames {
  switch (timeFrame) {
    case AllowedTimeFramesDTO.LAST_DAY:
      return AllowedTimeFrames.LAST_DAY;
    case AllowedTimeFramesDTO.LAST_WEEK:
      return AllowedTimeFrames.LAST_WEEK;
    case AllowedTimeFramesDTO.LAST_MONTH:
      return AllowedTimeFrames.LAST_MONTH;
    case AllowedTimeFramesDTO.LAST_YEAR:
      return AllowedTimeFrames.LAST_YEAR;
    case AllowedTimeFramesDTO.ALL_TIME:
      return AllowedTimeFrames.ALL_TIME;
  }
}

export enum AllowedContextTypesDTO {
  ACCOUNT_IN_ROOM = 'ACCOUNT_IN_ROOM',
  ACCOUNT = 'ACCOUNT',
  ROOM = 'ROOM',
  GLOBAL = 'GLOBAL',
}

export function toAllowedContextTypes(contextType: AllowedContextTypesDTO): AllowedContextTypes {
  switch (contextType) {
    case AllowedContextTypesDTO.ACCOUNT_IN_ROOM:
      return AllowedContextTypes.ACCOUNT_IN_ROOM;
    case AllowedContextTypesDTO.ACCOUNT:
      return AllowedContextTypes.ACCOUNT;
    case AllowedContextTypesDTO.ROOM:
      return AllowedContextTypes.ROOM;
    case AllowedContextTypesDTO.GLOBAL:
      return AllowedContextTypes.GLOBAL;
  }
}

import { createAction, props } from '@ngrx/store';
import { propsWithError } from '@ranked/logging';
import { Room, RoomMatchInfo } from '@ranked/model';

export const RoomLoaded = createAction('[Room/Room API] Room Loaded', props<{ room: Room }>());

export const UpdateCurrentRoom = createAction('[Room/Select Table Page] Update Current Room');

export const RoomRemoved = createAction('[Room/Effect] Room Removed');

export const LeaveRoomFromHomePage = createAction('[Room/Home Page] Leave Room From Home Page');

export const LeaveRoomOnLogout = createAction('[Room/Effect] Leave Room On Logout');

export const RoomLeft = createAction('[Room/Effect] Room Left');

export const LoadMatchList = createAction('[Room/Routing] Load Match List');

export const MatchListLoaded = createAction('[Room/Room API] Match List Loaded', props<{ matchList: RoomMatchInfo[] }>());

export const LoadMatchListFailed = createAction('[Room/Room API] Load Match List Failed', propsWithError<{ error: unknown }>());

export const CreateMatch = createAction('[Room/Select Table Page] Create Match', props<{ tableName: string }>());

export const CreateMatchFailed = createAction('[Room/Room API] Create Match Failed', propsWithError<{ error: unknown }>());

export const ParticipateInMatch = createAction('[Room/Select Table Page] Participate In Match', props<{ matchId: string }>());

export const ParticipateInMatchFailed = createAction('[Room/Match API] Participate In Match Failed', propsWithError<{ error: unknown }>());

export const ReadCurrentMatchId = createAction('[Room/Effect] Read Current Match Id', props<{ room: Room }>());

export const CurrentMatchFound = createAction('[Room/Effect] Current Match Found', props<{ matchId: string }>());

export const ReenterMatch = createAction('[Room/Room API] Reenter Current Match', props<{ matchId: string }>());

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountModule } from '@ranked/account';
import { DeviceModule } from '@ranked/device';
import { ConsoleLogger, GlobalErrorLoggerService, HttpLoggerInterceptor, LoggerService, LoggingModule } from '@ranked/logging';
import { RoomModule } from '@ranked/room';
import { RoutingModule } from '@ranked/routing';
import { ENVIRONMENT_TOKEN, EnvironmentType, SettingsModule } from '@ranked/settings';
import { SharedUiModule } from '@ranked/shared-ui';
import { StatisticsModule } from '@ranked/statistics';
import { UserFeedbackModule } from '@ranked/user-feedback';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DebugPageComponent } from './components/debug/debug-page.component';
import { HomePageComponent } from './components/home/home-page.component';
import { HomeShutterButtonComponent } from './components/home/home-shutter-button/home-shutter-button.component';
import { JoinRoomComponent } from './components/join-room/join-room.component';
import { SelectRoomPageComponent } from './components/select-room/select-room-page.component';
import { BearerTokenInterceptor, DeviceIdInterceptor } from './interceptors';
import { RankedBackendLogger } from './logger/ranked-backend-logger';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HomeShutterButtonComponent,
    SelectRoomPageComponent,
    JoinRoomComponent,
    DebugPageComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    LoggingModule.forRoot(),
    RoutingModule,
    SharedUiModule,
    DeviceModule,
    SettingsModule,
    UserFeedbackModule,
    AccountModule,
    RoomModule,
    StatisticsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorLoggerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoggerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceIdInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    { provide: ENVIRONMENT_TOKEN, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(ENVIRONMENT_TOKEN) env: EnvironmentType, router: Router, httpClient: HttpClient, loggerService: LoggerService) {
    registerLocaleData(localeDe, 'de');

    loggerService.addLogger(new RankedBackendLogger(httpClient, env));

    if (!env.production) {
      loggerService.addLogger(new ConsoleLogger());
      router.navigateByUrl('/');
    }
  }
}

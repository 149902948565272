import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableOccupationStatus } from '../model/table-occupation-status';

@Pipe({
  name: 'beautifyOccupationStatus',
})
export class BeautifyOccupationStatusPipe implements PipeTransform {
  public constructor(private translationService: TranslateService) {}

  public transform(occupationStatus: TableOccupationStatus): string {
    let translationKey = 'room.beautify-occupation-status.';

    switch (occupationStatus) {
      case TableOccupationStatus.FREE:
        translationKey += 'free';
        break;
      case TableOccupationStatus.MATCH_PREPARE:
        translationKey += 'preparing';
        break;
      case TableOccupationStatus.BLOCKED:
        translationKey += 'blocked';
        break;
    }

    return this.translationService.instant(translationKey);
  }
}

import { Participant } from './participant';

export enum ParticipantPosition {
  OFFENSE = 'attack',
  DEFENSE = 'defense',
  SOLO = 'solo',
}

export interface ParticipantInMatchInfo {
  position: ParticipantPosition;
}

export type ParticipantInMatch = [Participant, ParticipantInMatchInfo];

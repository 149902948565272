import { MatchStateDTO, toMatchStatus } from './match-state-dto';
import { RoomMatchInfo } from './room';

export interface RoomMatchInfoDTO {
  matchId: string;
  tableName: string;
  matchState: MatchStateDTO;
}

export function toRankedRoomMatchInfo(roomMatchInfo: RoomMatchInfoDTO): RoomMatchInfo {
  return {
    matchId: roomMatchInfo.matchId,
    tableName: roomMatchInfo.tableName,
    matchStatus: toMatchStatus(roomMatchInfo.matchState),
  };
}

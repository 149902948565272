import { createReducer, Action } from '@ngrx/store';

export const ROUTING_FEATURE_KEY = 'routing';

export type RoutingState = {
  // Nothing here for now.
};

export const initialRoutingState: RoutingState = {};

const routingReducer = createReducer(initialRoutingState);

export function reducer(state: RoutingState | undefined, action: Action): RoutingState {
  return routingReducer(state, action);
}

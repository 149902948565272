import { MatchStatus } from '..';
import { Participant } from './participant';
import { RuleSet } from './rule-set';
import { Table } from './table';

export interface RoomMatchInfo {
  matchId: string;
  tableName: string;
  matchStatus: MatchStatus;
}

export interface Room {
  id: string;
  name: string;
  tables: Array<Table>;
  participants: Array<Participant>;
  matches: Array<RoomMatchInfo>;
  ruleSets: Array<RuleSet>;
}

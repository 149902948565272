import { Component, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsStoreService } from '@ranked/settings';

@Component({
  selector: 'ranked-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isSafari = false;

  @HostBinding('class')
  public hostClass = 'theme-global';

  constructor(translate: TranslateService, settingsStoreService: SettingsStoreService) {
    translate.setDefaultLang('de');
    translate.use('de');

    settingsStoreService.appStarted();

    // little hack to prevent overscroll / rubber band effect on iOS / Safari
    // be aware that this DISABLES SCROLLING COMPLETELY on the body element
    // scrolling in other container inside the body will still work
    if (this.isSafariBrowser()) {
      document.body.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
      this.isSafari = true;
    }

    // screen?.orientation?.lock('portrait-primary').catch(() => void 0);
  }

  private isSafariBrowser(): boolean {
    return navigator.userAgent.indexOf('Chrome') < 0 && navigator.userAgent.indexOf('Safari') >= 0;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatchScoreHistory, MatchStatus, MatchWinner, RuleSet, Table, DevicePosition } from '@ranked/model';
import { MatchUtils } from '../../match.utils';

@Component({
  selector: 'ranked-match-info',
  templateUrl: './match-info.component.html',
  styleUrls: ['./match-info.component.scss'],
})
export class MatchInfoComponent {
  @Input() public devicePosition: DevicePosition;
  @Input() public matchStatus: MatchStatus;
  @Input() public matchWinner: MatchWinner;
  @Input() public currentSetCount: number;
  @Input() public matchIsStartable: boolean;
  @Input() public scoreHistory?: MatchScoreHistory;
  @Input() public table: Table;
  @Input() public ruleSet: RuleSet;

  @Output() public matchStarted = new EventEmitter<void>();
  @Output() public matchCompleted = new EventEmitter<void>();

  public correspondingColorForTableSide(): string {
    return MatchUtils.correspondingColorForTableSide(this.devicePosition, this.table.color1, this.table.color2);
  }

  public onStartButtonClicked(): void {
    this.matchStarted.emit();
  }

  public onRematchButtonClicked(): void {
    // TODO
  }

  public onFinishButtonClicked(): void {
    this.matchCompleted.emit();
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, interval, Observable } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { WakeLockService } from '@ranked/device';
import { MatchStatus } from '@ranked/model';
import { isDefined } from '@ranked/utils';
import { createSelectableTable, SelectableTable } from '../../model/selectable-table';
import { TableOccupationStatus } from '../../model/table-occupation-status';
import { RoomStoreService } from '../../services/room-store.service';

@Component({
  selector: 'ranked-select-table',
  templateUrl: './select-table.component.html',
  styleUrls: ['./select-table.component.scss'],
})
export class SelectTableComponent implements OnDestroy {
  // Polling every other second is very inefficient, but currently the easiest solution for alpha.
  // In the future the backend will provide a websocket connection to get updates for the room,
  // the same way as for the match. Then the getter functions from the roomStoreService will automatically
  // trigger every time the room data change and so this polling will not be needed anymore.
  private pollingSubscription = interval(2000).subscribe(() => {
    this.roomStoreService.updateCurrentRoom();
  });

  public useList$: Observable<boolean> = this.roomStoreService.getAllTables().pipe(map((allTables) => allTables?.length > 5));

  public freeTables$: Observable<SelectableTable[]> = this.roomStoreService.getAllTables().pipe(
    filter(isDefined),
    map((allTables) =>
      allTables.filter((table) => table.isFree).map((freeTable) => createSelectableTable(freeTable, TableOccupationStatus.FREE)),
    ),
  );

  public tablesWithPreparingMatches$: Observable<SelectableTable[]> = this.roomStoreService.getAllMatches().pipe(
    filter(isDefined),
    map((allMatches) => allMatches.filter((match) => match.matchStatus === MatchStatus.PREPARE)),
    withLatestFrom(this.roomStoreService.getAllTables().pipe(filter(isDefined))),
    map(([preparingMatches, tables]) =>
      preparingMatches.map((match) => {
        const tableForMatch = tables.find((table) => table.name === match.tableName);
        return createSelectableTable(tableForMatch, TableOccupationStatus.MATCH_PREPARE, match.matchId);
      }),
    ),
  );

  public blockedTables$: Observable<SelectableTable[]> = this.roomStoreService.getAllMatches().pipe(
    filter(isDefined),
    map((allMatches) => allMatches.filter((match) => match.matchStatus === MatchStatus.RUNNING || match.matchStatus === MatchStatus.ENDED)),
    withLatestFrom(this.roomStoreService.getAllTables().pipe(filter(isDefined))),
    map(([preparingMatches, tables]) =>
      preparingMatches.map((match) => {
        const tableForMatch = tables.find((table) => table.name === match.tableName);
        return createSelectableTable(tableForMatch, TableOccupationStatus.BLOCKED);
      }),
    ),
  );

  public allTables$: Observable<SelectableTable[]> = combineLatest([
    this.freeTables$,
    this.tablesWithPreparingMatches$,
    this.blockedTables$,
  ]).pipe(map((tables) => [].concat(...tables).sort((a, b) => (a.name < b.name ? -1 : 1))));

  constructor(private wakeLockService: WakeLockService, private roomStoreService: RoomStoreService, private router: Router) {}

  private freeTableSelected(tableName: string): void {
    this.navigateToMatchOnNextGrantedParticipation();
    this.roomStoreService.createMatch({ tableName });
  }

  private tableWithPreparingMatchSelected(matchId: string): void {
    this.navigateToMatchOnNextGrantedParticipation();
    this.roomStoreService.participateInMatch({ matchId });
  }

  private navigateToMatchOnNextGrantedParticipation(): void {
    this.roomStoreService
      .matchParticipationGranted$()
      .pipe(first())
      .subscribe(() => {
        this.router.navigateByUrl('/room/match');
      });
  }

  public ngOnDestroy(): void {
    this.pollingSubscription.unsubscribe();
  }

  public onTableClicked(table: SelectableTable): void {
    this.wakeLockService.enableWakeLock();
    if (table.status === TableOccupationStatus.FREE) {
      this.freeTableSelected(table.name);
    } else if (table.status === TableOccupationStatus.MATCH_PREPARE && table.matchId) {
      this.tableWithPreparingMatchSelected(table.matchId);
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DevicePosition, MatchTeam, ParticipantPosition, ParticipantInMatch, ScoreGoalInfo, ManikinPosition } from '@ranked/model';

@Component({
  selector: 'ranked-match-running-side',
  templateUrl: './match-running-side.component.html',
  styleUrls: ['./match-running-side.component.scss'],
})
export class MatchRunningSideComponent implements OnChanges {
  public participant: ParticipantInMatch;
  public showManikinSelection = false;

  @Input() public devicePosition: DevicePosition;
  @Input() public top: boolean;
  @Input() public color: string;

  @Input() public relevantParticipantPosition: 'all' | ParticipantPosition;
  @Input() public team: MatchTeam;
  @Input() public participants: Array<ParticipantInMatch>;

  @Output() public scoreGoal = new EventEmitter<ScoreGoalInfo>();

  // private particpantsAreEqual(participants1: Array<ParticipantInMatch>, participants2: Array<ParticipantInMatch>): boolean {
  //   if (participants1.length !== participants2.length) {
  //     return false;
  //   }

  //   return participants1.every(
  //     participant => participants2.findIndex(p => p[0].id === participant[0].id
  //       && p[1].position === participant[1].position) >= 0
  //   );
  // }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['relevantParticipantPosition'] || changes['participants']) {
      if (this.relevantParticipantPosition === 'all') {
        // TODO: what to do when we show more than one player on this side?
      } else {
        this.participant = this.participants.find((p) => {
          return p[1].position === this.relevantParticipantPosition;
        });
      }
    }
  }

  public onGoalCounterClicked(): void {
    if (!this.devicePosition) {
      // TODO: what to do, when there's just one smartphone?
    } else {
      this.showManikinSelection = true;
    }
  }

  public manikinSelected(manikin: ManikinPosition): void {
    this.showManikinSelection = false;
    this.scoreGoal.emit({ participant: this.participant[0], manikin });
  }

  public closeManikinSelection(): void {
    this.showManikinSelection = false;
  }
}

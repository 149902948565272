import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { AccountStoreService } from '../../services/account-store.service';

@Component({
  selector: 'ranked-account-info-page',
  templateUrl: './account-info-page.component.html',
  styleUrls: ['./account-info-page.component.scss'],
})
export class AccountInfoPageComponent {
  private clickCount = 0;
  private clickTimeout: number | undefined = undefined;

  public showDebugButton = false;
  public account$ = this.accountStoreService.getRankedAccount();

  constructor(private accountStoreService: AccountStoreService, private router: Router) {}

  public onAvatarImageClicked(): void {
    if (this.showDebugButton) {
      return;
    }

    if (this.clickTimeout !== undefined) {
      clearTimeout(this.clickTimeout);
    }

    if (++this.clickCount > 10) {
      this.showDebugButton = true;
    } else {
      this.clickTimeout = setTimeout(() => {
        this.clickCount = 0;
      }, 500);
    }
  }

  public logout(): void {
    this.accountStoreService
      .isUserLoggedIn()
      .pipe(
        filter((value) => value === false),
        first(),
      )
      .subscribe(() => {
        this.router.navigateByUrl('');
      });

    this.accountStoreService.logout();
  }
}

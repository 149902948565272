import { MatchRuleDTO, toRuleSet } from './match-rule-dto';
import { PlayerDTO, playerToParticipant } from './player-dto';
import { Room } from './room';
import { RoomMatchInfoDTO, toRankedRoomMatchInfo } from './room-match-info-dto';
import { TableDTO, toTable } from './table-dto';
import { TeamDTO, teamToParticipant } from './team-dto';

export interface RoomDTO {
  roomId: string;
  name: string;
  tables: Array<TableDTO>;
  players: Array<PlayerDTO>;
  teams: Array<TeamDTO>;
  matches: Array<RoomMatchInfoDTO>;
  rules: Array<MatchRuleDTO>;
}

export function toRankedRoomState(room: RoomDTO): Room {
  const roomState: Room = {
    id: room.roomId,
    name: room.name,
    tables: room.tables.map(toTable),
    participants: [...room.players.map(playerToParticipant), ...room.teams.map(teamToParticipant)],
    matches: room.matches.map(toRankedRoomMatchInfo),
    ruleSets: room.rules.map(toRuleSet),
  };

  return roomState;
}

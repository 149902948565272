import { Injectable } from '@angular/core';
import { dispatch, observe, select, StoreService } from '@ngxp/store-service';
import { MatchJoined } from '@ranked/match';
import { CreateMatch, LeaveRoomFromHomePage, ParticipateInMatch, ReenterMatch, RoomLoaded, UpdateCurrentRoom } from '../state/room.actions';
import { RoomState } from '../state/room.reducer';
import { getAllMatches, getAllTables, getCurrentMatchId, getCurrentRoom, getCurrentRoomId } from '../state/room.selectors';

@Injectable()
export class RoomStoreService extends StoreService<RoomState> {
  public changeRoom = dispatch(LeaveRoomFromHomePage);

  public updateCurrentRoom = dispatch(UpdateCurrentRoom);

  public createMatch = dispatch(CreateMatch);

  public participateInMatch = dispatch(ParticipateInMatch);

  public reenterMatch = dispatch(ReenterMatch);

  public getCurrentRoom = select(getCurrentRoom);

  public getCurrentRoomId = select(getCurrentRoomId);

  public getAllTables = select(getAllTables);

  public getAllMatches = select(getAllMatches);

  public matchParticipationGranted$ = observe([MatchJoined]);

  public roomSuccessfullyLoaded$ = observe([RoomLoaded]);

  public getCurrentMatchId = select(getCurrentMatchId);
}

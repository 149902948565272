export enum AllowedTimeFrames {
  LAST_DAY = 'LAST_DAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  ALL_TIME = 'ALL_TIME',
}

export enum AllowedContextTypes {
  ACCOUNT_IN_ROOM = 'ACCOUNT_IN_ROOM',
  ACCOUNT = 'ACCOUNT',
  ROOM = 'ROOM',
  GLOBAL = 'GLOBAL',
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState, ACCOUNT_FEATURE_KEY } from './account.reducer';

export const getAccountState = createFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY);

export const getRankedAccountStatus = createSelector(getAccountState, (state: AccountState) => state.accountStatus);

export const getRankedAccount = createSelector(getAccountState, (state: AccountState) => state.account);

export const getRankedAccountId = createSelector(getAccountState, (state: AccountState) => state.account?.id);

export const getRoomMemberships = createSelector(getAccountState, (state: AccountState) => state.account.roomMemberships);

export const getCurrentRoomStatus = createSelector(getAccountState, (state: AccountState) => state.currentRoomStatus);

import { Participant } from './participant';

export interface TeamDTO {
  name: string;
  teamAvatarUrl: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function teamToParticipant(teamDTO: TeamDTO): Participant {
  // TODO: implement teams as participants
  return {} as Participant;
}

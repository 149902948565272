import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Participant } from '@ranked/model';
import { ENVIRONMENT_TOKEN, EnvironmentType } from '@ranked/settings';
import {
  Metric,
  MetricDataDTO,
  MetricMetadata,
  MetricMetadataDTO,
  MetricQueryParameters,
  Ranking,
  RankingDTO,
  RankingMetadata,
  RankingMetadataDTO,
  RankingQueryParameters,
  toMetric,
  toMetricMetadata,
  toRanking,
  toRankingMetadata,
} from '../model';

// TODO: this service could be merged with the StatisticsDataService in the future once we don't need this service individually anymore
@Injectable()
export class StatisticsClientService {
  public constructor(@Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType, private httpClient: HttpClient) {}

  public getMetricsMetadata(): Observable<MetricMetadata[]> {
    return this.httpClient
      .get<MetricMetadataDTO[]>(`${this.environment.baseUrl}/api/metrics`)
      .pipe(map((metadataDTO) => metadataDTO.map(toMetricMetadata)));
  }

  public getMetric(metricName: string, params: MetricQueryParameters, accountId?: string, roomId?: string): Observable<Metric> {
    const queryParams: Record<string, string> = {
      contextType: params.contextType,
      timeFrame: params.timeFrame,
    };

    if (accountId !== undefined) {
      queryParams['accountId'] = accountId;
    }

    if (roomId !== undefined) {
      queryParams['roomId'] = roomId;
    }

    return this.httpClient
      .get<MetricDataDTO[]>(`${this.environment.baseUrl}/api/metrics/${metricName}`, { params: queryParams })
      .pipe(map(toMetric));
  }

  public getRankingsMetadata(): Observable<RankingMetadata[]> {
    return this.httpClient
      .get<RankingMetadataDTO[]>(`${this.environment.baseUrl}/api/rankings`)
      .pipe(map((metadataDTO) => metadataDTO.map(toRankingMetadata)));
  }

  public getRanking(
    rankingName: string,
    params: RankingQueryParameters,
    availableParticipants: Participant[],
    accountId?: string,
    roomId?: string,
  ): Observable<Ranking> {
    const queryParams: Record<string, string> = {
      contextType: params.contextType,
      timeFrame: params.timeFrame,
    };

    if (accountId !== undefined) {
      queryParams['accountId'] = accountId;
    }

    if (roomId !== undefined) {
      queryParams['roomId'] = roomId;
    }

    return this.httpClient
      .get<RankingDTO[]>(`${this.environment.baseUrl}/api/rankings/${rankingName}`, { params: queryParams })
      .pipe(map((rankingDTO) => toRanking(rankingDTO, availableParticipants)));
  }
}

<div class="manikin-selection theme-{{ themeColor }} {{ top ? 'top' : 'bottom' }}">
  <ranked-safe-area color="primary" [class]="top ? 'bottom' : 'top'"></ranked-safe-area>
  <ranked-navbar class="navbar" [iconTap]="goBack" title="{{ 'match.manikin-selection.headline' | translate }}"> </ranked-navbar>

  <div class="manikins">
    <div class="row" *ngFor="let row of manikins">
      <div class="pole"></div>
      <button class="manikin" id="{{ manikin }}" *ngFor="let manikin of row" (click)="onManikinClicked(manikin)">
        <div class="head-border"></div>
        <div class="cap"></div>
        <div class="head"></div>
      </button>
    </div>
  </div>

  <button
    ranked-button
    variant="flat"
    color="secondary"
    [fullWidth]="true"
    text="{{ 'match.manikin-selection.own-goal' | translate }}"
    (click)="onOwnGoalButtonClicked()"
  ></button>

  <ranked-safe-area [class]="top ? 'top' : 'bottom'" color="secondary"></ranked-safe-area>
</div>

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { DevicePosition, Match, MatchScoreHistory, MatchStatus, Participant, RuleSet, Table } from '@ranked/model';
import { NamedIcon } from '@ranked/shared-ui';
import { transformSecondsToMatchTimeString } from '../../helper/time-format';
import { MatchUtils } from '../../match.utils';
import { ParticipantTeamColorMapping } from '../../model/participant-team-color-mapping';
import { MatchStoreService } from '../../services/match-store.service';

export interface MatchRelevantInfo {
  devicePosition: DevicePosition;
  match: Match;
  table: Table;
  ruleSets: RuleSet[];
  participants: Participant[];
}

@Component({
  selector: 'ranked-match',
  templateUrl: './match-page.component.html',
  styleUrls: ['./match-page.component.scss'],
})
export class MatchPageComponent {
  private positioningHintConfirmedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public ruleSet$: Observable<RuleSet>;
  public matchRelevantInfo$: Observable<MatchRelevantInfo>;
  public matchScoreHistory$: Observable<MatchScoreHistory>;
  public selectedParticipants$: Observable<ParticipantTeamColorMapping>;
  public shutterPosition$: Observable<'right' | 'left'>;
  public correspondingColorForTableSide$: Observable<string>;
  public matchPaused$: Observable<boolean>;
  public undoDisabled$: Observable<boolean>;
  public shutterText$: Observable<string>;
  public positioningHintConfirmed$: Observable<boolean>;

  public homeIcon: NamedIcon = {
    name: this.translateAndUpperCase('match.shutter.home'),
    iconName: 'home',
  };
  public undoIcon: NamedIcon = {
    name: this.translateAndUpperCase('match.shutter.undo'),
    iconName: 'undo',
  };

  constructor(private translateService: TranslateService, private matchStoreService: MatchStoreService) {
    // This might also be a selector combining multiple streams of data for a Match
    this.matchRelevantInfo$ = combineLatest([
      matchStoreService.getDevicePosition(),
      matchStoreService.getMatch(),
      matchStoreService.getTable(),
      matchStoreService.getParticipants(),
      matchStoreService.getRuleSets(),
    ]).pipe(
      filter((info) => info.every((entry) => !!entry)),
      map((info) => ({
        devicePosition: info[0],
        match: info[1],
        table: info[2],
        participants: info[3],
        ruleSets: info[4],
      })),
    );

    this.matchPaused$ = matchStoreService.getMatch().pipe(
      filter((match) => !!match),
      map((match) => match.status === MatchStatus.PAUSED),
    );

    this.undoDisabled$ = matchStoreService.getMatch().pipe(map((match) => !match?.undoPossible));
    this.positioningHintConfirmed$ = this.positioningHintConfirmedSubject$.asObservable();

    this.shutterText$ = combineLatest([this.matchRelevantInfo$, this.positioningHintConfirmed$]).pipe(
      map(([info, hintConfirmed]) => {
        const matchStatus = info.match.status;
        switch (matchStatus) {
          case MatchStatus.PREPARE:
            if (hintConfirmed) {
              return this.translateService.instant('match.shutter.menu');
            } else {
              return this.translateService.instant('common.ok');
            }
          case MatchStatus.PAUSED:
            return this.translateService.instant('match.shutter.pause');
          default:
            return transformSecondsToMatchTimeString(info.match.currentTime);
        }
      }),
    );

    this.shutterPosition$ = matchStoreService
      .getDevicePosition()
      .pipe(map((devicePosition) => (devicePosition === DevicePosition.RIGHT ? 'right' : 'left')));

    this.correspondingColorForTableSide$ = this.matchRelevantInfo$.pipe(
      map(({ devicePosition, table }) => MatchUtils.correspondingColorForTableSide(devicePosition, table.color1, table.color2)),
    );

    this.matchScoreHistory$ = matchStoreService.getScoreHistory();

    this.ruleSet$ = combineLatest([matchStoreService.getMatch(), matchStoreService.getRuleSets()]).pipe(
      filter(([match]) => match !== undefined),
      takeWhile(([match]) => match.status === MatchStatus.PREPARE),
      map(([match, ruleSets]) => ruleSets.find((ruleSet) => ruleSet.name === match.ruleSetName)),
    );

    this.selectedParticipants$ = combineLatest([matchStoreService.getMatch(), matchStoreService.getTable()]).pipe(
      filter(([match]) => match !== undefined),
      takeWhile(([match]) => match.status === MatchStatus.PREPARE),
      map(([match, table]) => {
        const mapping: ParticipantTeamColorMapping = {};

        if (match.teamColor1?.attackPlayerName) {
          mapping[match.teamColor1.attackPlayerName] = table.color1;
        }

        if (match.teamColor1?.defensePlayerName) {
          mapping[match.teamColor1.defensePlayerName] = table.color1;
        }

        if (match.teamColor2?.attackPlayerName) {
          mapping[match.teamColor2.attackPlayerName] = table.color2;
        }

        if (match.teamColor2?.defensePlayerName) {
          mapping[match.teamColor2.defensePlayerName] = table.color2;
        }

        return mapping;
      }),
    );
  }

  private translateAndUpperCase(key: string): string {
    return (this.translateService.instant(key) as string).toUpperCase();
  }

  // TODO: where do we want to have this logic?
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  public complementaryColor(color1: string, color2: string): string {
    // if (color1 === 'black' || color2 === 'black') {
    //   return 'darkblue';
    // }
    return 'gray';
  }

  public startMatch(): void {
    this.matchStoreService.startMatchFromMatchPage();
  }

  public completeMatch(): void {
    this.matchStoreService.completeMatchFromMatchPage();
  }

  public goHome(): void {
    this.matchStoreService.abortMatchFromMatchPage();
  }

  public undoLastGoal(): void {
    this.matchStoreService.undoGoalFromMatchPage();
  }

  public pauseMatch(matchStatus: MatchStatus): void {
    if (matchStatus === MatchStatus.RUNNING) {
      this.matchStoreService.pauseMatchFromMatchPage();
    }
  }

  public shutterClosed(currentMatchStatus: MatchStatus): void {
    this.positioningHintConfirmedSubject$.next(true);

    if (currentMatchStatus === MatchStatus.PAUSED) {
      this.matchStoreService.resumeMatchFromMatchPage();
    }
  }
}

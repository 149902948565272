export interface RoomMembership {
  accountId: string;
  roomId: string;
  roomName: string;
  playerName: string;
  playerAvatarUrl: string;
  membershipTypes: MembershipType[];
}

export enum MembershipType {
  ADMINISTRATOR = 'ADMINSTRATOR',
  VISITOR = 'VISITOR',
  PLAYER = 'PLAYER',
}

import { AllowedContextTypes, AllowedTimeFrames } from './metadata';

export interface MetricQueryParameters {
  contextType: AllowedContextTypes;
  timeFrame: AllowedTimeFrames;
}

export interface MetricData {
  time: Date;
  value: number;
}

export type Metric = MetricData[];

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MatchModule, MatchPageComponent } from '@ranked/match';
import { SharedUiModule } from '@ranked/shared-ui';
import { RoomInfoListComponent } from './components/room-info-list/room-info-list.component';
import { SelectTableComponent } from './components/select-table/select-table.component';
import { SelectableTableComponent } from './components/selectable-table/selectable-table.component';
import { BeautifyOccupationStatusPipe } from './pipes/beautify-occupation-status.pipe';
import { RoomStoreService } from './services/room-store.service';
import { UpdateCurrentRoom } from './state/room.actions';
import { RoomEffects } from './state/room.effects';
import * as fromRoom from './state/room.reducer';

const roomRoutes: Routes = [
  {
    path: 'room',
    children: [
      { path: '', redirectTo: 'select-table', pathMatch: 'full' },
      {
        path: 'select-table',
        component: SelectTableComponent,
        data: { onLoadAction: UpdateCurrentRoom() },
      },
      { path: 'match', component: MatchPageComponent },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(roomRoutes),
    StoreModule.forFeature(fromRoom.ROOM_FEATURE_KEY, fromRoom.roomReducer),
    EffectsModule.forFeature([RoomEffects]),
    SharedUiModule,
    MatchModule,
    TranslateModule,
  ],
  providers: [RoomStoreService],
  declarations: [RoomInfoListComponent, SelectTableComponent, SelectableTableComponent, BeautifyOccupationStatusPipe],
  exports: [RoomInfoListComponent],
})
export class RoomModule {}

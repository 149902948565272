import { Table } from './table';

export enum TableState {
  FREE = 'free',
  OCCUPIED = 'occupied',
}

export interface TableDTO {
  name: string;
  color1: string;
  color2: string;
  tableState: TableState;
}

export const toTable = (tableDTO: TableDTO): Table => ({
  name: tableDTO.name,
  color1: tableDTO.color1,
  color2: tableDTO.color2,
  isFree: tableDTO.tableState === TableState.FREE,
});

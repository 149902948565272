// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://app.new-dev.get-ranked.app',
  holisticonRoomId: '1d16ded3-ca73-47f9-9957-946fa6efa170',
  firebase: {
    apiKey: 'AIzaSyCwxEy56n3AMRZ65Rt7Bn_G6z-Ji_WSLBg',
    authDomain: 'ranked-ba0d2.firebaseapp.com',
    projectId: 'ranked-ba0d2',
    storageBucket: 'ranked-ba0d2.appspot.com',
    messagingSenderId: '183143801622',
    appId: '1:183143801622:web:b9bd4f6a9a4ad413e7e637',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManikinPosition, ParticipantPosition } from '@ranked/model';

@Component({
  selector: 'ranked-manikin-selection',
  templateUrl: './manikin-selection.component.html',
  styleUrls: ['./manikin-selection.component.scss'],
})
export class ManikinSelectionComponent {
  private readonly manikinsForOffense = [
    [
      ManikinPosition.LEFT_OUTER_CENTER,
      ManikinPosition.LEFT_INNER_CENTER,
      ManikinPosition.CENTER,
      ManikinPosition.RIGHT_INNER_CENTER,
      ManikinPosition.RIGHT_OUTER_CENTER,
    ],
    [ManikinPosition.LEFT_OFFENSE, ManikinPosition.CENTER_OFFENSE, ManikinPosition.RIGHT_OFFENSE],
  ];

  private readonly manikinsForDefense = [[ManikinPosition.GOAL_KEEPER], [ManikinPosition.LEFT_DEFENSE, ManikinPosition.RIGHT_DEFENSE]];

  public manikins: Array<Array<ManikinPosition>> = [];

  @Input() public themeColor: string;
  @Input() public top: boolean;
  @Input() public set position(value: ParticipantPosition) {
    if (value === ParticipantPosition.OFFENSE) {
      this.manikins = this.manikinsForOffense;
    } else if (value === ParticipantPosition.DEFENSE) {
      this.manikins = this.manikinsForDefense;
    } else {
      // TODO: what to do with solo players?
    }
  }

  @Output() public selected = new EventEmitter<ManikinPosition>();
  @Output() public closed = new EventEmitter<void>();

  public goBack = (): void => {
    this.closed.emit(null);
  };

  public onManikinClicked(manikin: ManikinPosition): void {
    this.selected.emit(manikin);
  }

  public onOwnGoalButtonClicked(): void {
    this.selected.emit(ManikinPosition.OWN_GOAL);
  }
}

import { AllowedContextTypes, AllowedTimeFrames } from './metadata';

export enum MetricType {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

export enum TimeUnit {
  DATE = 'DATE',
  WEEK = 'WEEK',
  MATCH = 'MATCH',
}

export enum ValueUnit {
  UNITLESS = 'UNITLESS',
  GOAL = 'GOAL',
  MATCH = 'MATCH',
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
}

export interface MetricMetadata {
  name: string;
  type: MetricType;
  timeUnit: TimeUnit;
  valueUnit: ValueUnit;
  allowedTimeFrames: Array<AllowedTimeFrames>;
  allowedContextTypes: Array<AllowedContextTypes>;
}

import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { RoomStoreService } from '@ranked/room';
import {
  AllowedContextTypes,
  AllowedTimeFrames,
  Metric,
  MetricMetadata,
  MetricQueryParameters,
  Ranking,
  RankingMetadata,
  RankingQueryParameters,
} from '../../model';
import { StatisticsClientService } from '../../services/statistics-client.service';

@Component({
  selector: 'ranked-metrics-test',
  templateUrl: './metrics-test.component.html',
  styleUrls: ['./metrics-test.component.scss'],
})
export class MetricsTestComponent {
  private accountId: string;
  private currentRoomId: string;
  private selectedMetricName: Subject<string>;

  public metricsMetadata$: Observable<MetricMetadata[]>;
  public rankingsMetadata$: Observable<RankingMetadata[]>;
  public metric$: Observable<Metric>;
  public ranking$: Observable<Ranking>;

  constructor(
    private statisticsClientService: StatisticsClientService,
    private accountStoreService: AccountStoreService,
    private roomStoreService: RoomStoreService,
  ) {
    accountStoreService
      .getRankedAccountId()
      .pipe(take(1))
      .subscribe((accountId) => {
        this.accountId = accountId;
      });

    roomStoreService
      .getCurrentRoomId()
      .pipe(take(1))
      .subscribe((roomId) => {
        this.currentRoomId = roomId;
      });

    this.selectedMetricName = new Subject();

    this.metricsMetadata$ = statisticsClientService.getMetricsMetadata().pipe(filter(this.isDefined));
    this.rankingsMetadata$ = statisticsClientService.getRankingsMetadata().pipe(filter(this.isDefined));
  }

  // TODO: extract in utils/helper lib
  private isDefined<T>(value?: T): value is T {
    return typeof value !== 'undefined';
  }

  public onMetricSelect(event: Event): void {
    const metricName = (event.target as HTMLSelectElement).value;

    const params: MetricQueryParameters = {
      contextType: AllowedContextTypes.ACCOUNT_IN_ROOM,
      timeFrame: AllowedTimeFrames.ALL_TIME,
    };

    this.metric$ = this.statisticsClientService.getMetric(metricName, params, this.accountId, this.currentRoomId);
  }

  public onRankingSelect(event: Event): void {
    const rankingName = (event.target as HTMLSelectElement).value;

    const params: RankingQueryParameters = {
      contextType: AllowedContextTypes.ROOM,
      timeFrame: AllowedTimeFrames.ALL_TIME,
    };

    this.ranking$ = this.statisticsClientService.getRanking(rankingName, params, [], this.accountId, this.currentRoomId);
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { MatchEnded, MatchJoined } from '@ranked/match';
import { MatchStatus, RoomMatchInfoDTO, toRankedRoomMatchInfo } from '@ranked/model';
import { EnvironmentType, ENVIRONMENT_TOKEN } from '@ranked/settings';
import { UserFeedbackStoreService } from '@ranked/user-feedback';
import { RoomStoreService } from '../services/room-store.service';
import {
  CreateMatch,
  CreateMatchFailed,
  CurrentMatchFound,
  LeaveRoomFromHomePage,
  LeaveRoomOnLogout,
  LoadMatchList,
  LoadMatchListFailed,
  MatchListLoaded,
  ParticipateInMatch,
  ParticipateInMatchFailed,
  ReadCurrentMatchId,
  ReenterMatch,
  RoomLeft,
  RoomLoaded,
} from './room.actions';

export enum RoomSettings {
  SAVED_MATCH_ID_KEY = 'savedMatchId',
}

@Injectable()
export class RoomEffects {
  public static readonly CREATE_MATCH_ORIGIN = 'CREATE_MATCH_ORIGIN';

  private retryCounter = 0;

  // We are not using the @nrwl fetch method here, because then we need to pipe it
  // directly after ofType(Action). Since we need more information than there are in
  // the action properties, the code would be more complex with the fetch method
  // in comparison to the mergeMap variant used here.
  matchListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMatchList),
      withLatestFrom(this.roomStoreService.getCurrentRoom()),
      map(([, room]) => room.id),
      mergeMap((roomId) =>
        this.httpClient.get<RoomMatchInfoDTO[]>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches`).pipe(
          map((matchDTOList) => matchDTOList.map(toRankedRoomMatchInfo)),
          map((matchList) => MatchListLoaded({ matchList })),
          catchError((error) => {
            return of(LoadMatchListFailed({ error }));
          }),
        ),
      ),
    ),
  );

  enableLoadingSpinnerBeforeMatchCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateMatch),
        tap(() => {
          this.userFeedbackStoreService.enableLoadingSpinner({
            origin: RoomEffects.CREATE_MATCH_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  disableLoadingSpinnerAfterMatchCreationOrOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MatchJoined, CreateMatchFailed),
        tap(() => {
          this.userFeedbackStoreService.disableLoadingSpinner({
            origin: RoomEffects.CREATE_MATCH_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  createMatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMatch),
      withLatestFrom(this.roomStoreService.getCurrentRoom()),
      mergeMap(([{ tableName }, room]) =>
        this.httpClient
          .post(
            `${this.environment.baseUrl}/api/rooms/${room.id}/matches`,
            { tableName: tableName, rulesName: room.ruleSets[0].name },
            { observe: 'response' },
          )
          .pipe(
            // creating a match automatically adds the device to it, so we know that we are joined
            map((response) =>
              MatchJoined({
                matchId: this.parseLocationHeaderFromResponse(response),
                roomId: room.id,
                table: room.tables.find((table) => table.name === tableName),
                ruleSets: room.ruleSets,
                participants: room.participants,
              }),
            ),
            catchError((error) => of(CreateMatchFailed({ error }))),
          ),
      ),
    ),
  );

  joinMatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipateInMatch),
      withLatestFrom(this.roomStoreService.getCurrentRoom()),
      mergeMap(([{ matchId }, room]) =>
        this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${room.id}/matches/${matchId}/devices`, {}).pipe(
          map(() => {
            const tableName = room.matches.find((match) => match.matchId === matchId)?.tableName;

            return MatchJoined({
              matchId,
              roomId: room.id,
              table: room.tables.find((table) => table.name === tableName),
              ruleSets: room.ruleSets,
              participants: room.participants,
            });
          }),
          catchError((error) => of(ParticipateInMatchFailed({ error }))),
        ),
      ),
    ),
  );

  saveCurrentMatchId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MatchJoined),
        tap(({ matchId }) => {
          localStorage.setItem(RoomSettings.SAVED_MATCH_ID_KEY, matchId);
          this.retryCounter = 0;
        }),
      ),
    { dispatch: false },
  );

  readCurrentMatchId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomLoaded),
      map(({ room }) => ReadCurrentMatchId({ room })),
    ),
  );

  reenterMatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReenterMatch),
      withLatestFrom(this.roomStoreService.getCurrentRoom()),
      map(([{ matchId }, room]) => ({ match: room.matches.find((match) => match.matchId === matchId), room })),
      map(({ match, room }) =>
        MatchJoined({
          matchId: match.matchId,
          roomId: room.id,
          table: room.tables.find((table) => table.name === match.tableName),
          ruleSets: room.ruleSets,
          participants: room.participants,
        }),
      ),
    ),
  );

  loadCurrentMatchId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReadCurrentMatchId),
      map(({ room }) => {
        const matchId = localStorage.getItem(RoomSettings.SAVED_MATCH_ID_KEY);
        return matchId === null ? undefined : room.matches.find((match) => match.matchId === matchId);
      }),
      filter((match) => match !== undefined && match.matchStatus !== MatchStatus.ENDED),
      map((match) => CurrentMatchFound({ matchId: match.matchId })),
    ),
  );

  removeCurrentMatchId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MatchEnded),
        tap(() => {
          localStorage.removeItem(RoomSettings.SAVED_MATCH_ID_KEY);
          this.retryCounter = 0;
        }),
      ),
    { dispatch: false },
  );

  handleJoinMatchError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipateInMatchFailed, CreateMatchFailed),
      delay(2000),
      map(() => {
        if (++this.retryCounter < 3 || window.confirm('Automatisches Beitreten zum Match hat nicht funktioniert. Noch mal versuchen?')) {
          return LoadMatchList();
        }
      }),
    ),
  );

  // For now, we do not have to do asynchronous work when leaving a room, since this is only a state in the frontend
  // Also, the room Reducer currently does cleanup work on RoomLeft that is disconnected to other tasks.
  // so we are fine just mapping multiple originating events to the RoomLeft action.
  notifyForRoomLeftOnRespectiveEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveRoomFromHomePage, LeaveRoomOnLogout),
      map(() => RoomLeft()),
    ),
  );

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType,
    private actions$: Actions,
    private httpClient: HttpClient,
    private roomStoreService: RoomStoreService,
    private userFeedbackStoreService: UserFeedbackStoreService,
  ) {}

  private parseLocationHeaderFromResponse(response: HttpResponse<unknown>): string {
    const location = response.headers.get('Location');
    return location.slice(location.lastIndexOf('/') + 1);
  }
}

<div class="table-icon">
  <div class="background">
    <div class="marking goal-left"></div>
    <div class="marking goal-right"></div>
    <div class="marking middle">
      <div class="point"></div>
      <div class="circle"></div>
      <div class="line-top"></div>
      <div class="line-bottom"></div>
    </div>
  </div>
  <div class="half left theme-{{ table.color1 }}"></div>
  <div class="half right theme-{{ table.color2 }}"></div>
</div>

<div class="name">{{ table.name }}</div>
<div class="occupation-status" [class.pulsate]="pulsateText">{{ table.status | beautifyOccupationStatus }}</div>

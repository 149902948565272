import { Component, Input } from '@angular/core';

@Component({
  selector: 'ranked-matchset-won-sets-counter',
  templateUrl: './matchset-won-sets-counter.component.html',
  styleUrls: ['./matchset-won-sets-counter.component.scss'],
})
export class MatchsetWonSetsCounterComponent {
  public wonSetsArray: Array<number>;

  @Input() public set wonSets(value: number) {
    this.wonSetsArray = new Array(value).fill(1);
  }
}

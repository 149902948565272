import { RankingMetadata } from './ranking-metadata';

export interface RankingMetadataDTO {
  name: string;
}

export function toRankingMetadata(metadata: RankingMetadataDTO): RankingMetadata {
  const rankingMetadata: RankingMetadata = {
    name: metadata.name,
  };

  return rankingMetadata;
}

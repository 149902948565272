import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { filter, flatMap, take } from 'rxjs/operators';
import { isDefined } from '@ranked/utils';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  private token$: Observable<string>;

  constructor(auth: Auth) {
    this.token$ = idToken(auth).pipe(filter(isDefined));
  }

  intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.token$.pipe(
      take(1),
      flatMap((token) => {
        let newReq = req;
        if (token) {
          newReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });
        }
        return next.handle(newReq);
      }),
    );
  }
}

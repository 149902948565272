import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogFileService } from '../services/log-file.service';

@Injectable()
export class HttpLoggerInterceptor implements HttpInterceptor {
  public constructor(private logFileService: LogFileService) {}

  private logHttpRequest(request: HttpRequest<unknown>, response: HttpResponse<unknown> | HttpErrorResponse): void {
    const { method, body: requestBody, urlWithParams, headers: requestHeaders } = request;
    const status = response.status;
    let responseBody = 'body' in response ? response.body : response.message;

    if (urlWithParams.includes('assets/')) {
      responseBody = 'not included';
    }

    const logData = { requestHeaders, requestBody, responseBody };
    this.logFileService.addDetailedLogEntry(`${status} ${method} request to ${urlWithParams}.`, logData);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            this.logHttpRequest(request, event);
          }
        },
        (error: HttpErrorResponse) => {
          this.logHttpRequest(request, error);
        },
      ),
    );
  }
}

<div
  *ngIf="matchRelevantInfo$ | async as matchRelevantInfo"
  class="match complementary-theme-{{ complementaryColor(matchRelevantInfo.table.color1, matchRelevantInfo.table.color2) }}"
>
  <ranked-match-info
    [devicePosition]="matchRelevantInfo.devicePosition"
    [matchStatus]="matchRelevantInfo.match.status"
    [matchIsStartable]="matchRelevantInfo.match.startable"
    [currentSetCount]="matchRelevantInfo.match.currentSetCount"
    [matchWinner]="matchRelevantInfo.match.winner"
    [scoreHistory]="matchScoreHistory$ | async"
    [table]="matchRelevantInfo.table"
    [ruleSet]="ruleSet$ | async"
    (matchStarted)="startMatch()"
    (matchCompleted)="completeMatch()"
  ></ranked-match-info>

  <div class="side top theme-{{ matchRelevantInfo.table.color1 }}">
    <ranked-match-status-side
      [roomParticipants]="matchRelevantInfo.participants"
      [selectedParticipantsTeamColorMapping]="selectedParticipants$ | async"
      [devicePosition]="matchRelevantInfo.devicePosition"
      [matchStatus]="matchRelevantInfo.match.status"
      [team]="matchRelevantInfo.match.teamColor1"
      [color]="matchRelevantInfo.table.color1"
      position="color1"
      [top]="true"
      [participantSwitchAllowed]="matchRelevantInfo.match.participantSwitchAllowed"
    >
    </ranked-match-status-side>
  </div>

  <div class="side bottom theme-{{ matchRelevantInfo.table.color2 }}">
    <ranked-match-status-side
      [roomParticipants]="matchRelevantInfo.participants"
      [selectedParticipantsTeamColorMapping]="selectedParticipants$ | async"
      [devicePosition]="matchRelevantInfo.devicePosition"
      [matchStatus]="matchRelevantInfo.match.status"
      [team]="matchRelevantInfo.match.teamColor2"
      [color]="matchRelevantInfo.table.color2"
      position="color2"
      [top]="false"
      [participantSwitchAllowed]="matchRelevantInfo.match.participantSwitchAllowed"
    >
    </ranked-match-status-side>
  </div>

  <ranked-shutter
    class="menu theme-{{ correspondingColorForTableSide$ | async }}"
    [position]="shutterPosition$ | async"
    [clipText]="shutterText$ | async"
    [isClosed]="(positioningHintConfirmed$ | async) === true && (matchPaused$ | async) === false"
    (opened)="pauseMatch(matchRelevantInfo.match.status)"
    (closed)="shutterClosed(matchRelevantInfo.match.status)"
  >
    <div class="shutter-content">
      <ng-template [ngIf]="(positioningHintConfirmed$ | async) === true" [ngIfElse]="positioningHint">
        <button class="icon" (click)="goHome()">
          <ranked-named-icon namePosition="bottom" [data]="homeIcon" iconColor="inverse"></ranked-named-icon>
        </button>

        <button [disabled]="undoDisabled$ | async" class="icon" (click)="undoLastGoal()">
          <ranked-named-icon namePosition="bottom" [data]="undoIcon" iconColor="inverse"></ranked-named-icon>
        </button>
      </ng-template>

      <ng-template #positioningHint>
        <span class="positioning-hint">
          {{
            'match.shutter.put-to-goal'
              | translate : { goalColor: 'match.shutter.color.' + (correspondingColorForTableSide$ | async) | translate }
          }}
        </span>
      </ng-template>
    </div>
  </ranked-shutter>

  <div class="overlay-container">
    <ng-template rankedOverlayPlaceholder></ng-template>
  </div>
</div>

import { Injectable } from '@angular/core';
import { select, StoreService } from '@ngxp/store-service';
import { getQueryParams, getRouteData, State } from '../state/routing.selectors';

@Injectable({
  providedIn: 'root',
})
export class RoutingStoreService extends StoreService<State> {
  public getRouteData = select(getRouteData);

  public getQueryParams = select(getQueryParams);
}

import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map, mergeMap, take } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { RoomInfo } from '@ranked/model';
import { RoomStoreService } from '@ranked/room';
import { EnvironmentType, ENVIRONMENT_TOKEN } from '@ranked/settings';
import { NavbarIcon } from '@ranked/shared-ui';

/**
 * This page is contained within "mobile-app" and needs to be "above" the room-lib,
 * because every page within the room-lib relies on the existence of _exactly_ one room (the selected one).
 * Furthermore, this page needs to know where to navigate after a room is selected (i.e. /home)
 * and this information may not be known within a lib.
 */
@Component({
  selector: 'ranked-select-room',
  templateUrl: './select-room-page.component.html',
  styleUrls: ['./select-room-page.component.scss'],
})
export class SelectRoomPageComponent {
  public readonly navbarIcon = NavbarIcon.PROFILE;

  public roomInfos$: Observable<RoomInfo[]>;
  public roomsAvailable$: Observable<boolean>;
  public nickname$: Observable<string>;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: EnvironmentType,
    private router: Router,
    private roomStoreService: RoomStoreService,
    private accountStoreService: AccountStoreService,
  ) {
    this.roomInfos$ = this.accountStoreService.getRoomMemberships().pipe(
      filter((memberships) => memberships !== undefined),
      map((memberships) => {
        return memberships.map((membership) => ({ id: membership.roomId, name: membership.roomName, playerName: membership.playerName }));
      }),
    );

    this.roomsAvailable$ = this.roomInfos$.pipe(map((roomInfos) => roomInfos.length > 0));

    this.nickname$ = this.accountStoreService.getRankedAccount().pipe(map((account) => account.nickname));

    this.automaticJoinHolisticonRoomForHolisticonUser();
  }

  private automaticJoinHolisticonRoomForHolisticonUser(): void {
    this.roomInfos$
      .pipe(
        take(1),
        filter((roomInfos) => !roomInfos.some((roomInfo) => roomInfo.id === this.env.holisticonRoomId)),
        mergeMap(() => this.accountStoreService.getRankedAccount().pipe(take(1))),
      )
      .subscribe((account) => {
        if (account.email.endsWith('@holisticon.de') && this.env.holisticonRoomId?.length > 0) {
          this.accountStoreService.joinRoom({ roomId: this.env.holisticonRoomId });
        }
      });
  }

  // arrow function needed here because it is used as an input
  public goToProfile = (): void => {
    this.router.navigateByUrl('/account/info');
  };

  public onRoomInfoClicked(roomInfo: RoomInfo): void {
    this.roomStoreService
      .roomSuccessfullyLoaded$()
      .pipe(
        filter((loaded) => loaded !== undefined),
        first(),
      )
      .subscribe(() => {
        this.router.navigateByUrl('/home');
      });

    this.accountStoreService.roomSelected({ roomId: roomInfo.id });
  }

  public joinRoom(): void {
    this.router.navigateByUrl('/join-room');
  }
}

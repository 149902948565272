import { MatchScoreHistory } from './match-score-history';

export type MatchScoreHistoryDTO = Array<{
  matchSet: number;
  finalGoalsColor1: number;
  finalGoalsColor2: number;
}>;

export function toRankedMatchScoreHistory(historyDTO: MatchScoreHistoryDTO): MatchScoreHistory {
  const history: MatchScoreHistory = {
    teamColor1: [],
    teamColor2: [],
  };

  historyDTO.forEach((setScoreHistory) => {
    history.teamColor1[setScoreHistory.matchSet - 1] = setScoreHistory.finalGoalsColor1;
    history.teamColor2[setScoreHistory.matchSet - 1] = setScoreHistory.finalGoalsColor2;
  });

  return history;
}

import { createReducer, on, Action } from '@ngrx/store';
import { Match, RuleSet, Participant, Table, MatchScoreHistory, DevicePosition } from '@ranked/model';
import { MatchEnded, MatchJoined, MatchLoaded, MatchScoreHistoryLoaded, MatchUpdateReceived, TimerTicked } from './match.actions';

export const MATCH_FEATURE_KEY = 'match';

export interface MatchState {
  roomId?: string;
  table?: Table;
  ruleSets: RuleSet[];
  participants: Participant[];
  match?: Match;
  devicePosition?: DevicePosition;
  scoreHistory?: MatchScoreHistory;
}

export interface MatchAppState {
  readonly [MATCH_FEATURE_KEY]: MatchState;
}

export const initialMatchState: MatchState = {
  ruleSets: [],
  participants: [],
};

const reducer = createReducer(
  initialMatchState,
  on(MatchJoined, (state, { roomId, table, ruleSets, participants }) => ({
    ...state,
    roomId,
    table,
    ruleSets,
    participants,
  })),
  on(MatchLoaded, MatchUpdateReceived, (state, { match, devicePosition }) => ({
    ...state,
    match,
    devicePosition: devicePosition ?? state.devicePosition,
  })),
  on(MatchScoreHistoryLoaded, (state, { history }) => ({
    ...state,
    scoreHistory: history,
  })),
  on(TimerTicked, (state, { time }) => {
    if (!state.match) {
      return state;
    }

    return {
      ...state,
      match: {
        ...state.match,
        currentTime: time,
      },
    };
  }),
  on(MatchEnded, () => initialMatchState),
);

export function matchReducer(state: MatchState | undefined, action: Action): MatchState {
  return reducer(state, action);
}

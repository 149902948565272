import { createAction, props } from '@ngrx/store';
import { propsWithError } from '@ranked/logging';
import { RoomMembership } from '@ranked/model';
import { FirebaseAuthError } from '../model/firebase-auth-error';
import { RankedAccount } from '../model/ranked-account';

export const LoadSavedRoomId = createAction('[Account/Effect] Load Saved Room Id');

export const SavedRoomIdLoaded = createAction('[Account/Local Storage] Saved Room Id Loaded', props<{ roomId: string }>());

export const LoadSavedRoomIdFailed = createAction('[Account/Local Storage] Load Saved Room Id Failed');

export const SavedRoomIdRemoved = createAction('[Account/Local Storage] Saved Room Id Removed');

export const SelectRoomFromSavedRoomId = createAction('[Account/Effect] Select Room From Saved Room Id', props<{ roomId: string }>());

export const SelectRoomFromSelectRoomPage = createAction(
  '[Account/Select Room Page] Select Room From Select Room Page',
  props<{ roomId: string }>(),
);

export const LoadRoomMemberships = createAction('[Account/Account API] Load Room Memberships');

export const RoomMembershipsLoaded = createAction(
  '[Account/Account Effect] Room Memberships Loaded',
  props<{ memberships: RoomMembership[] }>(),
);

export const LoadRoomMembershipsFailed = createAction(
  '[Account/Account API] Load Room Memberships Failed',
  propsWithError<{ error: unknown }>(),
);

export const LoadRoom = createAction('[Account/Effect] Load Room', props<{ roomId: string }>());

export const LoadRoomFailed = createAction('[Account/Room API] Load Room Failed', propsWithError<{ error: unknown }>());

export const LoginWithEmail = createAction('[Account/Login Page] Login With Email', props<{ email: string; password: string }>());

export const LoginFailure = createAction('[Account/Firebase API] Login Failure', propsWithError<{ error: FirebaseAuthError }>());

export const LoginSuccessful = createAction('[Account/Firebase API] Login Successful');

export const Logout = createAction('[Account/Account Page] Logout');

export const RegisterWithEmail = createAction('[Account/Login Page] Register With Email', props<{ email: string; password: string }>());

export const RegisterSuccessful = createAction('[Account/Firebase API] Register Successful');

export const RegisterFailure = createAction('[Account/Firebase API] Register Failure', propsWithError<{ error: FirebaseAuthError }>());

export const AuthenticateWithGoogle = createAction('[Account/Login Page] Authenticate With Google');

export const AuthenticateWithGoogleFailure = createAction(
  '[Account/Firebase API] Authenticate With Google Failure',
  propsWithError<{ error: FirebaseAuthError }>(),
);

export const RequestNewPassword = createAction('[Account/Forgot Password Page] Request New Password', props<{ email: string }>());

export const RequestNewPasswordSuccessful = createAction('[Account/Firebase API] Request New Password Successful');

export const RequestNewPasswordFailure = createAction(
  '[Account/Firebase API] Request New Password Failure',
  propsWithError<{ error: FirebaseAuthError }>(),
);

export const CreateRankedAccount = createAction(
  '[Account/Create Account Page] Create Ranked Account',
  props<{ nickname: string; avatarUrl: string }>(),
);

export const RankedAccountCreated = createAction('[Account/Account API] Ranked Account Created', props<{ accountId: string }>());

export const CreateRankedAccountFailure = createAction(
  '[Account/Account API] Ranked Account Creation Failure',
  propsWithError<{ error: unknown }>(),
);

export const UpdateCurrentAccount = createAction('[Account/Pending Verification Page] Update Current Account');

export const UpdateCurrentAccountOnVerificationAlreadyFinished = createAction('[Account/Effect] Update Current Account');

export const ResendVerificationMail = createAction('[Account/Pending Verification Page] Resend Verification Mail');

export const ResendVerificationMailSuccessful = createAction('[Account/Account API] Resend Verification Mail Successful');

export const ResendVerificationMailFailure = createAction(
  '[Account/Account API] Resend Verification Mail Failure',
  propsWithError<{ error: unknown }>(),
);

export const RankedAccountRequested = createAction('[Account/Effect] Ranked Account Requested', props<{ accountId: string }>());

export const RankedAccountReceived = createAction('[Account/Account API] Ranked Account Received', props<{ account: RankedAccount }>());

export const LoadRankedAccountFailure = createAction(
  '[Account/Account API] Load Ranked Account Failure',
  propsWithError<{ error: unknown }>(),
);

export const FirebaseUserReceived = createAction(
  '[Account/Firebase API] Firebase User Received',
  props<{ accountId: string; displayName: string; photoUrl: string }>(),
);

export const FirebaseUserRemoved = createAction('[Account/Firebase API] Firebase User Removed');

export const FirebaseUserWithoutAccountReceived = createAction('[Account/Firebase API] Firebase User Without Account Received');

export const JoinRoom = createAction('[Account/Account API] Ranked Account Joins Room', props<{ roomId: string }>());

export const JoinRoomFailure = createAction('[Account/Account API] Ranked Account Room Join Failure', propsWithError<{ error: unknown }>());

export const RoomJoined = createAction('[Room/Room API] Ranked Account joined a room', props<{ roomId: string }>());

export const SelectRoomOnRoomJoined = createAction('[Account/Effect] Select Room On Room Joined', props<{ roomId: string }>());

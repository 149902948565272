import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { SelectableTable } from '../../model/selectable-table';
import { TableOccupationStatus } from '../../model/table-occupation-status';

@Component({
  selector: 'ranked-selectable-table, button[rankedSelectableTable]',
  templateUrl: './selectable-table.component.html',
  styleUrls: ['./selectable-table.component.scss'],
})
export class SelectableTableComponent implements OnChanges {
  pulsateText = false;

  @Input()
  @HostBinding('class.list-item')
  isListItem = true;

  @Input()
  table: SelectableTable;

  public ngOnChanges(): void {
    this.pulsateText = !this.isListItem && this.table?.status === TableOccupationStatus.MATCH_PREPARE;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { transformSecondsToMatchTimeString } from '../helper/time-format';

@Pipe({
  name: 'matchTimeFormat',
})
export class MatchTimeFormatPipe implements PipeTransform {
  public transform(time: number): string {
    return transformSecondsToMatchTimeString(time);
  }
}

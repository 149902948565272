import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RoomModule } from '@ranked/room';
import { SharedUiModule } from '@ranked/shared-ui';
import { MatchesPlayedWidgetComponent } from './components/matches-played-widget/matches-played-widget.component';
import { MetricsTestComponent } from './components/metrics-test/metrics-test.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { RankingListComponent } from './components/ranking-list/ranking-list.component';
import { RoomRankingsComponent } from './components/room-rankings/room-rankings.component';
import { AnimatedNumberDirective } from './directives/animated-number.directive';
import { StatisticsDataService } from './services/statistics-data.service';
import { StatisticsBasicModule } from './statistics-basic.module';

const statisticsRoutes: Routes = [
  {
    path: 'statistics',
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'test', component: MetricsTestComponent },
      { path: 'profile', component: ProfilePageComponent },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(statisticsRoutes), TranslateModule, SharedUiModule, StatisticsBasicModule, RoomModule],
  providers: [StatisticsDataService],
  declarations: [MetricsTestComponent, ProfilePageComponent, MatchesPlayedWidgetComponent, RoomRankingsComponent],
  exports: [RankingListComponent, AnimatedNumberDirective],
})
export class StatisticsModule {}

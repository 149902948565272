import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { NavbarIcon } from '@ranked/shared-ui';
import { AccountStoreService } from '../../services/account-store.service';

@Component({
  selector: 'ranked-pending-verification-page',
  templateUrl: './pending-verification-page.component.html',
  styleUrls: ['./pending-verification-page.component.scss'],
})
export class PendingVerificationPageComponent {
  public navbarIcon = NavbarIcon.PROFILE;

  public accountEmail$: Observable<string>;

  public verificationMailResendSuccessful = false;

  constructor(private accountStoreService: AccountStoreService, private router: Router) {
    this.accountEmail$ = this.accountStoreService.getRankedAccount().pipe(
      filter((account) => !!account),
      map((account) => account.email),
    );

    this.accountStoreService
      .isRankedAccountVerified()
      .pipe(
        filter((verified) => verified === true),
        first(),
      )
      .subscribe(() => {
        this.router.navigateByUrl('/');
      });
  }

  public readonly goToProfile = (): void => {
    this.router.navigateByUrl('/account/info');
  };

  public onCheckVerificationAgain(): void {
    this.accountStoreService.updateCurrentAccount();
  }

  public async onSendEmailAgain(): Promise<void> {
    const successful = await this.accountStoreService.resendVerificationMail();
    if (successful) {
      this.verificationMailResendSuccessful = true;
    }
  }
}

import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  user as getFirebaseUser,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@angular/fire/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { PresenceStatus, RoomDTO, RoomMembershipDTO, toRankedRoomMembership, toRankedRoomState } from '@ranked/model';
import { LeaveRoomOnLogout, RoomLeft, RoomLoaded, RoomStoreService, UpdateCurrentRoom } from '@ranked/room';
import { ENVIRONMENT_TOKEN, EnvironmentType } from '@ranked/settings';
import { UserFeedbackStoreService } from '@ranked/user-feedback';
import { FirebaseAuthError, FirebaseAuthErrorCode } from '../model/firebase-auth-error';
import { RankedAccountDTO, toRankedAccount } from '../model/ranked-account-dto';
import { AccountStoreService } from '../services/account-store.service';
import {
  AuthenticateWithGoogle,
  AuthenticateWithGoogleFailure,
  CreateRankedAccount,
  CreateRankedAccountFailure,
  FirebaseUserReceived,
  FirebaseUserRemoved,
  FirebaseUserWithoutAccountReceived,
  JoinRoom,
  JoinRoomFailure,
  LoadRankedAccountFailure,
  LoadRoom,
  LoadRoomFailed,
  LoadRoomMemberships,
  LoadRoomMembershipsFailed,
  LoadSavedRoomId,
  LoadSavedRoomIdFailed,
  LoginFailure,
  LoginSuccessful,
  LoginWithEmail,
  Logout,
  RankedAccountCreated,
  RankedAccountReceived,
  RankedAccountRequested,
  RegisterFailure,
  RegisterSuccessful,
  RegisterWithEmail,
  RequestNewPassword,
  RequestNewPasswordFailure,
  RequestNewPasswordSuccessful,
  ResendVerificationMail,
  ResendVerificationMailFailure,
  ResendVerificationMailSuccessful,
  RoomJoined,
  RoomMembershipsLoaded,
  SavedRoomIdLoaded,
  SavedRoomIdRemoved,
  SelectRoomFromSavedRoomId,
  SelectRoomFromSelectRoomPage,
  SelectRoomOnRoomJoined,
  UpdateCurrentAccount,
  UpdateCurrentAccountOnVerificationAlreadyFinished,
} from './account.actions';

export enum AccountSettings {
  ROOM_ID_KEY = 'roomId',
}

@Injectable()
export class AccountEffects {
  public static readonly LOGIN_ORIGIN = 'LOGIN_LOADING_SPINNER';
  public static readonly ACCOUNT_OPERATION_ORIGIN = 'ACCOUNT_OPERATION_LOADING_SPINNER';
  public static readonly LOAD_ROOM_ORIGIN = 'LOAD_ROOM_LOADING_SPINNER';

  private firebaseUser$ = getFirebaseUser(this.firebaseAuth);

  loadSavedRoomIdWhenAppIsReady$ = createEffect(() =>
    this.accountStoreService.isRankedAccountAvailable().pipe(
      filter((isAvailable) => isAvailable),
      map(() => LoadSavedRoomId()),
    ),
  );

  loadSelectedRoomId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSavedRoomId),
      map(() => localStorage.getItem(AccountSettings.ROOM_ID_KEY)),
      map((roomId) => (roomId ? SavedRoomIdLoaded({ roomId }) : LoadSavedRoomIdFailed())),
    ),
  );

  selectSavedRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedRoomIdLoaded),
      map(({ roomId }) => SelectRoomFromSavedRoomId({ roomId })),
    ),
  );

  loadRoomInfoOnRoomSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelectRoomFromSelectRoomPage, SelectRoomFromSavedRoomId, SelectRoomOnRoomJoined),
      map(({ roomId }) => {
        return LoadRoom({ roomId });
      }),
    ),
  );

  loadRoomMemberships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoomMemberships),
      withLatestFrom(this.accountStoreService.getRankedAccount()),
      mergeMap(([, account]) => {
        return this.httpClient.get<RoomMembershipDTO[]>(`${this.environment.baseUrl}/api/accounts/${account.id}/rooms`).pipe(
          map((memberships) => RoomMembershipsLoaded({ memberships: memberships.map(toRankedRoomMembership) })),
          catchError((error) => of(LoadRoomMembershipsFailed({ error }))),
        );
      }),
    ),
  );

  loadRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoom),
      fetch({
        id: ({ roomId }) => roomId, // we provide the id here, because otherwise the same request later will not be executed
        run: ({ roomId }) =>
          this.httpClient
            .get<RoomDTO>(`${this.environment.baseUrl}/api/rooms/${roomId}`)
            .pipe(map((room) => RoomLoaded({ room: toRankedRoomState(room) }))),

        onError: (_, error) => {
          return LoadRoomFailed({ error });
        },
      }),
    ),
  );

  updateCurrentRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCurrentRoom),
      withLatestFrom(this.roomStoreService.getCurrentRoom()),
      filter(([, currentRoom]) => !!currentRoom),
      map(([, currentRoom]) => {
        return LoadRoom({ roomId: currentRoom.id });
      }),
    ),
  );

  enableLoadingSpinnerBeforeLoadingRoom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SelectRoomFromSelectRoomPage, SelectRoomFromSavedRoomId),
        tap(() => {
          this.userFeedbackStoreService.enableLoadingSpinner({
            origin: AccountEffects.LOAD_ROOM_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  disableLoadingSpinnerAfterRoomLoadedOrOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoomLoaded, LoadRoomFailed),
        tap(() => {
          this.userFeedbackStoreService.disableLoadingSpinner({
            origin: AccountEffects.LOAD_ROOM_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  saveSelectedRoomIdInAppSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SelectRoomFromSelectRoomPage),
        tap(({ roomId }) => {
          localStorage.setItem(AccountSettings.ROOM_ID_KEY, roomId);
        }),
      ),
    { dispatch: false },
  );

  removeSelectedRoomIdFromAppSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomLeft),
      tap(() => {
        localStorage.removeItem(AccountSettings.ROOM_ID_KEY);
      }),
      map(() => SavedRoomIdRemoved()),
    ),
  );

  firebaseUserChanged$ = createEffect(() =>
    this.firebaseUser$.pipe(
      mergeMap((user) => {
        if (user) {
          return user.getIdTokenResult(true).then((idTokenResult) =>
            FirebaseUserReceived({
              displayName: user.displayName,
              photoUrl: user.photoURL,
              accountId: idTokenResult.claims['account_id'],
            }),
          );
        } else {
          return of(FirebaseUserRemoved());
        }
      }),
    ),
  );

  enableLoadingSpinnerBeforeLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginWithEmail, RegisterWithEmail, AuthenticateWithGoogle),
        tap(() => {
          this.userFeedbackStoreService.enableLoadingSpinner({
            origin: AccountEffects.LOGIN_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  disableLoadingSpinnerAfterLoginOrOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginSuccessful, LoginFailure, RegisterSuccessful, RegisterFailure, AuthenticateWithGoogleFailure),
        tap(() => {
          this.userFeedbackStoreService.disableLoadingSpinner({
            origin: AccountEffects.LOGIN_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  showErrorMessageOnLoginOrRegisterFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginFailure, RegisterFailure, AuthenticateWithGoogleFailure),
        map(({ error: { code } }) => {
          return this.mapFirebaseAuthErrorCodeToMessageKey(code);
        }),
        filter((messageKey) => messageKey !== null),
        tap((messageKey) => {
          this.userFeedbackStoreService.showErrorDialog(messageKey, true);
        }),
      ),
    { dispatch: false },
  );

  loginWithEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginWithEmail),
      mergeMap(({ email, password }) => {
        return from(signInWithEmailAndPassword(this.firebaseAuth, email, password)).pipe(
          map(() => LoginSuccessful()),
          catchError((error) => of(LoginFailure({ error }))),
        );
      }),
    ),
  );

  registerWithEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterWithEmail),
      mergeMap(({ email, password }) => {
        return from(createUserWithEmailAndPassword(this.firebaseAuth, email, password)).pipe(
          map(() => RegisterSuccessful()),
          catchError((error) => of(RegisterFailure({ error }))),
        );
      }),
    ),
  );

  authenticateWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticateWithGoogle),
      mergeMap(() => {
        return from(signInWithPopup(this.firebaseAuth, new GoogleAuthProvider())).pipe(
          map((userCredential) => {
            const additionalUserInfo = getAdditionalUserInfo(userCredential);
            if (additionalUserInfo.isNewUser) {
              return RegisterSuccessful();
            }

            return LoginSuccessful();
          }),
          catchError((error) => of(AuthenticateWithGoogleFailure({ error }))),
        );
      }),
    ),
  );

  requestNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestNewPassword),
      mergeMap(({ email }) => {
        return from(sendPasswordResetEmail(this.firebaseAuth, email)).pipe(
          map(() => RequestNewPasswordSuccessful()),
          catchError((error: FirebaseAuthError) => {
            if (error.code === 'auth/user-not-found') {
              return of(RequestNewPasswordSuccessful());
            }
            return of(RequestNewPasswordFailure({ error }));
          }),
        );
      }),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Logout),
        tap(() => {
          this.firebaseAuth.signOut();
        }),
      ),
    { dispatch: false },
  );

  leaveRoomOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Logout),
      map(() => LeaveRoomOnLogout()),
    ),
  );

  updateRankedAccountOnFirebaseUserChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirebaseUserReceived),
      map(({ accountId }) => {
        if (!accountId) {
          return FirebaseUserWithoutAccountReceived();
        }

        return RankedAccountRequested({ accountId });
      }),
    ),
  );

  enableLoadingSpinnerBeforeAccountOperation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateRankedAccount, UpdateCurrentAccount, RequestNewPassword, ResendVerificationMail),
        tap(() => {
          this.userFeedbackStoreService.enableLoadingSpinner({
            origin: AccountEffects.ACCOUNT_OPERATION_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  disableLoadingSpinnerAfterAccountOperationOrOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CreateRankedAccountFailure,
          RankedAccountReceived,
          LoadRankedAccountFailure,
          RequestNewPasswordSuccessful,
          RequestNewPasswordFailure,
          ResendVerificationMailSuccessful,
          ResendVerificationMailFailure,
        ),
        tap(() => {
          this.userFeedbackStoreService.disableLoadingSpinner({
            origin: AccountEffects.ACCOUNT_OPERATION_ORIGIN,
          });
        }),
      ),
    { dispatch: false },
  );

  createRankedAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateRankedAccount),
      withLatestFrom(this.firebaseUser$),
      mergeMap(([{ nickname, avatarUrl }, user]) => {
        return this.httpClient
          .post(
            `${this.environment.baseUrl}/api/accounts`,
            {
              emailAddress: user.email,
              nickname,
              avatarUrl,
            },
            { observe: 'response' },
          )
          .pipe(
            map((response) =>
              RankedAccountCreated({
                accountId: this.parseLocationHeaderFromResponse(response),
              }),
            ),
            catchError((error) => of(CreateRankedAccountFailure(error))),
          );
      }),
    ),
  );

  joinRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinRoom),
      withLatestFrom(this.accountStoreService.getRankedAccount()),
      mergeMap(([{ roomId }, account]) => {
        return this.httpClient
          .post<void>(
            `${this.environment.baseUrl}/api/accounts/${account.id}/rooms/${roomId}/join`,
            {
              playerName: account.nickname,
              playerAvatarUrl: account.avatarUrl,
            },
            { observe: 'response' },
          )
          .pipe(
            map(() => RoomJoined({ roomId })),
            catchError((error) => {
              return of(JoinRoomFailure({ error }));
            }),
          );
      }),
    ),
  );

  selectRoomOnRoomJoined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomJoined),
      map(({ roomId }) => SelectRoomOnRoomJoined({ roomId })),
    ),
  );

  updateRoomMembershipsOnRoomJoined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomJoined),
      map(() => LoadRoomMemberships()),
    ),
  );

  updateCurrentAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCurrentAccount, UpdateCurrentAccountOnVerificationAlreadyFinished),
      withLatestFrom(this.accountStoreService.getRankedAccountStatus(), this.accountStoreService.getRankedAccount()),
      filter(([, currentAccountStatus]) => currentAccountStatus === PresenceStatus.PRESENT),
      map(([, , currentAccount]) => {
        return RankedAccountRequested({ accountId: currentAccount.id });
      }),
    ),
  );

  resendVerificationMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResendVerificationMail),
      withLatestFrom(this.accountStoreService.getRankedAccount()),
      mergeMap(([, { email }]) => {
        return this.httpClient
          .post<void>(
            `${this.environment.baseUrl}/api/accounts/verification`,
            {
              emailAddress: email,
            },
            { observe: 'response' },
          )
          .pipe(
            map(() => ResendVerificationMailSuccessful()),
            catchError((error) => {
              return of(ResendVerificationMailFailure({ error }));
            }),
          );
      }),
    ),
  );

  showErrorMessageOnResendVerificationMailFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResendVerificationMailFailure),
        tap(({ error }) => {
          let failureReason = '';

          switch (this.getVerificationFailureReason(error)) {
            case 'NOT_READY':
              failureReason = 'link-still-valid';
              break;
            case 'FINISHED':
              failureReason = 'already-finished';
              break;
            case 'UNKNOWN':
              failureReason = 'unknown';
              break;
          }

          this.userFeedbackStoreService.showErrorDialog(`account.pending-verification.resend-failure.${failureReason}`, true);
        }),
      ),
    { dispatch: false },
  );

  updateCurrentAccountOnResendVerificationMailFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResendVerificationMailFailure),
      filter(({ error }) => this.getVerificationFailureReason(error) === 'FINISHED'),
      map(() => UpdateCurrentAccountOnVerificationAlreadyFinished()),
    ),
  );

  loadRankedAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RankedAccountRequested, RankedAccountCreated),
      fetch({
        id: ({ accountId }) => accountId, // we provide the id here, because otherwise the same request later will not be executed
        run: ({ accountId }) =>
          this.httpClient.get<RankedAccountDTO>(`${this.environment.baseUrl}/api/accounts/${accountId}`).pipe(
            map((rankedAccountDto) =>
              RankedAccountReceived({
                account: toRankedAccount(rankedAccountDto, accountId),
              }),
            ),
          ),
        onError: (_, error) => LoadRankedAccountFailure({ error }),
      }),
    ),
  );

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType,
    private actions$: Actions,
    private firebaseAuth: Auth,
    private httpClient: HttpClient,
    private userFeedbackStoreService: UserFeedbackStoreService,
    private accountStoreService: AccountStoreService,
    private roomStoreService: RoomStoreService,
  ) {}

  private mapFirebaseAuthErrorCodeToMessageKey(errorCode: FirebaseAuthErrorCode): string {
    switch (errorCode) {
      case 'auth/email-already-in-use':
      case 'auth/account-exists-with-different-credential':
        return 'account.login.error.email-already-in-use';

      case 'auth/invalid-email':
      case 'auth/wrong-password':
      case 'auth/user-not-found':
        return 'account.login.error.invalid-email-or-password';

      case 'auth/weak-password':
        return 'account.login.error.weak-password';

      case 'auth/user-disabled':
        return 'account.login.error.user-disabled';

      case 'auth/popup-blocked':
        return 'account.login.error.popup-blocked';

      case 'auth/popup-closed-by-user':
        return null;

      case 'auth/operation-not-allowed':
      case 'auth/cancelled-popup-request':
      case 'auth/auth-domain-config-required':
      case 'auth/unauthorized-domain':
      case 'auth/operation-not-supported-in-this-environment':
      default:
        return 'account.login.error.technical-error';
    }
  }

  private parseLocationHeaderFromResponse(response: HttpResponse<unknown>): string {
    const location = response.headers.get('Location');
    return location.slice(location.lastIndexOf('/') + 1);
  }

  private getVerificationFailureReason(error: unknown): 'NOT_READY' | 'FINISHED' | 'UNKNOWN' {
    const message = (error as HttpErrorResponse)?.error?.message ?? '';

    if (message.includes('VERIFICATION_STARTED')) {
      return 'NOT_READY';
    }

    if (message.includes('VERIFICATION_FINISHED')) {
      return 'FINISHED';
    }

    return 'UNKNOWN';
  }
}

import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Tuple, isDefined } from '@ranked/utils';
import { AllowedContextTypes, AllowedTimeFrames, Metric } from '../model';
import { StatisticsDataService } from '../services/statistics-data.service';

/**
 * [metricName, contextType, timeFrame]
 */
export type MetricInfo = [string, AllowedContextTypes, AllowedTimeFrames];

export abstract class Widget {
  protected abstract get statisticDataService(): StatisticsDataService;

  private loadAndGetMetric(metricInfo: MetricInfo): Observable<Metric> {
    return this.statisticDataService
      .getMetric(metricInfo[0], { contextType: metricInfo[1], timeFrame: metricInfo[2] })
      .pipe(filter(isDefined));
  }

  private isNotArray(info: MetricInfo | Readonly<Array<MetricInfo>>): info is MetricInfo {
    return !Array.isArray(info[0]);
  }

  protected loadMetrics(...metricInfo: MetricInfo): Observable<Metric>;
  protected loadMetrics<TTupel extends Array<MetricInfo>>(...metricInfo: TTupel): Observable<Tuple<Metric, TTupel['length']>>;
  protected loadMetrics(...metricInfo: MetricInfo | Tuple<MetricInfo, number>): Observable<Metric> | Observable<Tuple<Metric, number>> {
    if (metricInfo.length === 0) {
      throw new Error('No metric info specified!');
    }

    if (this.isNotArray(metricInfo)) {
      return this.loadAndGetMetric(metricInfo);
    }

    return combineLatest(metricInfo.map((info) => this.loadAndGetMetric(info)));
  }
}

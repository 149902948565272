import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { PresenceStatus } from '@ranked/model';
import { AccountStoreService } from '../../services/account-store.service';
import { AvatarSliderComponent } from '../avatar-slider/avatar-slider.component';

@Component({
  selector: 'ranked-create-account',
  templateUrl: './create-account-page.component.html',
  styleUrls: ['./create-account-page.component.scss'],
})
export class CreateAccountPageComponent {
  private readonly avatarCount = 46;

  public nickname: string;
  public socialAvatarUrl: string;

  public avatars: Array<string> = [];

  @ViewChild(AvatarSliderComponent)
  public avatarSlider: AvatarSliderComponent;

  constructor(private accountStoreService: AccountStoreService, router: Router) {
    this.accountStoreService
      .getUser()
      .pipe(first())
      .subscribe({
        next: ({ displayName, photoUrl }) => {
          this.nickname = displayName;
          this.socialAvatarUrl = photoUrl;

          this.avatars = this.createAvatars();
        },
      });

    this.accountStoreService
      .getRankedAccountStatus()
      .pipe(filter((rankedAccountStatus) => rankedAccountStatus === PresenceStatus.PRESENT))
      .subscribe({
        next: () => {
          router.navigateByUrl('/home');
        },
      });
  }

  private createAvatars(): Array<string> {
    const avatars = [];

    if (this.socialAvatarUrl) {
      avatars.push(this.socialAvatarUrl);
    }

    for (let index = 0; index < this.avatarCount; index++) {
      avatars.push(`https://get-ranked.app/assets/images/account_avatars/avatar_${index}.png`);
    }

    return avatars;
  }

  private getSelectedAvatarUrl(): string {
    if (this.avatarSlider === undefined) {
      return '';
    }

    const selectedAvatarIndex = this.avatarSlider.currentSelection;
    let avatarUrl: string;

    if (this.socialAvatarUrl) {
      if (selectedAvatarIndex === 0) {
        avatarUrl = this.socialAvatarUrl;
      } else {
        avatarUrl = this.avatars[selectedAvatarIndex - 1];
      }
    } else {
      avatarUrl = this.avatars[selectedAvatarIndex];
    }

    return avatarUrl;
  }

  public onSaveClick(): void {
    if (this.nickname?.length > 0) {
      this.accountStoreService.createAccount({
        nickname: this.nickname,
        avatarUrl: this.getSelectedAvatarUrl(),
      });
    }
  }
}

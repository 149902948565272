<ranked-safe-area-top color="primary"></ranked-safe-area-top>

<ranked-navbar title="{{ 'account.account-info.title' | translate }}"></ranked-navbar>

<ng-container *ngIf="account$ | async as account">
  <ranked-hexagon class="avatar" backgroundColor="custom" borderColor="custom" [showShadow]="false" (click)="onAvatarImageClicked()">
    <img [src]="account.avatarUrl" />
  </ranked-hexagon>
  <div class="nickname">{{ account.nickname }}</div>
  <div class="spacer"></div>
  <button ranked-button variant="simple" (click)="logout()" text="{{ 'account.account-info.logout' | translate }}"></button>
</ng-container>

<button *ngIf="showDebugButton" variant="simple" ranked-button text="Debug" routerLink="/debug"></button>

<ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>

import { MatchStatus } from './match-status';

export enum MatchStateDTO {
  CREATED = 'created',
  PREPARED = 'prepared',
  STARTED = 'started',
  PAUSED = 'paused',
  FINISHED = 'finished',
  ENDED = 'ended',
  ABORTED = 'aborted',
}

export function toMatchStatus(state: MatchStateDTO): MatchStatus {
  switch (state) {
    case MatchStateDTO.CREATED:
    case MatchStateDTO.PREPARED:
      return MatchStatus.PREPARE;

    case MatchStateDTO.STARTED:
      return MatchStatus.RUNNING;

    case MatchStateDTO.PAUSED:
      return MatchStatus.PAUSED;

    case MatchStateDTO.FINISHED:
    case MatchStateDTO.ENDED:
    case MatchStateDTO.ABORTED:
    default:
      return MatchStatus.ENDED;
  }
}

<ranked-safe-area-top color="primary"></ranked-safe-area-top>

<ranked-navbar class="navbar" title="{{ 'room.select-table.headline' | translate }}"> </ranked-navbar>

<div class="table-list" *ngIf="{ useList: useList$ | async } as flags">
  <ng-container *ngIf="flags.useList; else bigButtons">
    <button
      rankedSelectableTable
      *ngFor="let table of freeTables$ | async"
      class="selectable-table"
      [table]="table"
      (click)="onTableClicked(table)"
    ></button>

    <ng-container *ngIf="tablesWithPreparingMatches$ | async as tablesWithPreparingMatches">
      <ranked-list-divider
        *ngIf="tablesWithPreparingMatches.length > 0"
        text="{{ 'room.select-table.list-divider.open-matches' | translate }}"
      ></ranked-list-divider>
      <button
        rankedSelectableTable
        *ngFor="let table of tablesWithPreparingMatches"
        class="selectable-table"
        [table]="table"
        (click)="onTableClicked(table)"
      ></button>
    </ng-container>

    <ng-container *ngIf="blockedTables$ | async as blockedTables">
      <ranked-list-divider
        *ngIf="blockedTables.length > 0"
        text="{{ 'room.select-table.list-divider.occupied-tables' | translate }}"
      ></ranked-list-divider>

      <button [disabled]="true" rankedSelectableTable *ngFor="let table of blockedTables" class="selectable-table" [table]="table"></button>
    </ng-container>
  </ng-container>

  <ng-template #bigButtons>
    <button
      rankedSelectableTable
      *ngFor="let table of allTables$ | async"
      class="selectable-table"
      [table]="table"
      (click)="onTableClicked(table)"
      [isListItem]="false"
    ></button>
  </ng-template>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { getApp } from 'firebase/app';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { SharedUiModule } from '@ranked/shared-ui';
import { AccountInfoPageComponent } from './components/account-info-page/account-info-page.component';
import { AvatarSliderComponent } from './components/avatar-slider/avatar-slider.component';
import { CreateAccountPageComponent } from './components/create-account/create-account-page.component';
import { ForgotPasswordComponent } from './components/forgot-password-page/forgot-password-page.component';
import { LoginPageComponent } from './components/login/login-page.component';
import { PendingVerificationPageComponent } from './components/pending-verification-page/pending-verification-page.component';
import { AccountStoreService } from './services/account-store.service';
import { AccountEffects } from './state/account.effects';
import * as fromAccount from './state/account.reducer';

const accountRoutes: Routes = [
  {
    path: 'account',
    children: [
      { path: 'login', component: LoginPageComponent },
      { path: 'create', component: CreateAccountPageComponent },
      { path: 'info', component: AccountInfoPageComponent },
      { path: 'pending', component: PendingVerificationPageComponent },
      { path: 'forgotPassword', component: ForgotPasswordComponent },
    ],
  },
];

@NgModule({
  providers: [AccountStoreService],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(accountRoutes),
    TranslateModule,
    StoreModule.forFeature(fromAccount.ACCOUNT_FEATURE_KEY, fromAccount.accountReducer),
    EffectsModule.forFeature([AccountEffects]),
    SharedUiModule,
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
  ],
  declarations: [
    LoginPageComponent,
    CreateAccountPageComponent,
    AccountInfoPageComponent,
    PendingVerificationPageComponent,
    AvatarSliderComponent,
    ForgotPasswordComponent,
  ],
})
export class AccountModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Match } from '@ranked/model';
import { MATCH_FEATURE_KEY, MatchState } from './match.reducer';

export const getMatchState = createFeatureSelector<MatchState>(MATCH_FEATURE_KEY);

export const getRoomIdAndMatchId = createSelector(getMatchState, (state: MatchState) => ({
  roomId: state.roomId,
  matchId: state.match?.id,
}));

export const getMatch = createSelector(getMatchState, (state: MatchState) => state.match);

export const getScoreHistory = createSelector(getMatchState, (state: MatchState) => state.scoreHistory);

export const getMatchStatus = createSelector(getMatch, (match: Match) => match?.status);

export const getMatchCurrentTime = createSelector(getMatch, (match: Match) => match?.currentTime);

export const getRuleSets = createSelector(getMatchState, (state: MatchState) => state.ruleSets);

export const getParticipants = createSelector(getMatchState, (state: MatchState) => state.participants);

export const getTable = createSelector(getMatchState, (state: MatchState) => state.table);

export const getDevicePosition = createSelector(getMatchState, (state: MatchState) => state.devicePosition);

<ranked-safe-area-top color="primary"></ranked-safe-area-top>
<ranked-navbar title="{{ 'account.pending-verification.title' | translate }}" [icon]="navbarIcon" [iconTap]="goToProfile"></ranked-navbar>

<div class="content">
  <div class="header">{{ 'account.pending-verification.header' | translate }}</div>
  <ol class="steps">
    <li data-step="1" class="step">{{ 'account.pending-verification.step-1' | translate : { email: (accountEmail$ | async) } }}</li>
    <li data-step="2" class="step">{{ 'account.pending-verification.step-2' | translate }}</li>
    <li data-step="3" class="step">{{ 'account.pending-verification.step-3' | translate }}</li>
  </ol>
  <button
    class="check-button"
    ranked-button
    (click)="onCheckVerificationAgain()"
    text="{{ 'account.pending-verification.check-again' | translate }}"
    size="big"
    [fullWidth]="true"
  ></button>
  <div class="spacer"></div>
  <button
    ranked-button
    [color]="verificationMailResendSuccessful ? 'secondary' : 'primary'"
    variant="simple"
    [disabled]="verificationMailResendSuccessful"
    (click)="onSendEmailAgain()"
    text="{{
      (verificationMailResendSuccessful ? 'account.pending-verification.sent-again' : 'account.pending-verification.send-again') | translate
    }}"
  ></button>
</div>

<ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>

import { Metric, MetricData } from './metric';

export interface MetricDataDTO {
  time: string;
  value: number;
}
export function toMetric(metricData: MetricDataDTO[]): Metric {
  return metricData.map((data) => {
    const metric: MetricData = {
      time: new Date(data.time),
      value: data.value,
    };

    return metric;
  });
}

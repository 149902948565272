import { Inject, Injectable, NgZone } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoggerService } from '@ranked/logging';
import { MatchDTO } from '@ranked/model';
import { EnvironmentType, ENVIRONMENT_TOKEN } from '@ranked/settings';
import { UserFeedbackStoreService } from '@ranked/user-feedback';
import { SseStream } from '../helper/sse-stream';
import { SSE_AUTO_RECONNECT_RETRIES, SSE_AUTO_RECONNECT_DELAY_MS } from './constants';

@Injectable()
export class MatchSseService {
  private eventSubject: Subject<MessageEvent>;
  private sseStream: SseStream | null;
  private sseStreamSubscription: Subscription | undefined;

  private lastRoomId: string;
  private lastMatchId: string;
  private unsuccessfulRetries = 0;

  public get matchUpdateEvent$(): Observable<MatchDTO> {
    return this.eventSubject.asObservable().pipe(map((event) => JSON.parse(event.data)));
  }

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType,
    private angularFireAuth: Auth,
    private zone: NgZone,
    private userFeedbackStoreService: UserFeedbackStoreService,
    private router: Router,
    private loggerService: LoggerService,
  ) {
    this.eventSubject = new Subject();
    this.sseStream = null;
  }

  private getBearerToken(): Promise<string> {
    return idToken(this.angularFireAuth).pipe(take(1)).toPromise();
  }

  private async subscribeInternal() {
    // we want to subscribe to another match, so we need to close the previous socket first
    this.unsubscribeFromMatch();

    try {
      this.sseStream = await this.createSseStream(`${this.environment.baseUrl}/api/rooms/${this.lastRoomId}/matches/${this.lastMatchId}`);
      this.sseStreamSubscription = this.sseStream.message$().subscribe(
        (value) => this.eventSubject.next(value),
        (error) => this.handleSseStreamError(error),
      );
    } catch (e) {
      await this.handleSseStreamError(e);
    }
  }

  private async handleSseStreamError(error: unknown) {
    if (this.unsuccessfulRetries < SSE_AUTO_RECONNECT_RETRIES) {
      this.unsuccessfulRetries++;
      await new Promise((resolve) => setTimeout(resolve, SSE_AUTO_RECONNECT_DELAY_MS));
      await this.subscribeInternal();
    } else {
      this.loggerService.log('ERROR', 'Match SSE stream failed after unsuccessful reconnection attempts.', error);
      this.unsubscribeFromMatch();

      await this.userFeedbackStoreService.showErrorDialog('match.message-connection-error', true);
      this.router.navigateByUrl('/');
    }
  }

  private async createSseStream(url: string): Promise<SseStream> {
    return new SseStream(this.zone, url, {
      Authorization: `Bearer ${await this.getBearerToken()}`,
    });
  }

  public async subscribeToMatch(roomId: string, matchId: string): Promise<void> {
    this.lastRoomId = roomId;
    this.lastMatchId = matchId;
    this.unsuccessfulRetries = 0;

    await this.subscribeInternal();
  }

  public unsubscribeFromMatch(): void {
    this.sseStreamSubscription?.unsubscribe();
    this.sseStreamSubscription = undefined;

    this.sseStream?.close();
    this.sseStream = null;
  }
}

import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider';
import { map } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { RoomStoreService } from '@ranked/room';
import { property } from '@ranked/utils';

function ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active');
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active');
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          main.moveToIdx(idx);
        });
      });
    }

    slider.on('created', () => {
      addActive(slider.track.details.rel);
      addClickEvents();
      main.on('animationStarted', (sliderInstance) => {
        removeActive();
        const next = sliderInstance.animator.targetIdx || 0;
        addActive(sliderInstance.track.absToRel(next));
        slider.moveToIdx(Math.min(sliderInstance.track.details.maxIdx, next));
      });
    });
  };
}

@Component({
  selector: 'ranked-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements AfterViewInit, OnDestroy {
  public account$ = this.accountStoreService.getRankedAccount();
  public roomName$ = this.roomStoreService.getCurrentRoom().pipe(map(property('name')));

  public slider: KeenSliderInstance = null;
  public thumbnailSlider: KeenSliderInstance = null;

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  @ViewChild('thumbnailRef') thumbnailRef: ElementRef<HTMLElement>;

  constructor(private accountStoreService: AccountStoreService, private roomStoreService: RoomStoreService) {}

  public ngAfterViewInit(): void {
    this.slider = new KeenSlider(this.sliderRef.nativeElement);
    this.thumbnailSlider = new KeenSlider(
      this.thumbnailRef.nativeElement,
      {
        initial: 0,
        drag: false,
        slides: {
          origin: 'auto',
          perView: 'auto',
        },
      },
      [ThumbnailPlugin(this.slider)],
    );
  }

  public ngOnDestroy(): void {
    if (this.slider) this.slider.destroy();
    if (this.thumbnailSlider) this.thumbnailSlider.destroy();
  }
}

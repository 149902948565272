<div class="participants show-{{ participantsToShow.toLowerCase() }}">
  <button
    *ngFor="let icon of icons; let first = first; let last = last"
    class="icon {{ icon.type }} {{ iconSize }}"
    [ngClass]="{
      left: first,
      right: last,
      hide: (icon.type === 'switch' && !switchAllowed) || (icon.type === 'add' && !addAllowed)
    }"
    (click)="onIconClicked(icon)"
  >
    <ranked-named-icon [data]="icon" namePosition="bottom"></ranked-named-icon>
  </button>
</div>

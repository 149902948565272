import { Component, Input } from '@angular/core';

@Component({
  selector: '[ranked-home-shutter-button], ranked-home-shutter-button',
  templateUrl: './home-shutter-button.component.html',
  styleUrls: ['./home-shutter-button.component.scss'],
})
export class HomeShutterButtonComponent {
  @Input() label;
  @Input() icon;
}

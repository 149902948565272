<ranked-safe-area-top color="primary"></ranked-safe-area-top>

<ranked-navbar [title]="'app.join-room.title' | translate"></ranked-navbar>

<div class="header" translate="app.join-room.header"></div>
<div class="description">
  <span translate="app.join-room.body-prefix"></span>
  <a [href]="emailLink">info@get-ranked.app</a>
  <span translate="app.join-room.body-suffix"></span>
</div>

<div class="room-code-group">
  <div class="room-code-part">
    <div class="numbered">1</div>
    <ranked-input-enhancer class="room-code-text" [centered]="true">
      <input
        rankedInput
        type="text"
        placeholder="aaaaaaaa"
        pattern="[0-9a-fA-F]{8}"
        [(ngModel)]="roomCodeParts[0]"
        (ngModelChange)="qualityOfLifeInsert()"
      />
    </ranked-input-enhancer>
  </div>

  <div class="room-code-part">
    <div class="numbered">2</div>
    <ranked-input-enhancer class="room-code-text" [centered]="true">
      <input rankedInput type="text" pattern="[0-9a-fA-F]{4}" placeholder="bbbb" [(ngModel)]="roomCodeParts[1]" />
    </ranked-input-enhancer>
  </div>

  <div class="room-code-part">
    <div class="numbered">3</div>
    <ranked-input-enhancer class="room-code-text" [centered]="true">
      <input rankedInput type="text" pattern="[0-9a-fA-F]{4}" placeholder="cccc" [(ngModel)]="roomCodeParts[2]" />
    </ranked-input-enhancer>
  </div>

  <div class="room-code-part">
    <div class="numbered">4</div>
    <ranked-input-enhancer class="room-code-text" [centered]="true">
      <input rankedInput type="text" pattern="[0-9a-fA-F]{4}" placeholder="dddd" [(ngModel)]="roomCodeParts[3]" />
    </ranked-input-enhancer>
  </div>

  <div class="room-code-part">
    <div class="numbered">5</div>
    <ranked-input-enhancer class="room-code-text" [centered]="true">
      <input rankedInput type="text" pattern="[0-9a-fA-F]{12}" placeholder="eeeeeeeeeeee" [(ngModel)]="roomCodeParts[4]" />
    </ranked-input-enhancer>
  </div>
</div>

<div class="buttons">
  <button
    class="join-room-button"
    ranked-button
    [fullWidth]="true"
    size="big"
    text="{{ 'app.join-room.button' | translate }}"
    (click)="onJoinSubmit()"
  ></button>
</div>

<ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>

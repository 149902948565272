import { Table } from '@ranked/model';
import { TableOccupationStatus } from './table-occupation-status';

export interface SelectableTable {
  name: string;
  color1: string;
  color2: string;
  status: TableOccupationStatus;
  matchId?: string;
}

export function createSelectableTable(table: Table, status: TableOccupationStatus, matchId?: string): SelectableTable {
  return {
    name: table.name,
    color1: table.color1,
    color2: table.color2,
    status,
    matchId,
  };
}

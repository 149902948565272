<ol #rankingList class="ranking">
  <li
    [id]="rankingData.id"
    class="ranking-entry"
    [class.highlighted]="rankingData.id === highlightedParticipantId"
    *ngFor="let rankingData of data"
  >
    <ranked-hexagon class="avatar" backgroundColor="custom" borderColor="custom" [showShadow]="false">
      <img [src]="rankingData.image" />
    </ranked-hexagon>
    <span class="name">{{ rankingData.name }}</span>

    <div class="bar" [ngStyle]="{ 'width.%': rankingData.barLength }"></div>
    <label class="value">{{ rankingData.value | number : numberFormat : 'de' }}{{ unit }}</label>
  </li>
</ol>

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { MatchDTO, MatchScoreHistoryDTO, MatchStatus, toDevicePostion, toRankedMatchScoreHistory, toRankedMatchState } from '@ranked/model';
import { EnvironmentType, ENVIRONMENT_TOKEN } from '@ranked/settings';
import { UserFeedbackStoreService } from '@ranked/user-feedback';
import { TimerValue } from '../helper/timer-value';
import { MatchSseService } from '../services/match-sse.service';
import { MatchStoreService } from '../services/match-store.service';
import {
  AbortMatchFromMatchPage,
  AddParticipantFromMatchStatusSide,
  CompleteMatchFromMatchPage,
  LoadMatch,
  LoadMatchFailed,
  LoadMatchScoreHistory,
  MatchActionFailed,
  MatchActionSuccessful,
  MatchEnded,
  MatchJoined,
  MatchLoaded,
  MatchScoreHistoryLoaded,
  MatchUpdateReceived,
  PauseMatchFromMatchPage,
  ResumeMatchFromMatchPage,
  ScoreGoalFromMatchStatusSide,
  StartMatchFromMatchPage,
  SwitchedPlayersFromMatchStatusSide,
  TimerTicked,
  UndoGoalFromMatchPage,
} from './match.actions';

@Injectable()
export class MatchEffects {
  private timer = new TimerValue();

  matchJoined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MatchJoined),
      map(({ matchId, roomId }) => LoadMatch({ matchId, roomId })),
    ),
  );

  loadMatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMatch),
      mergeMap(({ roomId, matchId }) =>
        this.httpClient.get<MatchDTO>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}`).pipe(
          map((match) => MatchLoaded({ match: toRankedMatchState(match), devicePosition: toDevicePostion(match) })),
          catchError((error) => of(LoadMatchFailed({ error }))),
        ),
      ),
    ),
  );

  openMatchUpdateStream$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MatchLoaded),
        withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
        tap(([, { matchId, roomId }]) => {
          // when the match is successfully loaded, we start the SSE stream
          this.matchSseService.subscribeToMatch(roomId, matchId);
        }),
      );
    },
    { dispatch: false },
  );

  listenToMatchUpdateStream$ = createEffect(() =>
    this.matchSseService.matchUpdateEvent$.pipe(
      map((match) =>
        MatchUpdateReceived({
          match: toRankedMatchState(match),
          devicePosition: toDevicePostion(match),
        }),
      ),
    ),
  );

  switchPlayers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SwitchedPlayersFromMatchStatusSide),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([{ teamPosition }, { matchId, roomId }]) =>
        this.httpClient
          .post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/switchPlayers`, {
            position: teamPosition,
          })
          .pipe(
            map(() => MatchActionSuccessful({ matchAction: 'Switch Players' })),
            catchError((error) => of(MatchActionFailed({ matchAction: 'Switch Players', error }))),
          ),
      ),
    );
  });

  addParticipant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddParticipantFromMatchStatusSide),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([{ participant, participantPosition, teamPosition }, { matchId, roomId }]) =>
        this.httpClient
          .post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/players`, {
            playerName: participant.id,
            playerPosition: participantPosition,
            position: teamPosition,
          })
          .pipe(
            map(() => MatchActionSuccessful({ matchAction: 'Add Participant' })),
            catchError((error) => of(MatchActionFailed({ matchAction: 'Add Participant', error }))),
          ),
      ),
    );
  });

  startMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StartMatchFromMatchPage),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) =>
        this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/start`, {}).pipe(
          map(() => MatchActionSuccessful({ matchAction: 'Start Match' })),
          catchError((error) => of(MatchActionFailed({ matchAction: 'Start Match', error }))),
        ),
      ),
    );
  });

  pauseMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PauseMatchFromMatchPage),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) =>
        this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/pause`, {}).pipe(
          map(() => MatchActionSuccessful({ matchAction: 'Pause Match' })),
          catchError((error) => of(MatchActionFailed({ matchAction: 'Pause Match', error }))),
        ),
      ),
    );
  });

  resumeMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResumeMatchFromMatchPage),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) =>
        this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/resume`, {}).pipe(
          map(() => MatchActionSuccessful({ matchAction: 'Resume Match' })),
          catchError((error) => of(MatchActionFailed({ matchAction: 'Resume Match', error }))),
        ),
      ),
    );
  });

  scoreGoal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScoreGoalFromMatchStatusSide),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([{ participant, manikinPosition }, { matchId, roomId }]) =>
        this.httpClient
          .post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/score`, {
            playerName: participant.id,
            manikinPosition,
          })
          .pipe(
            map(() => MatchActionSuccessful({ matchAction: 'Score Goal' })),
            catchError((error) => of(MatchActionFailed({ matchAction: 'Score Goal', error }))),
          ),
      ),
    );
  });

  undoLastGoal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UndoGoalFromMatchPage),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) =>
        this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/score/undo`, {}).pipe(
          map(() => MatchActionSuccessful({ matchAction: 'Undo Last Goal' })),
          catchError((error) => of(MatchActionFailed({ matchAction: 'Undo Last Goal', error }))),
        ),
      ),
    );
  });

  completeMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompleteMatchFromMatchPage),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) => {
        return this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/score/accept`, {}).pipe(
          map(() => MatchEnded()),
          catchError(() => of(MatchEnded())),
        );
      }),
    );
  });

  abortMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbortMatchFromMatchPage),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) => {
        return this.httpClient.post<void>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/abort`, {}).pipe(
          map(() => MatchEnded()),
          catchError(() => of(MatchEnded())),
        );
      }),
    );
  });

  closeMatchUpdateStream$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MatchEnded),
        tap(() => this.matchSseService.unsubscribeFromMatch()),
      );
    },
    { dispatch: false },
  );

  leaveMatch$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MatchEnded),
        tap(() => this.router.navigateByUrl('/home')),
      );
    },
    { dispatch: false },
  );

  loadCurrentMatchScoreHistoryWhenMatchCompletes$ = createEffect(() => {
    return this.matchStoreService.getMatchStatus().pipe(
      filter((status) => status === MatchStatus.ENDED),
      map(() => LoadMatchScoreHistory()),
    );
  });

  loadCurrentMatchScoreHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadMatchScoreHistory),
      withLatestFrom(this.matchStoreService.getRoomIdAndMatchId()),
      mergeMap(([, { matchId, roomId }]) => {
        return this.httpClient
          .get<MatchScoreHistoryDTO>(`${this.environment.baseUrl}/api/rooms/${roomId}/matches/${matchId}/score/history`)
          .pipe(
            map((historyDTO) =>
              MatchScoreHistoryLoaded({
                history: toRankedMatchScoreHistory(historyDTO),
              }),
            ),
            catchError(() => of(MatchScoreHistoryLoaded({ history: undefined }))), // TODO: error handling?
          );
      }),
    );
  });

  startStopMatchTimer$ = createEffect(
    () => {
      return this.matchStoreService.getMatchStatus().pipe(
        withLatestFrom(this.matchStoreService.getMatchCurrentTime()),
        tap(([status, currentTime]) => {
          switch (status) {
            case MatchStatus.RUNNING:
              this.timer.reset();
              this.timer.start(currentTime);
              break;
            case MatchStatus.PAUSED:
              this.timer.pause();
              break;
            case MatchStatus.ENDED:
              this.timer.reset();
              break;
          }
        }),
      );
    },
    { dispatch: false },
  );

  matchTimerTick$ = createEffect(() => {
    return this.timer.values$.pipe(map((time) => TimerTicked({ time })));
  });

  showPopupOnMatchActionFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MatchActionFailed),
        tap(() => {
          this.userFeedbackStoreService.showErrorDialog('match.action-failed-error');
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType,
    private actions$: Actions,
    private httpClient: HttpClient,
    private matchStoreService: MatchStoreService,
    private matchSseService: MatchSseService,
    private router: Router,
    private userFeedbackStoreService: UserFeedbackStoreService,
  ) {}
}

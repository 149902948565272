import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SettingsStoreService } from '@ranked/settings';

@Injectable()
export class DeviceIdInterceptor implements HttpInterceptor {
  public constructor(private settingsStoreService: SettingsStoreService) {}

  public intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.settingsStoreService.getDeviceId().pipe(
      mergeMap((deviceId) => {
        if (!deviceId) {
          return next.handle(req);
        }

        const authReq = req.clone({
          headers: req.headers.set('Device-Id', deviceId),
        });

        // send cloned request with header to the next handler.
        return next.handle(authReq);
      }),
    );
  }
}

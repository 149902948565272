<ranked-safe-area-top color="primary" />
<ranked-navbar title="{{ 'account.forgot-password.title' | translate }}" />

<div class="content" [ngSwitch]="requestSuccessful">
  <form *ngSwitchDefault (ngSubmit)="onResetPassword()">
    <span>{{ 'account.forgot-password.header' | translate }}</span>
    <ranked-input-enhancer class="mail-input">
      <input
        #forgotPasswordEmail
        rankedInput
        [(ngModel)]="email"
        type="email"
        name="email"
        autocomplete="email"
        placeholder="{{ 'account.login.placeholder.email' | translate }}"
        required
      />
    </ranked-input-enhancer>
    <button
      [fullWidth]="true"
      class="login-button"
      ranked-button
      size="big"
      text="{{ 'account.forgot-password.reset-password' | translate }}"
      type="submit"
      [disabled]="!forgotPasswordEmail.validity.valid"
    ></button>
  </form>

  <span *ngSwitchCase="true">{{ 'account.forgot-password.success' | translate }}</span>

  <span *ngSwitchCase="false">{{ 'account.forgot-password.failure' | translate }}</span>
</div>

<ranked-safe-area-bottom color="light" />

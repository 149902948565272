import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { AccountStoreService } from '../../services/account-store.service';

@Component({
  selector: 'ranked-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  private readonly animationDurationMs = 400;

  public email: string;
  public password: string;

  public mode: 'main' | 'login' | 'register' = 'main';

  public disableLogin = true;
  public disableRegister = true;

  constructor(private router: Router, private accountStoreService: AccountStoreService) {
    this.accountStoreService
      .isUserLoggedIn()
      .pipe(
        filter((isLoggedIn) => isLoggedIn),
        first(),
      )
      .subscribe({
        next: () => {
          this.router.navigateByUrl('/home');
        },
      });
  }

  private clearFocus(): void {
    (document.activeElement as HTMLElement).blur();
  }

  public onLoginSubmit(): void {
    this.clearFocus();
    this.accountStoreService.loginWithEmail({
      email: this.email,
      password: this.password,
    });
  }

  public onRegisterSubmit(): void {
    this.clearFocus();
    this.accountStoreService.registerWithEmail({
      email: this.email,
      password: this.password,
    });
  }

  public onGoogleLoginClicked(): void {
    this.accountStoreService.authenticateWithGoogle();
  }

  public onForgotPasswordClicked(): void {
    this.router.navigateByUrl('/account/forgotPassword');
  }

  public goToMain(): void {
    this.mode = 'main';

    setTimeout(() => {
      this.disableLogin = true;
      this.disableRegister = true;
    }, this.animationDurationMs);
  }

  public goToRegister(): void {
    this.mode = 'register';

    this.disableRegister = false;
  }

  public goToLogin(): void {
    this.mode = 'login';

    this.disableLogin = false;
  }
}

import { Injectable } from '@angular/core';
import { dispatch, select, StoreService } from '@ngxp/store-service';
import {
  AbortMatchFromMatchPage,
  AddParticipantFromMatchStatusSide,
  CompleteMatchFromMatchPage,
  PauseMatchFromMatchPage,
  ResumeMatchFromMatchPage,
  ScoreGoalFromMatchStatusSide,
  StartMatchFromMatchPage,
  SwitchedPlayersFromMatchStatusSide,
  UndoGoalFromMatchPage,
} from '../state/match.actions';
import { MatchState } from '../state/match.reducer';
import {
  getDevicePosition,
  getMatch,
  getMatchCurrentTime,
  getMatchStatus,
  getParticipants,
  getRoomIdAndMatchId,
  getRuleSets,
  getScoreHistory,
  getTable,
} from '../state/match.selectors';

@Injectable()
export class MatchStoreService extends StoreService<MatchState> {
  public getRoomIdAndMatchId = select(getRoomIdAndMatchId);

  public getMatch = select(getMatch);

  public getMatchStatus = select(getMatchStatus);

  public getMatchCurrentTime = select(getMatchCurrentTime);

  public getScoreHistory = select(getScoreHistory);

  public getParticipants = select(getParticipants);

  public getRuleSets = select(getRuleSets);

  public getTable = select(getTable);

  public getDevicePosition = select(getDevicePosition);

  public switchedPlayersFromMatchStatusSide = dispatch(SwitchedPlayersFromMatchStatusSide);

  public addParticipantFromMatchStatusSide = dispatch(AddParticipantFromMatchStatusSide);

  public startMatchFromMatchPage = dispatch(StartMatchFromMatchPage);

  public scoreGoalFromMatchStatusSide = dispatch(ScoreGoalFromMatchStatusSide);

  public undoGoalFromMatchPage = dispatch(UndoGoalFromMatchPage);

  public completeMatchFromMatchPage = dispatch(CompleteMatchFromMatchPage);

  public abortMatchFromMatchPage = dispatch(AbortMatchFromMatchPage);

  public pauseMatchFromMatchPage = dispatch(PauseMatchFromMatchPage);

  public resumeMatchFromMatchPage = dispatch(ResumeMatchFromMatchPage);
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatchTeamPosition, Participant, Player } from '@ranked/model';
import { ListElement } from '@ranked/shared-ui';
import { ParticipantTeamColorMapping } from '../../model/participant-team-color-mapping';

@Component({
  selector: 'ranked-particpant-selection',
  templateUrl: './particpant-selection.component.html',
  styleUrls: ['./particpant-selection.component.scss'],
})
export class ParticpantSelectionComponent implements OnChanges {
  public elements: Array<ListElement>;

  @Input() public themeColor: string;
  @Input() public position: MatchTeamPosition;
  @Input() public participants: Array<Participant>;
  @Input()
  public selectedParticipantsTeamColorMapping: ParticipantTeamColorMapping = {};

  @Output() public selected = new EventEmitter<Player | null>();

  public goBack = (): void => {
    this.selected.emit(null);
  };

  public group = (icon: ListElement): string => icon.name[0].toUpperCase();

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (this.participants && (simpleChanges['participants'] || simpleChanges['selectedParticipantsTeamColorMapping'])) {
      this.elements = this.participants.map((participant) => {
        let additionalClasses: Array<string>;
        if (this.selectedParticipantsTeamColorMapping[participant.id]) {
          additionalClasses = ['selected', 'theme-' + this.selectedParticipantsTeamColorMapping[participant.id]];
        }

        return {
          name: participant.name,
          iconName: 'person',
          iconPath: participant.image,
          additionalClasses,
        };
      });
    }
  }

  public onParticipantSelected(icon: ListElement): void {
    this.selected.emit({
      id: icon.name,
      name: icon.name,
      image: icon.iconPath,
    } as Player);
  }
}

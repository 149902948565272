import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { Room } from '@ranked/model';
import { RoomStoreService } from '@ranked/room';
import { UserFeedbackStoreService } from '@ranked/user-feedback';

@Component({
  selector: 'ranked-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnDestroy {
  private reenterMatchSubscription: Subscription;

  public room: Observable<Room>;

  public constructor(
    private roomStoreService: RoomStoreService,
    private accountStoreService: AccountStoreService,
    private userFeedbackStoreService: UserFeedbackStoreService,
    private router: Router,
  ) {
    this.room = roomStoreService.getCurrentRoom();
    this.reenterMatchSubscription = roomStoreService
      .getCurrentMatchId()
      .pipe(
        filter((matchId) => matchId !== undefined),
        take(1),
      )
      .subscribe((matchId) => this.showDialogOnFoundCurrentMatchId(matchId));
  }

  private async showDialogOnFoundCurrentMatchId(matchId: string): Promise<void> {
    const shouldReenter = await this.userFeedbackStoreService.showBooleanQuestionDialog('home.reenter-match.text', true);

    if (shouldReenter) {
      this.roomStoreService.reenterMatch({ matchId });
      this.router.navigateByUrl('/room/match');
    }
  }

  public ngOnDestroy(): void {
    if (!this.reenterMatchSubscription.closed) {
      this.reenterMatchSubscription.unsubscribe();
    }
  }

  public goToAccountPage(): void {
    this.router.navigateByUrl('/account/info');
  }

  public changeRoom(): void {
    this.accountStoreService
      .roomLeft$()
      .pipe(first())
      .subscribe(() => this.router.navigateByUrl('/select-room'));
    this.roomStoreService.changeRoom();
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomState, ROOM_FEATURE_KEY } from './room.reducer';

export const getRoomState = createFeatureSelector<RoomState>(ROOM_FEATURE_KEY);

export const getCurrentRoom = createSelector(getRoomState, (state: RoomState) => state.currentRoom);

export const getCurrentRoomId = createSelector(getRoomState, (state: RoomState) => state.currentRoom?.id);

export const getCurrentMatchId = createSelector(getRoomState, (state) => state.currentMatchId);

export const getAllTables = createSelector(getRoomState, (state) => state.currentRoom?.tables);

export const getAllMatches = createSelector(getRoomState, (state) => state.currentRoom?.matches);

import { AllowedContextTypesDTO, AllowedTimeFramesDTO, toAllowedContextTypes, toAllowedTimeFrames } from './metadata-dto';
import { MetricMetadata, MetricType, TimeUnit, ValueUnit } from './metric-metadata';

export enum MetricTypeDTO {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

function toMetricType(type: MetricTypeDTO): MetricType {
  switch (type) {
    case MetricTypeDTO.SUM:
      return MetricType.SUM;
    case MetricTypeDTO.AVERAGE:
      return MetricType.AVERAGE;
  }
}

export enum TimeUnitDTO {
  DATE = 'DATE',
  WEEK = 'WEEK',
  MATCH = 'MATCH',
}

function toTimeUnit(unit: TimeUnitDTO): TimeUnit {
  switch (unit) {
    case TimeUnitDTO.DATE:
      return TimeUnit.DATE;
    case TimeUnitDTO.WEEK:
      return TimeUnit.WEEK;
    case TimeUnitDTO.MATCH:
      return TimeUnit.MATCH;
  }
}

export enum ValueUnitDTO {
  UNITLESS = 'UNITLESS',
  GOAL = 'GOAL',
  MATCH = 'MATCH',
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
}

function toValueUnit(unit: ValueUnitDTO): ValueUnit {
  switch (unit) {
    case ValueUnitDTO.UNITLESS:
      return ValueUnit.UNITLESS;
    case ValueUnitDTO.GOAL:
      return ValueUnit.GOAL;
    case ValueUnitDTO.MATCH:
      return ValueUnit.MATCH;
    case ValueUnitDTO.SECOND:
      return ValueUnit.SECOND;
    case ValueUnitDTO.MINUTE:
      return ValueUnit.MINUTE;
  }
}

export interface MetricMetadataDTO {
  name: string;
  type: MetricTypeDTO;
  timeUnit: TimeUnitDTO;
  valueUnit: ValueUnitDTO;
  allowedTimeFrames: Array<AllowedTimeFramesDTO>;
  allowedContextTypes: Array<AllowedContextTypesDTO>;
}

export function toMetricMetadata(metadata: MetricMetadataDTO): MetricMetadata {
  const metricMetadata: MetricMetadata = {
    name: metadata.name,
    type: toMetricType(metadata.type),
    timeUnit: toTimeUnit(metadata.timeUnit),
    valueUnit: toValueUnit(metadata.valueUnit),
    allowedTimeFrames: metadata.allowedTimeFrames.map(toAllowedTimeFrames),
    allowedContextTypes: metadata.allowedContextTypes.map(toAllowedContextTypes),
  };

  return metricMetadata;
}

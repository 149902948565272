import { Injectable } from '@angular/core';
import NoSleep from 'nosleep.js';

@Injectable()
export class WakeLockService {
  private noSleep = new NoSleep();

  /**
   * Enables the screen wake lock, if not already enabled.
   * Needs to be called within a user action handler, e.g. onClick handler.
   */
  public enableWakeLock(): void {
    if (!this.noSleep.isEnabled) {
      this.noSleep.enable();
    }
  }

  /**
   * Disabled the screen wake lock.
   */
  public disableWakeLock(): void {
    this.noSleep.disable();
  }
}

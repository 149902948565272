import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  MatchStatus,
  MatchTeam,
  MatchTeamPosition,
  Participant,
  ParticipantInMatch,
  ParticipantPosition,
  ScoreGoalInfo,
  DevicePosition,
} from '@ranked/model';
import { ParticipantTeamColorMapping } from '../../../model/participant-team-color-mapping';
import { MatchStoreService } from '../../../services/match-store.service';

// TODO: Performance increases possible with refactoring this component/template
@Component({
  selector: 'ranked-match-status-side',
  templateUrl: './match-status-side.component.html',
  styleUrls: ['./match-status-side.component.scss'],
})
export class MatchStatusSideComponent implements OnChanges {
  public participants: Array<ParticipantInMatch>;
  public participantChangeAllowed: boolean;
  public participantAddAllowed: boolean;
  public participantsToShow: 'all' | ParticipantPosition;

  public showParticipantSelection = false;
  public selectedParticipantPosition: ParticipantPosition;

  @Input() public roomParticipants: Array<Participant>;
  @Input() public devicePosition: DevicePosition;
  @Input() public matchStatus: MatchStatus;
  @Input() public team: MatchTeam;
  @Input() public color: string;
  @Input() public position: MatchTeamPosition;
  @Input() public top: boolean;
  @Input() public participantSwitchAllowed: boolean;
  @Input() public selectedParticipantsTeamColorMapping: ParticipantTeamColorMapping;

  constructor(private matchStoreService: MatchStoreService) {}

  private getRelevantPosition(devicePosition: DevicePosition): ParticipantPosition {
    if (devicePosition === DevicePosition.LEFT) {
      return this.top ? ParticipantPosition.OFFENSE : ParticipantPosition.DEFENSE;
    } else if (devicePosition === DevicePosition.RIGHT) {
      return this.top ? ParticipantPosition.DEFENSE : ParticipantPosition.OFFENSE;
    } else {
      return null;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['matchStatus']) {
      const isPrepareStatus = this.matchStatus === MatchStatus.PREPARE;
      this.participantChangeAllowed = isPrepareStatus;

      if (this.matchHasStarted()) {
        this.participantsToShow = this.getRelevantPosition(this.devicePosition) || 'all';
      } else {
        this.participantsToShow = 'all';
      }
    }

    if (changes['team']) {
      this.participantAddAllowed = !(this.team.attackPlayerName && this.team.defensePlayerName);

      if (
        changes['team'].firstChange ||
        changes['team'].previousValue.defensePlayerName !== this.team.defensePlayerName ||
        changes['team'].previousValue.attackPlayerName !== this.team.attackPlayerName
      ) {
        this.participants = [];

        if (this.team.defensePlayerName) {
          this.participants.push([
            this.roomParticipants.find((p) => p.id === this.team.defensePlayerName),
            { position: ParticipantPosition.DEFENSE },
          ]);
        }

        if (this.team.attackPlayerName) {
          this.participants.push([
            this.roomParticipants.find((p) => p.id === this.team.attackPlayerName),
            { position: ParticipantPosition.OFFENSE },
          ]);
        }
      }
    }
  }

  public matchHasStarted(): boolean {
    return this.matchStatus === MatchStatus.RUNNING || this.matchStatus === MatchStatus.PAUSED;
  }

  public addParticipant(): void {
    // always use OFFENSE here, backend just sorts the second player onto the correct position
    this.selectedParticipantPosition = ParticipantPosition.OFFENSE;
    this.showParticipantSelection = true;
  }

  public changeParticipant(participant: ParticipantInMatch): void {
    this.selectedParticipantPosition = participant[1].position;
    this.showParticipantSelection = true;
  }

  public onParticipantSelected(participant: Participant): void {
    if (participant) {
      this.matchStoreService.addParticipantFromMatchStatusSide({
        teamPosition: this.position,
        participantPosition: this.selectedParticipantPosition,
        participant,
      });
    }

    this.showParticipantSelection = false;
  }

  public switchPlayers(): void {
    this.matchStoreService.switchedPlayersFromMatchStatusSide({
      teamPosition: this.position,
    });
  }

  public scoreGoal(info: ScoreGoalInfo): void {
    this.matchStoreService.scoreGoalFromMatchStatusSide({
      participant: info.participant,
      manikinPosition: info.manikin,
    });
  }
}

import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Ranking } from '../../model';

interface RankingData {
  id: string;
  name: string;
  image: string;
  value: number;
  barLength: number;
}

@Component({
  selector: 'ranked-ranking-list',
  templateUrl: './ranking-list.component.html',
  styleUrls: ['./ranking-list.component.scss'],
})
export class RankingListComponent implements AfterViewInit {
  private readonly minBarLength = 10;
  private readonly maxBarLength = 100;

  public data: RankingData[] = [];
  public numberFormat = '1.1-1';

  @Input() public unit: string;
  @Input() public highlightedParticipantId: string;

  @Input() public set ranking(value: Ranking) {
    this.data = this.mapRankingToData(value);
  }

  @Input()
  public set decimalCount(value: number) {
    this.numberFormat = `1.${value}-${value}`;
  }

  @ViewChild('rankingList')
  public rankingListRef: ElementRef<HTMLElement>;

  private mapRankingToData(ranking: Ranking): RankingData[] {
    if (ranking.length <= 0) {
      return [];
    }

    const min = ranking[ranking.length - 1].value;

    const maxDiff = ranking[0].value - min;
    const goalMaxDiff = this.maxBarLength - this.minBarLength;
    const factor = goalMaxDiff / maxDiff;

    return ranking.map((rankingValue) => ({
      id: rankingValue.participant.id,
      name: rankingValue.participant.name,
      image: rankingValue.participant.image,
      value: rankingValue.value,
      barLength: +((rankingValue.value - min) * factor + this.minBarLength).toFixed(2),
    }));
  }

  private scrollToEntryIfNotVisible(target?: Element): void {
    if (target !== undefined && target.getBoundingClientRect().bottom > window.innerHeight) {
      target.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  public ngAfterViewInit(): void {
    if ('IntersectionObserver' in window) {
      const scrollToHighlightAnimation: IntersectionObserverCallback = (entries) => {
        const listIsVisible = entries.some((entry) => entry.isIntersecting);

        if (listIsVisible) {
          setTimeout(() => {
            const highlightedElements = this.rankingListRef.nativeElement.querySelectorAll('.highlighted');
            this.scrollToEntryIfNotVisible(highlightedElements[0]);
          }, 100);
          scrollToHighlightObserver.disconnect();
        }
      };

      const scrollToHighlightObserver = new IntersectionObserver(scrollToHighlightAnimation, { threshold: 1.0 });
      this.rankingListRef.nativeElement.querySelectorAll('.ranking-entry:first-child').forEach((element) => {
        scrollToHighlightObserver.observe(element);
      });
    }
  }
}

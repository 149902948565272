import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorLoggerService implements ErrorHandler {
  constructor(private loggerService: LoggerService) {}

  public handleError(error: unknown): void {
    this.loggerService.log('ERROR', 'An unhandled error has occurred.', error);
  }
}

<div class="create-account">
  <ranked-safe-area-top color="light"></ranked-safe-area-top>

  <span class="title-text">
    {{ 'account.create.title' | translate }}
  </span>

  <ranked-avatar-slider [avatars]="avatars"></ranked-avatar-slider>

  <ranked-input-enhancer class="nickname" [centered]="true">
    <input
      rankedInput
      [(ngModel)]="nickname"
      type="text"
      name="nickname"
      placeholder="{{ 'account.create.placeholder.nickname' | translate }}"
    />
  </ranked-input-enhancer>

  <!-- Save-Button -->
  <div class="buttons">
    <button
      class="save"
      ranked-button
      [fullWidth]="true"
      size="big"
      [useBoxShadow]="true"
      text="{{ 'account.create.button.save' | translate }}"
      (click)="onSaveClick()"
    ></button>
  </div>

  <ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiModule } from '@ranked/shared-ui';
import { RankingListComponent } from './components/ranking-list/ranking-list.component';
import { AnimatedNumberDirective } from './directives/animated-number.directive';
import { StatisticsClientService } from './services/statistics-client.service';

@NgModule({
  imports: [CommonModule, SharedUiModule],
  providers: [StatisticsClientService],
  declarations: [AnimatedNumberDirective, RankingListComponent],
  exports: [RankingListComponent, AnimatedNumberDirective],
})
export class StatisticsBasicModule {}

<article *ngFor="let entry of logEntries" class="log-entry">
  <time class="timestamp">{{ entry.timestamp | date : 'medium' : 'de' }}</time>
  <p class="message">{{ entry.message }}</p>
  <details *ngIf="entry.data" class="data">
    <summary class="data__summary">
      <span>Data</span>
      <button class="copy-button" (click)="copyLogData(entry)">
        <span class="material-icons">content_copy</span>
      </button>
    </summary>
    <pre class="data__content">{{ entry.data }}</pre>
  </details>
</article>

<dialog #snackbar class="snackbar">
  <p>Data successfully copied to the clipboard</p>
</dialog>

<ranked-shutter class="menu" [clipText]="(room | async)?.name">
  <button ranked-home-shutter-button [label]="'home.menu.change-room' | translate" icon="meeting_room" (click)="changeRoom()"></button>
  <button ranked-home-shutter-button [label]="'home.menu.account' | translate" icon="person" (click)="goToAccountPage()"></button>
</ranked-shutter>

<ranked-safe-area-top color="dark"></ranked-safe-area-top>

<button ranked-button text="{{ 'home.button.play' | translate }}" size="huge" [showIcon]="true" routerLink="/room/select-table">
  <img class="icon puppet" src="assets/images/icons/buttons/Puppet.svg" />
</button>
<!-- <button ranked-button text="{{ 'home.button.find-others' | translate }}" fontSizeRem="1.5" color="secondary"
    [showIcon]="true" routerLink="/room">
    <div class="icon group">
      <img src="assets/images/icons/buttons/Group.svg" />
    </div>
  </button> -->
<button
  ranked-button
  text="{{ 'home.button.statistics' | translate }}"
  fontSizeRem="1.5"
  color="secondary"
  [showIcon]="true"
  routerLink="/statistics"
>
  <img class="icon statistics" src="assets/images/icons/buttons/Statistics.svg" />
</button>

<ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>

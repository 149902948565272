<div class="match-info {{ devicePosition | positionAsCssClass }} {{ matchStatus.toLowerCase() }}">
  <div class="background-container">
    <div class="shape-top theme-{{ table?.color1 }}" [class.winner]="matchWinner === 'color1'">
      <div class="background">
        <div class="spot"></div>
      </div>
    </div>

    <div class="shape-bottom theme-{{ table?.color2 }}" [class.winner]="matchWinner === 'color2'">
      <div class="background">
        <div class="spot"></div>
      </div>
    </div>
  </div>

  <div class="content-container">
    <div class="table-info content">
      <div class="table-name">{{ table?.name }}</div>
      <div class="table-rules">
        <div>
          {{ 'match.info.rules.sets' | translate : { sets: ruleSet?.setsNeededToWin } }}
        </div>
        <div>
          {{ 'match.info.rules.goals' | translate : { goals: ruleSet?.goalsNeededToWin } }}
        </div>
      </div>
    </div>

    <div class="set-count content">
      <span class="number">{{ currentSetCount }}</span>
    </div>

    <div class="draw-info content" [class.hidden]="matchWinner !== undefined">
      <button
        ranked-button
        (click)="onFinishButtonClicked()"
        color="primary"
        size="huge"
        class="theme-{{ correspondingColorForTableSide() }} finish-button"
        text="{{ 'match.button.finish' | translate }}"
      ></button>
    </div>

    <div class="winner-info {{ matchWinner === 'color1' ? 'top' : matchWinner === 'color2' ? 'bottom' : 'draw' }}">
      <button
        ranked-button
        (click)="onRematchButtonClicked()"
        color="primary"
        class="theme-{{ matchWinner === 'color1' ? table.color2 : table.color1 }} rematch-button"
        text="{{ 'match.button.rematch' | translate }}"
      ></button>

      <div class="won-text">{{ 'match.info.winner.won' | translate }}</div>

      <button
        ranked-button
        (click)="onFinishButtonClicked()"
        color="primary"
        size="huge"
        class="theme-{{ matchWinner === 'color1' ? table.color1 : table.color2 }} finish-button"
        text="{{ 'match.button.finish' | translate }}"
      ></button>

      <div class="match-history" *ngIf="scoreHistory">
        <div class="headline">
          {{ 'match.info.winner.match-history' | translate }}
        </div>
        <table class="results">
          <tr>
            <td *ngFor="let goals of scoreHistory.teamColor1; let i = index" [class.winner]="goals > scoreHistory.teamColor2[i]">
              {{ goals }}
            </td>
          </tr>
          <tr>
            <td *ngFor="let goals of scoreHistory.teamColor2; let i = index" [class.winner]="goals > scoreHistory.teamColor1[i]">
              {{ goals }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <button
    ranked-button
    *ngIf="matchIsStartable"
    (click)="onStartButtonClicked()"
    class="theme-{{ correspondingColorForTableSide() }} start-button"
    color="primary"
    size="huge"
    text="{{ 'match.button.start' | translate }}"
  ></button>
</div>

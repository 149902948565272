import { Creator } from '@ngrx/store';
import { NotAllowedInPropsCheck } from '@ngrx/store/src/models';
import { ErrorAction, LogIgnoreAction } from '../model/actions';
import { LOG_LEVEL_IGNORE } from '../model/log-level';

export function propsWithError<P extends { error: unknown } & SafeProps, SafeProps = NotAllowedInPropsCheck<P>>(): Creator<
  [args: P],
  P & ErrorAction
> {
  return (...[props]) => ({
    logLevel: 'ERROR',
    ...props,
  });
}

export function propsWithLogIgnore<P extends SafeProps, SafeProps = NotAllowedInPropsCheck<P>>(): Creator<[args: P], P & LogIgnoreAction> {
  return (...[props]) => ({
    logLevel: LOG_LEVEL_IGNORE,
    ...props,
  });
}

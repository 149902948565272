import { DevicePosition } from './device-position';
import { Match, MatchWinner } from './match';
import { MatchStateDTO, toMatchStatus } from './match-state-dto';

export type DevicePositionDTO = 'LEFT' | 'RIGHT';

export interface MatchTeamDTO {
  name: string;
  attackPlayerName: string;
  defensePlayerName: string;
}

export interface CurrentScoreInfoDTO {
  currentMatchSet: number;
  goalsColor1: number;
  goalsColor2: number;
  wonMatchSetsColor1: number;
  wonMatchSetsColor2: number;
}

export interface MatchDTO {
  matchId: string;
  roomId: string;
  tableName: string;
  matchRules: string;
  matchTeams: {
    color1: MatchTeamDTO;
    color2: MatchTeamDTO;
  };
  matchState: MatchStateDTO;
  playerSwitchAllowed: boolean;
  currentScore: CurrentScoreInfoDTO;
  startTime: Date | string;
  pauseTimeElapsedInMs: number;
  finishedTime: Date | string;
  devicePosition: DevicePositionDTO;
  winner?: 'COLOR1' | 'COLOR2';
  undoPossible: boolean;
}

export function toRankedMatchState(match: MatchDTO): Match {
  const matchState: Match = {
    id: match.matchId,
    tableName: match.tableName,
    ruleSetName: match.matchRules,
    status: toMatchStatus(match.matchState),
    startable: match.matchState === MatchStateDTO.PREPARED,
    startTime: match.startTime === 'null' ? null : (match.startTime as Date),
    finishedTime: match.finishedTime === 'null' ? null : (match.finishedTime as Date),
    currentTime: calcSecondsFromStartTimeAndPausedTime(match.startTime, match.pauseTimeElapsedInMs ?? 0),
    currentSetCount: match.currentScore.currentMatchSet,
    teamColor1: {
      ...match.matchTeams.color1,
      goalsCount: match.currentScore.goalsColor1,
      wonMatchSets: match.currentScore.wonMatchSetsColor1,
    },
    teamColor2: {
      ...match.matchTeams.color2,
      goalsCount: match.currentScore.goalsColor2,
      wonMatchSets: match.currentScore.wonMatchSetsColor2,
    },
    undoPossible: match.undoPossible,
    winner: match.winner?.toLocaleLowerCase() as MatchWinner,
    completed: match.matchState === MatchStateDTO.ENDED,
    participantSwitchAllowed: match.playerSwitchAllowed,
  };

  return matchState;
}

export function calcSecondsFromStartTimeAndPausedTime(startTime: string | Date | null, pauseTimeElapsedInMs: number | null): number {
  let startDate: number;
  if (startTime === null) {
    return 0;
  }
  if (typeof startTime === 'string') {
    startDate = Date.parse(startTime);
  } else if (startTime instanceof Date) {
    startDate = startTime.getTime();
  }
  const timeElapsed = Date.now() - startDate - pauseTimeElapsedInMs;
  const seconds = Math.round(timeElapsed / 1000);
  return !isNaN(seconds) ? seconds : 0;
}

export function toDevicePostion(match: MatchDTO): DevicePosition | undefined {
  switch (match.devicePosition) {
    case 'LEFT':
      return DevicePosition.LEFT;
    case 'RIGHT':
      return DevicePosition.RIGHT;
    default:
      return undefined;
  }
}

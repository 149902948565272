import { Action, createReducer, on } from '@ngrx/store';
import { MatchEnded } from '@ranked/match';
import { Room } from '@ranked/model';
import { CurrentMatchFound, MatchListLoaded, RoomLeft, RoomLoaded } from './room.actions';

export const ROOM_FEATURE_KEY = 'room';

export interface RoomState {
  currentRoom?: Room;
  currentMatchId?: string;
}

export interface RoomAppState {
  readonly [ROOM_FEATURE_KEY]: RoomState;
}

export const initialRoomState: RoomState = {};

const reducer = createReducer(
  initialRoomState,
  on(RoomLoaded, (state, { room }) => ({
    ...state,
    currentRoom: room,
  })),
  on(CurrentMatchFound, (state, { matchId }) => ({
    ...state,
    currentMatchId: matchId,
  })),
  on(MatchEnded, (state) => ({
    ...state,
    currentMatchId: undefined,
  })),
  on(RoomLeft, (state) => ({
    ...state,
    currentRoom: undefined,
    currentMatchId: undefined,
  })),
  on(MatchListLoaded, (state, { matchList }) => ({
    ...state,
    currentRoom: {
      ...state.currentRoom,
      matches: matchList,
    },
  })),
);

export function roomReducer(state: RoomState | undefined, action: Action): RoomState {
  return reducer(state, action);
}

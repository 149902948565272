import { Injectable } from '@angular/core';

export interface DetailedLogEntry {
  timestamp: Date;
  message: string;
  data?: object;
}

@Injectable()
export class LogFileService {
  private readonly DETAILED_LOG_FILE_KEY = 'ranked-log-file';
  private readonly MAX_LOG_ENTRY_SIZE = 10;
  private readonly MAX_DETAILED_LOG_ENTRY_SIZE = 1000;

  private detailedLogFile: Array<DetailedLogEntry>;
  private logFile: Array<string>;

  public constructor() {
    this.logFile = [];
    this.detailedLogFile = JSON.parse(localStorage.getItem(this.DETAILED_LOG_FILE_KEY) ?? '[]', (key, value) =>
      key === 'timestamp' ? new Date(value) : value,
    );

    window.addEventListener('beforeunload', () => {
      localStorage.setItem(this.DETAILED_LOG_FILE_KEY, JSON.stringify(this.detailedLogFile));
    });
  }

  public addDetailedLogEntry(message: string, data?: object): void {
    const length = this.detailedLogFile.push({ timestamp: new Date(), message, data });

    if (length > this.MAX_DETAILED_LOG_ENTRY_SIZE) {
      this.detailedLogFile.splice(0, 100);
    }
  }

  public addLogEntry(logEntry: string): void {
    const length = this.logFile.push(`${new Date().toUTCString()}: ${logEntry}`);

    if (length > this.MAX_LOG_ENTRY_SIZE) {
      this.logFile.splice(0, length - this.MAX_LOG_ENTRY_SIZE);
    }
  }

  public getLogFile(): readonly string[] {
    return Object.freeze([...this.logFile]);
  }

  public getDetailedLogFile(): readonly DetailedLogEntry[] {
    return Object.freeze([...this.detailedLogFile]);
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { RoomStoreService } from '@ranked/room';

const UUID_REGEX = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

@Component({
  selector: 'ranked-join-room',
  templateUrl: './join-room.component.html',
  styleUrls: ['./join-room.component.scss'],
})
export class JoinRoomComponent {
  roomCodeParts: string[] = [];
  emailLink: string;

  constructor(
    private router: Router,
    private accountStoreService: AccountStoreService,
    private roomStoreService: RoomStoreService,
    private translateService: TranslateService,
  ) {
    this.emailLink =
      'mailto:info@get-ranked.app?subject=' +
      this.translateService.instant('app.join-room.mail-subject') +
      '&body=' +
      this.translateService.instant('app.join-room.mail-body');
  }

  onJoinSubmit(): void {
    this.roomStoreService
      .roomSuccessfullyLoaded$()
      .pipe(first())
      .subscribe(() => {
        this.router.navigateByUrl('/home');
      });
    const roomCode = this.roomCodeParts.join('-');
    this.accountStoreService.joinRoom({ roomId: roomCode });
  }

  qualityOfLifeInsert(): void {
    if (this.roomCodeParts[0].toLowerCase().match(UUID_REGEX) !== null && this.roomCodeParts[0].split('-').length === 5) {
      this.roomCodeParts = this.roomCodeParts[0].split('-');
    }
  }
}

import { Component } from '@angular/core';
import { AccountStoreService } from '../../services/account-store.service';

@Component({
  selector: 'ranked-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class ForgotPasswordComponent {
  public email: string;
  public requestSuccessful: boolean | undefined = undefined;

  constructor(private accountStoreService: AccountStoreService) {}

  private clearFocus(): void {
    (document.activeElement as HTMLElement).blur();
  }

  public async onResetPassword(): Promise<void> {
    this.clearFocus();
    this.requestSuccessful = await this.accountStoreService.requestNewPassword(this.email);
  }
}

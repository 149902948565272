import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, EnvironmentType } from '@ranked/settings';
import { LogLevel } from '../model/log-level';
import { LogMessage } from '../model/log-message';
import { Logger } from '../model/logger';
import { LogFileService } from './log-file.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private loggers: Array<Logger> = [];

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType, private logFileService: LogFileService) {}

  public addLogger(...loggers: Array<Logger>): void {
    this.loggers.push(...loggers);
  }

  public removeLogger(logger: Logger): void;
  public removeLogger(loggerName: string): void;
  public removeLogger(loggerOrName: Logger | string): void {
    const compare =
      typeof loggerOrName === 'string' ? (logger: Logger) => logger.name === loggerOrName : (logger: Logger) => logger === loggerOrName;
    this.loggers = this.loggers.filter(compare);
  }

  public log(logLevel: 'ERROR', message: string, error: unknown): void;
  public log(logLevel: LogLevel, message: string): void;
  public log(logLevel: LogLevel, message: string, error?: unknown): void {
    if (logLevel === 'DEBUG' && this.environment.production) {
      return;
    }

    const logMessage: LogMessage =
      logLevel === 'ERROR'
        ? {
            logLevel,
            text: message,
            error,
            eventLog: this.logFileService.getLogFile(),
          }
        : {
            logLevel: logLevel,
            text: message,
          };

    this.loggers.forEach((logger) => logger.log(logMessage));
  }
}

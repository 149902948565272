<div class="match-side">
  <div class="participants" *ngIf="participants">
    <ranked-participants
      [participants]="participants"
      [changeAllowed]="participantChangeAllowed"
      [addAllowed]="participantAddAllowed"
      [switchAllowed]="participantSwitchAllowed"
      [participantsToShow]="participantsToShow"
      [iconSize]="matchHasStarted() ? 'small' : 'normal'"
      (add)="addParticipant()"
      (replace)="changeParticipant($event)"
      (switch)="switchPlayers()"
    >
    </ranked-participants>
  </div>

  <ranked-particpant-selection
    *rankedOverlay="showParticipantSelection"
    [themeColor]="color"
    [position]="position"
    [selectedParticipantsTeamColorMapping]="selectedParticipantsTeamColorMapping"
    [participants]="roomParticipants"
    (selected)="onParticipantSelected($event)"
  >
  </ranked-particpant-selection>

  <ranked-match-running-side
    *ngIf="matchHasStarted()"
    [devicePosition]="devicePosition"
    [participants]="participants"
    [team]="team"
    [relevantParticipantPosition]="participantsToShow"
    [color]="color"
    [top]="top"
    (scoreGoal)="scoreGoal($event)"
  >
  </ranked-match-running-side>
</div>

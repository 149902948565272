import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[rankedAnimatedNumber]',
})
export class AnimatedNumberDirective implements OnChanges {
  private readonly updateInterval = 16;
  private stepCount = 0;
  private animationId: number;

  @Input('rankedAnimatedNumber')
  public value: number;

  @Input()
  public durationSeconds = 0.6;

  constructor(private el: ElementRef<HTMLElement>) {}

  private setDisplayValue(displayValue: number | string): void {
    this.el.nativeElement.textContent = `${displayValue}`;
  }

  private countDecimals(value: number): number {
    if (Math.floor(value) === value) {
      return 0;
    }

    return value.toString().split('.')[1]?.length || 0;
  }

  private animate(from: number, to: number): void {
    this.stopAnimation();
    const diff = to - from;
    const decimalCount = Math.max(this.countDecimals(from), this.countDecimals(to));
    const steps = Math.ceil((this.durationSeconds * 1000) / this.updateInterval);
    const stepSize = diff / steps;

    const animateStep = () => {
      this.stepCount++;

      if (this.stepCount >= steps) {
        this.stopAnimation();
        this.setDisplayValue(to);
      } else {
        const displayValue = from + stepSize * this.stepCount;
        this.setDisplayValue(displayValue.toFixed(decimalCount));
        this.animationId = requestAnimationFrame(animateStep);
      }
    };

    animateStep();
  }

  private stopAnimation(): void {
    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
      this.animationId = null;
    }
    this.stepCount = 0;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      const valueChanges = changes['value'];

      if (valueChanges.firstChange) {
        this.setDisplayValue(valueChanges.currentValue as number);
      } else {
        const newValue = valueChanges.currentValue;
        const oldValue = valueChanges.previousValue || 0;
        this.animate(oldValue, newValue);
      }
    }
  }
}

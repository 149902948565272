import { getRouterSelectors, MinimalRouterStateSnapshot, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
  router: RouterReducerState<MinimalRouterStateSnapshot>;
}

const selectRouter = createFeatureSelector<RouterReducerState<MinimalRouterStateSnapshot>>('router');

const { selectRouteData, selectQueryParams } = getRouterSelectors(selectRouter);

export const getRouteData = createSelector(selectRouteData, (data) => data);

export const getQueryParams = createSelector(selectQueryParams, (data) => data);

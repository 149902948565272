export interface ChangesEntry {
  readonly name: string;
  readonly oldValue: unknown;
  readonly newValue: unknown;
}

interface ChangesObject {
  readonly deleted: ChangesEntry[];
  readonly changed: ChangesEntry[];
  readonly created: ChangesEntry[];
}

export class Changes {
  private deleted: ChangesEntry[] = [];
  private changed: ChangesEntry[] = [];
  private created: ChangesEntry[] = [];

  public isEmpty(): boolean {
    return this.deleted.length <= 0 && this.changed.length <= 0 && this.created.length <= 0;
  }

  public toObject(): ChangesObject {
    return Object.seal({
      deleted: this.deleted,
      changed: this.changed,
      created: this.created,
    });
  }

  public toLogEntry(): string {
    const messages: string[] = [];

    if (this.deleted.length > 0) {
      messages.push(`DELETED: ${this.deleted.map((entry) => entry.name).join(', ')}`);
    }

    if (this.created.length > 0) {
      messages.push(`CREATED: ${this.created.map((entry) => entry.name).join(', ')}`);
    }

    if (this.changed.length > 0) {
      messages.push(
        `CHANGED: ${this.changed
          .map((entry) => `${entry.name} from ${JSON.stringify(entry.oldValue)} to ${JSON.stringify(entry.newValue)}`)
          .join(', ')}`,
      );
    }

    return `[${messages.join('; ')}]`;
  }

  public add(other: Changes): void {
    const otherObject = other.toObject();

    this.deleted.push(...otherObject.deleted);
    this.changed.push(...otherObject.changed);
    this.created.push(...otherObject.created);
  }

  public addEntry(entry: ChangesEntry): void {
    let property: keyof ChangesObject = 'changed';
    if (entry.newValue === undefined) {
      property = 'deleted';
    } else if (entry.oldValue === undefined) {
      property = 'created';
    }

    this[property].push(entry);
  }
}

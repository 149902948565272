import { Participant } from '@ranked/model';
import { Ranking, RankingValue } from './ranking';

export interface RankingDTO {
  ranked: string;
  value: number;
}

function createDefaultForMissingParticipant(id: string): Participant {
  return {
    id,
    name: id,
    image: 'https://get-ranked.app/assets/images/account_avatars/avatar_0.png',
  };
}

export function toRanking(rankingDtos: RankingDTO[], availableParticipants: Participant[]): Ranking {
  const participantMap = availableParticipants.reduce<{ [id: string]: Participant }>((map, participant) => {
    map[participant.id] = participant;
    return map;
  }, {});

  return rankingDtos.map((rankingDto) => {
    const rankingValue: RankingValue = {
      participant: participantMap[rankingDto.ranked] ?? createDefaultForMissingParticipant(rankingDto.ranked),
      value: rankingDto.value,
    };

    return rankingValue;
  });
}

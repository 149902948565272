import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { RoomStoreService } from '@ranked/room';
import { Metric, MetricMetadata, MetricQueryParameters, Ranking, RankingMetadata, RankingQueryParameters } from '../model';
import { StatisticsClientService } from './statistics-client.service';

// TODO: maybe we should add some kind of caching and/or automatic update here?
@Injectable()
export class StatisticsDataService {
  public constructor(
    private accountStoreService: AccountStoreService,
    private roomStoreService: RoomStoreService,
    private statisticsClientService: StatisticsClientService,
  ) {}

  public getMetricsMetadata(): Observable<MetricMetadata[]> {
    return this.statisticsClientService.getMetricsMetadata();
  }

  public getMetric(metricName: string, params: MetricQueryParameters): Observable<Metric> {
    return combineLatest([this.accountStoreService.getRankedAccountId(), this.roomStoreService.getCurrentRoomId()]).pipe(
      mergeMap(([accountId, roomId]) => this.statisticsClientService.getMetric(metricName, params, accountId, roomId)),
    );
  }

  public getRankingsMetadata(): Observable<RankingMetadata[]> {
    return this.statisticsClientService.getRankingsMetadata();
  }

  public getRanking(rankingName: string, params: RankingQueryParameters): Observable<Ranking> {
    return combineLatest([this.accountStoreService.getRankedAccountId(), this.roomStoreService.getCurrentRoom()]).pipe(
      mergeMap(([accountId, room]) => this.statisticsClientService.getRanking(rankingName, params, room.participants, accountId, room.id)),
    );
  }
}

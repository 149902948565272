/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ROUTER_CANCEL, ROUTER_ERROR, ROUTER_NAVIGATED, ROUTER_NAVIGATION, RouterAction } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { ErrorAction } from '../model/actions';
import { LOG_LEVEL_IGNORE } from '../model/log-level';
import { Changes, ChangesEntry } from '../model/state-changes';
import { LogFileService } from '../services/log-file.service';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class ActionLogService {
  private currentRoute = '';

  public constructor(private logFileService: LogFileService, private loggerService: LoggerService) {}

  private createChangesEntryForArrays(name: string, arrayA: unknown[], arrayB: unknown[]): ChangesEntry[] {
    if (arrayA.length === 0 && arrayB.length === 0) {
      return [];
    } else if (arrayA.length === 0) {
      return [{ name, oldValue: undefined, newValue: arrayB }];
    } else if (arrayB.length === 0) {
      return [{ name, oldValue: arrayA, newValue: undefined }];
    } else {
      const entries: ChangesEntry[] = [];
      const itemsName = `${name}.items`;
      const stringArrayA = arrayA.map((item) => JSON.stringify(item));
      const stringArrayB = arrayB.map((item) => JSON.stringify(item));
      const deletedItems = arrayA.filter((_, index) => !stringArrayB.includes(stringArrayA[index]));
      const createdItems = arrayB.filter((_, index) => !stringArrayA.includes(stringArrayB[index]));

      if (deletedItems.length > 0) {
        entries.push({ name: itemsName, oldValue: deletedItems, newValue: undefined });
      }

      if (createdItems.length > 0) {
        entries.push({ name: itemsName, oldValue: undefined, newValue: createdItems });
      }

      return entries;
    }
  }

  private objectIsEmpty(object: object | null): boolean {
    if (object === null) {
      return true;
    }

    if (Array.isArray(object) && object.length === 0) {
      return true;
    }

    return Object.getOwnPropertyNames(object).length === 0;
  }

  private mapEmptyObjectToUndefined<T>(value: T): T | undefined {
    if (typeof value !== 'object') {
      return value;
    }

    return this.objectIsEmpty(value) ? undefined : value;
  }

  private compareIgnoreDifferentEmptiness(valueA: unknown, valueB: unknown): boolean {
    return this.mapEmptyObjectToUndefined(valueA) === this.mapEmptyObjectToUndefined(valueB);
  }

  private deepCompare(objectA: any, objectB: any, namePrefix?: string): Changes {
    const changes = new Changes();

    const allPropertyNames = new Set(Object.getOwnPropertyNames(objectA ?? {}).concat(Object.getOwnPropertyNames(objectB ?? {})));

    if (allPropertyNames.size > 0) {
      allPropertyNames.forEach((propertyName) => {
        const totalPropertyName = namePrefix === undefined ? propertyName : `${namePrefix}.${propertyName}`;

        const propertyValueA = objectA?.[propertyName];
        const propertyValueB = objectB?.[propertyName];

        if (!this.compareIgnoreDifferentEmptiness(propertyValueA, propertyValueB)) {
          if (typeof propertyValueA !== 'object' || typeof propertyValueB !== 'object') {
            changes.addEntry({ name: totalPropertyName, oldValue: propertyValueA, newValue: propertyValueB });
          } else {
            const isArrayIndicator = `${Array.isArray(propertyValueA) ? 1 : 0}${Array.isArray(propertyValueB) ? 1 : 0}` as const;

            switch (isArrayIndicator) {
              case '11': // both are array
                this.createChangesEntryForArrays(totalPropertyName, propertyValueA, propertyValueB).forEach((entry) =>
                  changes.addEntry(entry),
                );
                break;
              case '10': // a is array, b is not -> not very likely
                changes.addEntry({ name: totalPropertyName, oldValue: 'Array', newValue: 'Object' });
                break;
              case '01': // a is not array, b is -> not very likely
                changes.addEntry({ name: totalPropertyName, oldValue: 'Object', newValue: 'Array' });
                break;
              case '00': // none are arrays
                changes.add(this.deepCompare(propertyValueA, propertyValueB, totalPropertyName));
                break;
            }
          }
        }
      });
    }

    return changes;
  }

  private isRouterAction(action: Action): action is RouterAction<string> {
    return action.type.startsWith('@ngrx/router-store');
  }

  private handleRouterAction(action: Action): boolean {
    if (!this.isRouterAction(action)) {
      return false;
    }

    switch (action.type) {
      case ROUTER_NAVIGATION:
        this.currentRoute += `${this.currentRoute.length !== 0 ? ' - ' : 'Routing: '}${action.payload.event.url}`;

        if (action.payload.event.urlAfterRedirects !== action.payload.event.url) {
          this.currentRoute += ' - ' + action.payload.event.urlAfterRedirects;
        }
        break;

      case ROUTER_CANCEL:
        this.currentRoute += ' (canceled)';
        break;

      case ROUTER_NAVIGATED:
        this.currentRoute += ' (success)';
        this.logFileService.addLogEntry(this.currentRoute);
        this.logFileService.addDetailedLogEntry(this.currentRoute);

        this.currentRoute = '';
        break;

      case ROUTER_ERROR:
        this.currentRoute += ' (error)';
        this.logFileService.addLogEntry(this.currentRoute);
        this.logFileService.addDetailedLogEntry(this.currentRoute);

        this.currentRoute = '';
        break;
    }

    return true;
  }

  private actionShouldBeIgnored(action: Action): boolean {
    if (action.type.startsWith('@ngrx')) {
      return true;
    }

    return 'logLevel' in action && action.logLevel === LOG_LEVEL_IGNORE;
  }

  private isErrorAction(action: Action): action is Action & ErrorAction {
    return 'logLevel' in action && action.logLevel === 'ERROR';
  }

  private writeLogFileEntry(action: Action, state: any, newState: any): void {
    if (this.handleRouterAction(action)) {
      return;
    }

    if (this.actionShouldBeIgnored(action)) {
      return;
    }

    const { type, ...payload } = action;
    const changes = this.deepCompare(state, newState);
    if (!changes.isEmpty()) {
      this.logFileService.addLogEntry(`${type} - ${changes.toLogEntry()}`);
    }

    this.logFileService.addDetailedLogEntry(type, this.mapEmptyObjectToUndefined(payload));
  }

  public metaReducer: MetaReducer = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
      const newState = reducer(state, action);

      this.writeLogFileEntry(action, state, newState);

      if (this.isErrorAction(action)) {
        this.loggerService.log('ERROR', `Failed action ${action.type}`, action.error);
      }

      return newState;
    };
  };
}

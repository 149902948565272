import { HttpClient } from '@angular/common/http';
import { Logger, LogLevel, LogMessage } from '@ranked/logging';
import { EnvironmentType } from '@ranked/settings';

type SeverityDTO = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';

function logLevelToSeverityDTO(logLevel: LogLevel): SeverityDTO {
  return logLevel;
}

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export class RankedBackendLogger implements Logger {
  public readonly name = 'Ranked Backend Logger';

  constructor(private httpClient: HttpClient, private environment: EnvironmentType) {}

  public log(message: LogMessage): void {
    if (message.logLevel === 'DEBUG') {
      return;
    }

    const severity = logLevelToSeverityDTO(message.logLevel);
    let logMessageText = message.text;

    if (message.logLevel === 'ERROR') {
      logMessageText = `${message.text} | [${message.eventLog.join(', ')}] | ${
        message.error ? JSON.stringify(message.error, getCircularReplacer()) : ''
      }`;
    }

    this.httpClient
      .post(`${this.environment.baseUrl}/api/public/log-message/${severity}`, {
        message: logMessageText,
      })
      .subscribe(
        () => void 0,
        () => {
          // ignore error
        },
      );
  }
}

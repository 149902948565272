import { createAction, props } from '@ngrx/store';
import { propsWithError, propsWithLogIgnore } from '@ranked/logging';
import {
  DevicePosition,
  ManikinPosition,
  Match,
  MatchScoreHistory,
  MatchTeamPosition,
  Participant,
  ParticipantPosition,
  RuleSet,
  Table,
} from '@ranked/model';

export const MatchJoined = createAction(
  '[Match/Match API] Match Joined',
  props<{
    matchId: string;
    roomId: string;
    table: Table;
    ruleSets: RuleSet[];
    participants: Participant[];
  }>(),
);

export const LoadMatch = createAction('[Match/Effect] Load Match', props<{ matchId: string; roomId: string }>());

export const MatchLoaded = createAction('[Match/Match API] Match Loaded', props<{ match: Match; devicePosition: DevicePosition }>());

export const MatchUpdateReceived = createAction(
  '[Match/Match API] Match Update Received',
  props<{ match: Match; devicePosition?: DevicePosition }>(),
);

export const LoadMatchFailed = createAction('[Match/Match API] Load Match Failed', propsWithError<{ error: unknown }>());

export const AddParticipantFromMatchStatusSide = createAction(
  '[Match/Match Status Side Component] Add Participant',
  props<{
    participantPosition: ParticipantPosition;
    participant: Participant;
    teamPosition: MatchTeamPosition;
  }>(),
);

export const SwitchedPlayersFromMatchStatusSide = createAction(
  '[Match/Match Status Side Component] Switch Players',
  props<{ teamPosition: MatchTeamPosition }>(),
);

export const ScoreGoalFromMatchStatusSide = createAction(
  '[Match/Match Status Side Component] Score Goal',
  props<{ participant: Participant; manikinPosition: ManikinPosition }>(),
);

export const UndoGoalFromMatchPage = createAction('[Match/Match Page] Undo Goal');

export const StartMatchFromMatchPage = createAction('[Match/Match Page] Start Match');

export const CompleteMatchFromMatchPage = createAction('[Match/Match Page] Complete Match');

export const MatchEnded = createAction('[Match/Match API] Match Ended');

export const AbortMatchFromMatchPage = createAction('[Match/Match Page] Abort Match');

export const LoadMatchScoreHistory = createAction('[Match/Effect] Load Match Score History');

export const MatchScoreHistoryLoaded = createAction('[Match/Match API] Score History Received', props<{ history: MatchScoreHistory }>());

export const PauseMatchFromMatchPage = createAction('[Match/Match Page] Pause Match');

export const ResumeMatchFromMatchPage = createAction('[Match/Match Page] Resume Match');

export const TimerTicked = createAction('[Match/Timer] Timer Ticked', propsWithLogIgnore<{ time: number }>());

export const MatchActionFailed = createAction(
  '[Match/Effect] Action in match failed',
  propsWithError<{ matchAction: string; error: unknown }>(),
);

export const MatchActionSuccessful = createAction(
  '[Match/Effect] Action in match was successful',
  propsWithLogIgnore<{ matchAction: string }>(),
);

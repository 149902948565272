import { RankedAccountState } from './ranked-account-state';

export enum RankedAccountStateDTO {
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
}

export function toRankedAccountState(state: RankedAccountStateDTO): RankedAccountState {
  switch (state) {
    case RankedAccountStateDTO.ACTIVE:
      return RankedAccountState.ACTIVE;
    case RankedAccountStateDTO.LOCKED:
      return RankedAccountState.LOCKED;
    case RankedAccountStateDTO.VERIFICATION_PENDING:
      return RankedAccountState.VERIFICATION_PENDING;
  }
}

import { Participant } from '@ranked/model';
import { AllowedContextTypes, AllowedTimeFrames } from './metadata';

export interface RankingQueryParameters {
  contextType: AllowedContextTypes;
  timeFrame: AllowedTimeFrames;
}

export interface RankingValue {
  participant: Participant;
  value: number;
}

export type Ranking = RankingValue[];

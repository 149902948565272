import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance, TrackDetails } from 'keen-slider';

@Component({
  selector: 'ranked-avatar-slider',
  templateUrl: './avatar-slider.component.html',
  styleUrls: ['./avatar-slider.component.scss'],
})
export class AvatarSliderComponent implements OnDestroy {
  private details: TrackDetails;
  private slider: KeenSliderInstance;

  public get currentSelection(): number | undefined {
    if (this.slider === undefined) {
      return undefined;
    }

    return this.slider.track.absToRel(this.slider.track.details.abs + 1);
  }

  @ViewChild('slider')
  public set sliderRef(element: ElementRef<HTMLElement>) {
    if (element !== undefined) {
      this.initializeSlider(element.nativeElement);
    }
  }

  @Input()
  public avatars: Array<string>;

  private initializeSlider(container: HTMLElement): void {
    this.slider = new KeenSlider(container, {
      loop: true,
      mode: 'free-snap',
      slides: {
        perView: 3,
      },
      renderMode: 'performance',
      detailsChanged: (s) => {
        this.details = s.track.details;
      },
    });
  }

  public ngOnDestroy(): void {
    this.slider?.destroy();
  }

  public scaleStyle(idx: number): object {
    if (!this.details) return {};

    const slide = this.details.slides[idx];

    if (slide.portion === 0) return {};

    const factor = Math.abs(Math.floor(slide.distance * 100) - 33) / 33;
    const percent = 1 - 0.4 * factor;

    return {
      transform: `scale(${percent})`,
      WebkitTransform: `scale(${percent})`,
      opacity: percent,
      filter: `saturate(${percent * 100}%)`,
    };
  }
}

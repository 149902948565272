import { BehaviorSubject, Observable } from 'rxjs';

export class TimerValue {
  private intervalId: ReturnType<typeof setInterval>;
  private valueSubject: BehaviorSubject<number>;

  public get values$(): Observable<number> {
    return this.valueSubject.asObservable();
  }

  /**
   *
   * @param tickPause (optional) Number in ms, how long to wait between two ticks.
   * @param increment (optional) How much the value is incrementented each tick.
   * @param defaultValue (optional) Default start and reset value.
   */
  constructor(private tickPause = 1000, private increment = 1, private defaultValue = 0) {
    this.valueSubject = new BehaviorSubject(this.defaultValue);
    this.intervalId = null;
  }

  /**
   * Starts or resumes the timer, if it's not started yet. When there is no startValue given, the time$ Observable emits a new value after the first tick.
   * @param startValue (optional) Number to change the value bevor the first tick happened.
   */
  public start(startValue?: number): void {
    if (this.intervalId === null) {
      if (typeof startValue === 'number') {
        this.valueSubject.next(startValue);
      }

      this.intervalId = setInterval(() => {
        this.valueSubject.next(this.valueSubject.getValue() + this.increment);
      }, this.tickPause);
    }
  }

  /**
   * Pauses the timer. The time$ Observable will not emit new values unless start() is called again.
   */
  public pause(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  /**
   * Resets the value and pauses the timer if it's not paused yet. Emits the resetted value to the time$ Observable.
   * @param resetValue (optional) Number to set the value to. If no number is given, the value is resetted to the defaultValue.
   */
  public reset(resetValue?: number): void {
    this.pause();

    this.valueSubject.next(resetValue || this.defaultValue);
  }
}

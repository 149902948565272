import { Component } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { AllowedContextTypes, AllowedTimeFrames } from '../../model';
import { StatisticsDataService } from '../../services/statistics-data.service';
import { Widget } from '../abstract-widget.component';

@Component({
  selector: 'ranked-matches-played-widget',
  templateUrl: './matches-played-widget.component.html',
  styleUrls: ['./matches-played-widget.component.scss'],
})
export class MatchesPlayedWidgetComponent extends Widget {
  public data$: Observable<{ total: number; won: number; ratio: number }>;

  constructor(protected statisticDataService: StatisticsDataService) {
    super();

    this.data$ = from([
      of({ total: 0, won: 0, ratio: 0 }),
      this.loadMetrics(
        ['MatchesPlayedTotal', AllowedContextTypes.ACCOUNT_IN_ROOM, AllowedTimeFrames.ALL_TIME],
        ['MatchesWonTotal', AllowedContextTypes.ACCOUNT_IN_ROOM, AllowedTimeFrames.ALL_TIME],
        ['MatchesWonLostRatio', AllowedContextTypes.ACCOUNT_IN_ROOM, AllowedTimeFrames.ALL_TIME],
      ).pipe(
        map(([totalPlayedMetric, wonMetric, wonLostRatio]) => {
          const total = totalPlayedMetric[0]?.value ?? 0;
          const won = wonMetric[0]?.value ?? 0;
          const ratio = (wonLostRatio[0]?.value ?? 0) * 100;

          return { total, won, ratio };
        }),
      ),
    ]).pipe(concatAll());
  }
}

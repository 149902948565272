import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParticipantInMatch, ParticipantPosition } from '@ranked/model';
import { NamedIcon } from '@ranked/shared-ui';

interface IconData extends NamedIcon {
  type: 'participant' | 'switch' | 'add';
  fullName?: string;
}

@Component({
  selector: 'ranked-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss'],
})
export class ParticipantsComponent implements OnChanges {
  public icons: Array<IconData> = [];

  @Input() public participants: Array<ParticipantInMatch>;

  @Input() public iconSize: 'normal' | 'small' = 'normal';

  @Input() public changeAllowed = false;
  @Input() public addAllowed = false;
  @Input() public switchAllowed = false;
  @Input() public switchPosition: 'left' | 'right' | 'between' = 'between';
  @Input() public participantsToShow: 'all' | ParticipantPosition = 'all';

  @Output() public add = new EventEmitter<void>();
  @Output() public replace = new EventEmitter<ParticipantInMatch>();
  @Output() public switch = new EventEmitter<void>();

  constructor(private translate: TranslateService) {}

  private generateIconData(): Array<IconData> {
    const icons: Array<IconData> = [];

    this.participants.forEach((participant) => {
      icons.push({
        type: 'participant',
        fullName: participant[0].name,
        name: participant[0].name,
        iconName: 'person',
        iconPath: participant[0].image,
      });

      if (this.switchAllowed) {
        icons.push({ type: 'switch', name: '', iconName: 'swap_horiz' });
      }
    });

    if (this.switchAllowed) {
      // no switch arrows after the last participant
      icons.pop();
    }

    if (this.addAllowed) {
      icons.push({
        type: 'add',
        name: this.translate.instant('match.participants.add'),
        iconName: 'add',
      });
    }

    return icons;
  }

  public ngOnChanges(): void {
    this.icons = this.generateIconData();
  }

  public onIconClicked(icon: IconData): void {
    switch (icon.type) {
      case 'add':
        this.add.emit();
        break;

      case 'switch':
        this.switch.emit();
        break;

      case 'participant':
        if (this.changeAllowed) {
          this.replace.emit(this.participants.find((participant) => participant[0].name === icon.fullName));
        }
        break;
    }
  }
}

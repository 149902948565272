import { RuleSet } from './rule-set';

export enum MatchRatingStrategy {
  COMPARING = 'ComparingMatchRatingStrategy',
  BEST_OF = 'BestOfMatchRatingStrategy',
}

export enum MatchSetRatingStrategy {
  GOALS_BASED = 'goalsBasedMatchSetRatingStrategy',
  TIME_BASED = 'timeBasedMatchSetRatingStrategy',
}

export enum SwitchPlayersStrategy {
  NO_SWITCH = 'noSwitch',
  FREE_SWITCH = 'freeSwitch',
  EVERY_MATCHSET_SWITCH = 'everyMatchSetSwitch',
  EVERY_MATCHSET_SWITCH_LAST_FREE = 'everyMatchSetSwitchLastFree',
}

export enum SwitchTeamsStrategy {
  NO_CHANGE = 'noChange',
  CHANGE_ONE_EVERY_MATCHSET = 'changeOneEveryMatchSet',
}

export enum DrawMode {
  NO_DRAW = 'noDraw',
  DRAW_BY_ONE_LESS = 'drawByOneLess',
}

export enum MatchTeamSetuo {
  ALL = 'ALL',
  DOUBLE_ONLY = 'DOUBLE_ONLY',
  DOUBLE_SOLO = 'DOUBLE_SOLO',
  DOUBLE_NAMED = 'DOUBLE_NAMED',
  SOLO_ONLY = 'SOLO_ONLY',
  SOLO_NAMED = 'SOLO_NAMED',
  NAMED_ONLY = 'NAMED_ONLY',
}

export enum UndoMode {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  NOT_MATCH_SET_START = 'NOT_MATCH_SET_START',
}

export interface ComparingMatchRatingStrategyDTO {
  amountOfMatchSets: number;
}

export interface BestOfMatchRatingStrategyDTO {
  numberOfMatchSetsToWinMatch: number;
}

export interface GoalsBasedMatchSetRatingStrategyDTO {
  drawMode: DrawMode;
  goalsToWinMatchSet: number;
}

export interface TimeBasedMatchSetRatingStrategyDTO {
  drawMode: DrawMode;
  matchsetDurationInSeconds: number;
}

export interface MatchRuleDTOBase {
  name: string;
  matchRatingStrategy: MatchRatingStrategy;
  matchSetRatingStrategy: MatchSetRatingStrategy;
  switchPlayersStrategy: SwitchPlayersStrategy;
  switchTeamsStrategy: SwitchTeamsStrategy;
  matchPreparationTimeout: number;
  matchTimeout: number;
  matchTeamSetup: MatchTeamSetuo;
  undoMode: UndoMode;
}

export interface MatchRuleMatchRatingComparingDTO {
  matchRatingStrategy: MatchRatingStrategy.COMPARING;
  comparingMatchRatingStrategy: ComparingMatchRatingStrategyDTO;
}

export interface MatchRuleMatchRatingBestOfDTO {
  matchRatingStrategy: MatchRatingStrategy.BEST_OF;
  bestOfMatchRatingStrategy: BestOfMatchRatingStrategyDTO;
}

export interface MatchRuleSetRatingGoalsBasedDTO {
  matchSetRatingStrategy: MatchSetRatingStrategy.GOALS_BASED;
  goalsBasedMatchSetRatingStrategy: GoalsBasedMatchSetRatingStrategyDTO;
}

export interface MatchRuleSetRatingTimeBasedDTO {
  matchSetRatingStrategy: MatchSetRatingStrategy.TIME_BASED;
  timeBasedMatchSetRatingStrategy: TimeBasedMatchSetRatingStrategyDTO;
}

export type MatchRuleDTO = MatchRuleDTOBase &
  (MatchRuleMatchRatingComparingDTO | MatchRuleMatchRatingBestOfDTO) &
  (MatchRuleSetRatingGoalsBasedDTO | MatchRuleSetRatingTimeBasedDTO);

export function toRuleSet(rule: MatchRuleDTO): RuleSet {
  const ruleSet: RuleSet = {
    name: rule.name,
    setsNeededToWin: -1,
    goalsNeededToWin: -1,
  };

  if (rule.matchRatingStrategy === MatchRatingStrategy.COMPARING) {
    // TODO: implement matches with comparing rating
  } else {
    ruleSet.setsNeededToWin = rule.bestOfMatchRatingStrategy.numberOfMatchSetsToWinMatch;

    if (rule.matchSetRatingStrategy === MatchSetRatingStrategy.TIME_BASED) {
      // TODO: implement time based matche sets
    } else {
      ruleSet.goalsNeededToWin = rule.goalsBasedMatchSetRatingStrategy.goalsToWinMatchSet;
    }
  }

  return ruleSet;
}

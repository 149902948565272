<ng-container *ngIf="data$ | async as data">
  <header>{{ 'statistics.matches-played-widget.title' | translate }}</header>

  <div class="bar-graph">
    <label class="label left">{{ 'statistics.matches-played-widget.label.won' | translate }}</label>
    <label class="label right">{{ 'statistics.matches-played-widget.label.total' | translate }}</label>

    <div class="bar">
      <div class="fill" [ngStyle]="{ 'width.%': data.ratio }"></div>
    </div>

    <span class="value left" [rankedAnimatedNumber]="data.won"></span>
    <span class="value right" [rankedAnimatedNumber]="data.total"></span>
  </div>
</ng-container>

<div class="match-running" *ngIf="team">
  <ranked-matchset-goal-counter
    [goalCount]="team.goalsCount"
    [devicePosition]="devicePosition"
    [top]="top"
    (goalScored)="onGoalCounterClicked()"
  >
  </ranked-matchset-goal-counter>

  <ranked-matchset-won-sets-counter [wonSets]="team.wonMatchSets"></ranked-matchset-won-sets-counter>

  <ranked-manikin-selection
    *rankedOverlay="showManikinSelection"
    [themeColor]="color"
    [top]="top"
    [position]="participant[1].position"
    (selected)="manikinSelected($event)"
    (closed)="closeManikinSelection()"
  ></ranked-manikin-selection>
</div>

import { Injectable } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map, filter, withLatestFrom, tap, delay, take } from 'rxjs/operators';
import { RoutingStoreService } from '../services/routing-store.service';

@Injectable()
export class RoutingEffects {
  loadPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      withLatestFrom(this.routingStoreService.getRouteData()),
      map(([, data]) => data.onLoadAction),
      filter((onLoadAction) => !!onLoadAction),
    ),
  );

  // TODO: move this logic to a better place
  // we want to disable the splashscreen after the first successful navigation
  disableSplashscreenAfterFirstNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        take(1),
        delay(500),
        tap(() => {
          SplashScreen.hide({ fadeOutDuration: 500 });
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private routingStoreService: RoutingStoreService) {}
}

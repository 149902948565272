import { Component, ElementRef, ViewChild } from '@angular/core';
import { DetailedLogEntry, LogFileService } from '../../services/log-file.service';

type DisplayLogEntry = Omit<DetailedLogEntry, 'data'> & {
  data: string | undefined;
};

@Component({
  selector: 'ranked-detailed-log-file',
  templateUrl: './detailed-log-file.component.html',
  styleUrls: ['./detailed-log-file.component.scss'],
})
export class DetailedLogFileComponent {
  public logEntries: DisplayLogEntry[] = this.logFileService
    .getDetailedLogFile()
    .map((entry) => ({ ...entry, data: entry.data && JSON.stringify(entry.data, null, 2) }));

  @ViewChild('snackbar')
  public snackbar?: ElementRef<HTMLDialogElement>;

  constructor(private logFileService: LogFileService) {}

  public copyLogData(entry: DisplayLogEntry): void {
    if (!navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(entry.data ?? '');

    this.snackbar?.nativeElement.show();

    setTimeout(() => {
      this.snackbar?.nativeElement.close();
    }, 3000);
  }
}

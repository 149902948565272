import { MembershipType, RoomMembership } from './room-membership';

export interface RoomMembershipDTO {
  accountId: string;
  roomId: string;
  roomName: string;
  playerName: string;
  playerAvatarUrl: string;
  membershipTypes: MembershipTypeDTO[];
}

export enum MembershipTypeDTO {
  ADMINISTRATOR = 'ADMINSTRATOR',
  VISITOR = 'VISITOR',
  PLAYER = 'PLAYER',
}

export function toRankedRoomMembership(membership: RoomMembershipDTO): RoomMembership {
  const roomMembership: RoomMembership = {
    roomId: membership.roomId,
    roomName: membership.roomName,
    accountId: membership.accountId,
    membershipTypes: membership.membershipTypes.map(toMembershipType),
    playerAvatarUrl: membership.playerAvatarUrl,
    playerName: membership.playerName,
  };

  return roomMembership;
}

function toMembershipType(membershipType: MembershipTypeDTO): MembershipType {
  switch (membershipType) {
    case MembershipTypeDTO.ADMINISTRATOR:
      return MembershipType.ADMINISTRATOR;
    case MembershipTypeDTO.VISITOR:
      return MembershipType.VISITOR;
    case MembershipTypeDTO.PLAYER:
      return MembershipType.PLAYER;
    default:
      return undefined;
  }
}

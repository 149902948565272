import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { isDefined } from '@ranked/utils';
import { AllowedContextTypes, AllowedTimeFrames, Ranking } from '../../model';
import { StatisticsDataService } from '../../services/statistics-data.service';

@Component({
  selector: 'ranked-room-rankings',
  templateUrl: './room-rankings.component.html',
  styleUrls: ['./room-rankings.component.scss'],
})
export class RoomRankingsComponent {
  public winRateRanking$: Observable<Ranking>;
  public ownPlayerId: Observable<string>;

  constructor(accountStoreService: AccountStoreService, private statisticsDataService: StatisticsDataService) {
    this.winRateRanking$ = this.loadRanking('Elo', AllowedContextTypes.ROOM, AllowedTimeFrames.ALL_TIME).pipe(filter(isDefined));

    this.ownPlayerId = accountStoreService.getOwnPlayerNameInCurrentRoom();
  }

  private loadRanking(rankingName: string, contextType: AllowedContextTypes, timeFrame: AllowedTimeFrames): Observable<Ranking> {
    return this.statisticsDataService.getRanking(rankingName, { contextType, timeFrame });
  }
}

import { MatchStatus } from './match-status';

export interface MatchTeam {
  name: string;
  attackPlayerName: string;
  defensePlayerName: string;
  goalsCount: number;
  wonMatchSets: number;
}

export type MatchWinner = 'color1' | 'color2';

export interface Match {
  id: string;
  tableName: string;
  ruleSetName: string;
  status: MatchStatus;
  startable: boolean;
  startTime?: Date;
  finishedTime?: Date;
  currentTime: number;
  currentSetCount: number;
  teamColor1: MatchTeam;
  teamColor2: MatchTeam;
  winner: MatchWinner;
  completed: boolean;
  participantSwitchAllowed: boolean;
  undoPossible: boolean;
}

<ranked-safe-area-top color="primary"></ranked-safe-area-top>

<ranked-navbar [title]="'app.select-room.title' | translate" [icon]="navbarIcon" [iconTap]="goToProfile"></ranked-navbar>

<ranked-room-info-list
  *ngIf="roomsAvailable$ | async; else noRooms"
  class="room-info-list"
  [roomInfoList]="roomInfos$ | async"
  (roomInfoClicked)="onRoomInfoClicked($event)"
></ranked-room-info-list>

<ng-template #noRooms>
  <section class="no-rooms">
    <div class="header">{{ 'app.select-room.no-rooms.headline' | translate : { nickname: (nickname$ | async) } }}</div>
    <p>{{ 'app.select-room.no-rooms.text' | translate }}</p>
  </section>
</ng-template>

<button
  class="join-room"
  ranked-button
  [variant]="(roomsAvailable$ | async) ? 'simple' : 'normal'"
  text="{{ 'app.select-room.button.join-room' | translate }}"
  size="big"
  (click)="joinRoom()"
></button>

<ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>

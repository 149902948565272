import { Participant } from './participant';
import { Player } from './player';

export interface PlayerDTO {
  name: string;
  playerAvatarUrl: string;
}

export function playerToParticipant(playerDTO: PlayerDTO): Participant {
  const player: Player = {
    id: playerDTO.name,
    name: playerDTO.name,
    image: playerDTO.playerAvatarUrl,
  };

  return player;
}

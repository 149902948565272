import { LogLevel } from './log-level';

export type SimpleLogMessage = {
  text: string;
  logLevel: Exclude<LogLevel, 'ERROR'>;
};

export type ErrorLogMessage = {
  text: string;
  logLevel: 'ERROR';
  error?: unknown;
  eventLog?: readonly string[];
};

export type LogMessage = SimpleLogMessage | ErrorLogMessage;

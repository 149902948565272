import { Action, createReducer, on } from '@ngrx/store';
import { PresenceStatus } from '@ranked/model';
import { RoomLeft, RoomLoaded } from '@ranked/room';
import { RankedAccount } from '../model/ranked-account';
import {
  CreateRankedAccount,
  FirebaseUserRemoved,
  FirebaseUserWithoutAccountReceived,
  LoadSavedRoomIdFailed,
  RankedAccountReceived,
  RoomMembershipsLoaded,
} from './account.actions';

export const ACCOUNT_FEATURE_KEY = 'account';

export interface AccountState {
  accountStatus: PresenceStatus;
  account?: RankedAccount;
  currentRoomStatus: PresenceStatus; // this needs to be here and not within room, because LoadSavedRoomIdFailed, which cannot be part of room, sets this to MISSING
}

export interface AccountAppState {
  readonly [ACCOUNT_FEATURE_KEY]?: AccountState;
}

export const initialAccountState: AccountState = {
  accountStatus: PresenceStatus.UNINITIALIZED,
  currentRoomStatus: PresenceStatus.UNINITIALIZED,
};

const reducer = createReducer(
  initialAccountState,
  on(RoomMembershipsLoaded, (state, { memberships }) => ({
    ...state,
    account: {
      ...state.account,
      roomMemberships: memberships,
    },
  })),
  on(RankedAccountReceived, (state, { account }) => ({
    ...state,
    account,
    accountStatus: PresenceStatus.PRESENT,
  })),
  on(FirebaseUserWithoutAccountReceived, (state) => ({
    ...state,
    account: undefined,
    accountStatus: PresenceStatus.MISSING,
  })),
  on(LoadSavedRoomIdFailed, (state) => ({
    ...state,
    currentRoomStatus: PresenceStatus.MISSING,
  })),
  on(RoomLoaded, (state) => ({
    ...state,
    currentRoomStatus: PresenceStatus.PRESENT,
  })),
  on(RoomLeft, (state) => ({
    ...state,
    currentRoomStatus: PresenceStatus.MISSING,
  })),
  on(FirebaseUserRemoved, CreateRankedAccount, () => initialAccountState),
);

export function accountReducer(state: AccountState | undefined, action: Action): AccountState {
  return reducer(state, action);
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DevicePosition } from '@ranked/model';

@Component({
  selector: 'ranked-matchset-goal-counter',
  templateUrl: './matchset-goal-counter.component.html',
  styleUrls: ['./matchset-goal-counter.component.scss'],
})
export class MatchsetGoalCounterComponent {
  @Input() public devicePosition: DevicePosition;
  @Input() public top: boolean;
  @Input() public goalCount: number;

  @Output() public goalScored = new EventEmitter<void>();

  public onGoalScored(): void {
    this.goalScored.emit();
  }
}

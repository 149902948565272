import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUiModule } from '@ranked/shared-ui';
import { ManikinSelectionComponent } from './components/manikin-selection/manikin-selection.component';
import { MatchInfoComponent } from './components/match-info/match-info.component';
import { MatchRunningSideComponent } from './components/match-running-side/match-running-side.component';
import { MatchPageComponent } from './components/match/match-page.component';
import { MatchStatusSideComponent } from './components/match/match-status-side/match-status-side.component';
import { MatchsetGoalCounterComponent } from './components/matchset-goal-counter/matchset-goal-counter.component';
import { MatchsetWonSetsCounterComponent } from './components/matchset-won-sets-counter/matchset-won-sets-counter.component';
import { ParticipantsComponent } from './components/participants/participants.component';
import { ParticpantSelectionComponent } from './components/particpant-selection/particpant-selection.component';
import { MatchTimeFormatPipe } from './pipes/match-time-format.pipe';
import { PositionAsCssClassPipe } from './pipes/position-as-css-class.pipe';
import { MatchSseService } from './services/match-sse.service';
import { MatchStoreService } from './services/match-store.service';
import { MatchEffects } from './state/match.effects';
import { MATCH_FEATURE_KEY, matchReducer } from './state/match.reducer';

@NgModule({
  declarations: [
    MatchPageComponent,
    ParticipantsComponent,
    MatchInfoComponent,
    PositionAsCssClassPipe,
    MatchTimeFormatPipe,
    ParticpantSelectionComponent,
    MatchRunningSideComponent,
    MatchStatusSideComponent,
    MatchsetGoalCounterComponent,
    ManikinSelectionComponent,
    MatchsetWonSetsCounterComponent,
  ],
  providers: [MatchStoreService, MatchSseService],
  imports: [
    CommonModule,
    TranslateModule,
    StoreModule.forFeature(MATCH_FEATURE_KEY, matchReducer),
    EffectsModule.forFeature([MatchEffects]),
    SharedUiModule,
  ],
  exports: [MatchPageComponent],
})
export class MatchModule {}

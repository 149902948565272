import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccountStoreService } from '@ranked/account';
import { PresenceStatus } from '@ranked/model';

@Injectable({
  providedIn: 'root',
})
export class AccountPresentGuard {
  constructor(private router: Router, private accountStoreService: AccountStoreService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.accountStoreService.getRankedAccountStatus().pipe(
      filter((status) => status !== PresenceStatus.UNINITIALIZED),
      map((status) => {
        if (status === PresenceStatus.MISSING) {
          return this.router.createUrlTree(['/account/create']);
        }

        return true;
      }),
    );
  }
}

<ranked-safe-area-top color="light"></ranked-safe-area-top>

<div class="splash mode-{{ mode }}">
  <img src="assets/images/splash_minimum.svg" />
</div>

<div class="content mode-{{ mode }}">
  <!-- register form -->
  <form class="part register" (submit)="onRegisterSubmit()">
    <span class="greetings">{{ 'account.login.text.greetings-register' | translate }}</span>

    <ranked-input-enhancer class="mail-input">
      <input
        #registerEmail
        rankedInput
        [(ngModel)]="email"
        type="email"
        name="email"
        autocomplete="email"
        placeholder="{{ 'account.login.placeholder.email' | translate }}"
        required
        [disabled]="disableRegister"
      />
    </ranked-input-enhancer>
    <ranked-input-enhancer class="password-input" [showToggleSecureButton]="true">
      <input
        #registerPassword
        rankedInput
        [(ngModel)]="password"
        name="password"
        type="password"
        autocomplete="password"
        placeholder="{{ 'account.login.placeholder.password' | translate }}"
        required
        [disabled]="disableRegister"
      />
    </ranked-input-enhancer>

    <button
      class="register-button"
      ranked-button
      [fullWidth]="true"
      size="big"
      text="{{ 'account.login.button.register' | translate }}"
      type="submit"
      [disabled]="!registerEmail.validity.valid || !registerPassword.validity.valid"
    ></button>

    <div class="spacer"></div>

    <button
      class="go-to-main"
      [fullWidth]="true"
      ranked-button
      variant="simple"
      text="{{ 'account.login.button.other-options' | translate }}"
      type="button"
      (click)="goToMain()"
    ></button>
  </form>

  <!-- all the buttons -->
  <div class="part main">
    <div class="greetings">{{ 'account.login.text.greetings-main' | translate }}</div>

    <button
      ranked-button
      class="main-login-button"
      [fullWidth]="true"
      size="big"
      text="{{ 'account.login.button.login' | translate }}"
      (click)="goToLogin()"
    ></button>

    <button
      ranked-button
      class="main-register-button"
      [fullWidth]="true"
      size="big"
      text="{{ 'account.login.button.create-account' | translate }}"
      (click)="goToRegister()"
    ></button>

    <!-- <div class="social-buttons-headline">
      <span>{{ 'account.login.text.alternative-signin' | translate }}</span>
    </div>

    <div class="social-buttons row-1">
      <button
        aria-label="login with facebook"
        ranked-hexagon
        class="social-button facebook"
        backgroundColor="custom"
        borderColor="custom"
        disabled
      >
        <img class="icon" src="assets/images/icons/buttons/facebook.svg" />
      </button>
      <button
        aria-label="login with twitter"
        ranked-hexagon
        class="social-button twitter"
        backgroundColor="custom"
        borderColor="custom"
        disabled
      >
        <img class="icon" src="assets/images/icons/buttons/twitter.svg" />
      </button>
      <button
        aria-label="login with github"
        ranked-hexagon
        class="social-button github"
        backgroundColor="custom"
        borderColor="custom"
        disabled
      >
        <img class="icon" src="assets/images/icons/buttons/github.svg" />
      </button>
    </div>

    <div class="social-buttons row-2">
      <button
        aria-label="login with google"
        ranked-hexagon
        class="social-button google"
        backgroundColor="custom"
        borderColor="custom"
        (click)="onGoogleLoginClicked()"
      >
        <img class="icon" src="assets/images/icons/buttons/google.svg" />
      </button>
      <button
        aria-label="login with microsoft"
        ranked-hexagon
        class="social-button microsoft"
        backgroundColor="custom"
        borderColor="custom"
        disabled
      >
        <img class="icon" src="assets/images/icons/buttons/microsoft.svg" />
      </button>
      <button
        aria-label="login with apple"
        ranked-hexagon
        class="social-button apple"
        backgroundColor="custom"
        borderColor="custom"
        disabled
      >
        <img class="icon" src="assets/images/icons/buttons/apple.svg" />
      </button>
    </div> -->
  </div>

  <!-- login form -->
  <form class="part login" (submit)="onLoginSubmit()">
    <span class="greetings">{{ 'account.login.text.greetings-login' | translate }}</span>

    <ranked-input-enhancer class="mail-input">
      <input
        #loginEmail
        rankedInput
        [(ngModel)]="email"
        placeholder="{{ 'account.login.placeholder.email' | translate }}"
        type="email"
        name="email"
        autocomplete="email"
        required
        [disabled]="disableLogin"
      />
    </ranked-input-enhancer>
    <ranked-input-enhancer class="password-input" [showToggleSecureButton]="true">
      <input
        #loginPassword
        rankedInput
        [(ngModel)]="password"
        type="password"
        name="password"
        autocomplete="password"
        placeholder="{{ 'account.login.placeholder.password' | translate }}"
        required
        [disabled]="disableLogin"
      />
    </ranked-input-enhancer>

    <button
      class="forgot-password"
      ranked-button
      variant="simple"
      size="small"
      text="{{ 'account.login.button.forgot-password' | translate }}"
      type="button"
      (click)="onForgotPasswordClicked()"
    ></button>

    <button
      class="login-button"
      ranked-button
      [fullWidth]="true"
      size="big"
      text="{{ 'account.login.button.login' | translate }}"
      type="submit"
      [disabled]="!loginEmail.validity.valid || !loginPassword.validity.valid"
    ></button>

    <div class="spacer"></div>

    <button
      class="go-to-main"
      [fullWidth]="true"
      ranked-button
      variant="simple"
      text="{{ 'account.login.button.other-options' | translate }}"
      type="button"
      (click)="goToMain()"
    ></button>
  </form>
</div>

<ranked-safe-area-bottom color="light"></ranked-safe-area-bottom>

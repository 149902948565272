import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RoutingStoreService } from './services/routing-store.service';
import { RoutingEffects } from './state/routing.effects';
import * as fromRouting from './state/routing.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromRouting.ROUTING_FEATURE_KEY, fromRouting.reducer),
    EffectsModule.forFeature([RoutingEffects]),
  ],
  providers: [RoutingStoreService],
})
export class RoutingModule {}

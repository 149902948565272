import { Pipe, PipeTransform } from '@angular/core';
import { DevicePosition } from '@ranked/model';

@Pipe({
  name: 'positionAsCssClass',
})
export class PositionAsCssClassPipe implements PipeTransform {
  public transform(position: DevicePosition): string {
    if (!position) {
      return '';
    }
    return position === DevicePosition.LEFT ? 'side-left' : 'side-right';
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoomInfo } from '@ranked/model';

@Component({
  selector: 'ranked-room-info-list',
  templateUrl: './room-info-list.component.html',
  styleUrls: ['./room-info-list.component.scss'],
})
export class RoomInfoListComponent {
  @Input()
  public roomInfoList: RoomInfo[] = [];

  @Output()
  public roomInfoClicked = new EventEmitter<RoomInfo>();

  onRoomInfoClicked(roomInfo: RoomInfo): void {
    this.roomInfoClicked.emit(roomInfo);
  }
}

/* eslint-disable no-restricted-syntax */
import { LogMessage } from '../model/log-message';
import { Logger } from '../model/logger';

export class ConsoleLogger implements Logger {
  public readonly name = 'Console Logger';

  log(message: LogMessage): void {
    switch (message.logLevel) {
      case 'DEBUG':
        console.debug(message.text);
        break;
      case 'INFO':
        console.info(message.text);
        break;
      case 'WARN':
        console.warn(message.text);
        break;
      case 'ERROR':
        console.error(message.text, message.error, message.eventLog);
        break;
    }
  }
}

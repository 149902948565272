<ranked-safe-area-top color="primary"></ranked-safe-area-top>
<ranked-navbar title="{{ 'statistics.profile-page.title' | translate : { roomname: roomName$ | async } }}"></ranked-navbar>

<nav class="keen-slider thumbnail" #thumbnailRef>
  <h1 class="keen-slider__slide">{{ 'statistics.profile-page.navigation.profile' | translate }}</h1>
  <h1 class="keen-slider__slide">{{ 'statistics.profile-page.navigation.ranking' | translate }}</h1>
</nav>

<div class="keen-slider pages" #sliderRef>
  <article class="keen-slider__slide profile" *ngIf="account$ | async as account">
    <ranked-hexagon class="avatar" backgroundColor="custom" borderColor="custom">
      <img [src]="account.avatarUrl" />
    </ranked-hexagon>
    <div class="nickname">{{ account.nickname }}</div>

    <section class="widgets">
      <ranked-matches-played-widget></ranked-matches-played-widget>
    </section>
  </article>

  <article class="keen-slider__slide">
    <ranked-room-rankings></ranked-room-rankings>
  </article>
</div>

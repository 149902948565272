import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { META_REDUCERS } from '@ngrx/store';
import { SharedUiModule } from '@ranked/shared-ui';
import { DetailedLogFileComponent } from './components/detailed-log-file/detailed-log-file.component';
import { ActionLogService } from './ngrx/action-log.service';
import { LogFileService } from './services/log-file.service';

@NgModule({
  imports: [CommonModule, SharedUiModule],
  providers: [LogFileService, ActionLogService],
  declarations: [DetailedLogFileComponent],
  exports: [DetailedLogFileComponent],
})
export class LoggingModule {
  public static forRoot(): ModuleWithProviders<LoggingModule> {
    return {
      ngModule: LoggingModule,
      providers: [
        {
          provide: META_REDUCERS,
          deps: [ActionLogService],
          useFactory: (actionLogService: ActionLogService) => actionLogService.metaReducer,
          multi: true,
        },
      ],
    };
  }
}

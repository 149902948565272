<div class="ranked">
  <router-outlet></router-outlet>
</div>

<ranked-loading-spinner></ranked-loading-spinner>
<ranked-message-dialog></ranked-message-dialog>

<div class="portrait-hint" [class.safari]="isSafari">
  <span>Um Ranked zu nutzen, drehe bitte dein Smartphone wieder in den Portrait Modus.</span>
  <span class="safari-blame"> Safari ist der einzige moderne Browser, in dem eine PWA nicht ihre Orientation festlegen kann.</span>
</div>

import { RoomMembership } from '@ranked/model';
import { RankedAccount } from './ranked-account';
import { RankedAccountStateDTO, toRankedAccountState } from './ranked-account-state-dto';

export interface RankedAccountDTO {
  emailAddress: string;
  nickname: string;
  accountState: RankedAccountStateDTO;
  avatarUrl?: string;
  roomMemberships: RoomMembership[];
}

export function toRankedAccount(account: RankedAccountDTO, id: string): RankedAccount {
  return {
    id,
    state: toRankedAccountState(account.accountState),
    email: account.emailAddress,
    nickname: account.nickname,
    avatarUrl: account.avatarUrl,
    roomMemberships: account.roomMemberships,
  };
}
